import axios from 'axios';
import { LOC_getRefreshToken } from '../core/custom-local-storage';
import Api from '../network/api';

const axiosInstance = axios.create({
    baseURL: Api.host
});

export const Authenticate = async (phoneNumber, password, props) => {
    try {
        const response = await axiosInstance.post(Api.login, {
            "username": phoneNumber,
            "password": password
        }, { headers: { 'accountType': 'SHIPPER' } })
        return response
    } catch (error) {
        return error
    }
}

export const RefreshToken = async (props) => {
    try {
        const refreshToken = LOC_getRefreshToken()
        const response = await axiosInstance.post(Api.refresh_token, {
            "refreshToken": refreshToken
        })
        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        throw error
    }
   
}

