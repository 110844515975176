import { Col, Flex, Image, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { SvgPaperPlaneFilled, SvgSearchOutlined } from '../../components/custom-svgIcon';
import Theme from '../../components/theme';
import CustomPath from '../../routes/custom-path';

const cardStyle = {
    height: 70,
    paddingLeft: 20,
    paddingRight: 10,
    borderRadius: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color: 'white',
    fontSize: 16,
    fontWeight: '600',
    backgroundColor: Theme.colors.primary,
}
const logo = require('../../asset/Logo.png');
class TicketRepairPage extends React.Component {
    render() {
        return (
            <>
                <Row>
                    <Col lg={8} md={8} sm={1} xs={1} />
                    <Col lg={8} md={8} sm={22} xs={22}>
                        <Row >
                            <Col span={24} style={{ position: 'fixed', top: 10 }}>
                                <Image width={200} height={67} src={logo} preview={false} style={{ padding: '5px 0' }} />
                            </Col>
                            <Col span={24} >
                                <Flex vertical justify='center' style={{ height: '100vh' }}>
                                    <Link to={CustomPath.ticket_repair_search}>
                                        <div
                                            style={cardStyle}
                                        >
                                            <span>Repair Ticket's Status စစ်ဆေးရန်</span>
                                            <div className='icon-circle' style={{ marginRight: 10 }}>
                                                <SvgSearchOutlined width={20} height={20} color={Theme.colors.primary} />
                                            </div>

                                        </div>
                                    </Link>
                                    <br />
                                    <Link to={CustomPath.ticket_repair_request_form}>
                                        <div
                                            style={cardStyle}
                                        >
                                            <span>Repair Ticket တင်ရန်</span>
                                            <div className='icon-circle' style={{ marginRight: 10 }}>
                                                <SvgPaperPlaneFilled width={20} height={20} color={Theme.colors.primary} />
                                            </div>

                                        </div>
                                    </Link>

                                </Flex>
                            </Col>

                        </Row>

                    </Col>
                </Row>
                <div style={{ position: 'fixed', height: 80, bottom: 0, left: 0, right: 0, background: 'rgb(26,23,24)' }}>
                    <div style={{ height: 80, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center', fontSize: 13 }}>
                            <div>Powered by Ninja Tech MM</div>
                            <div style={{ marginTop: 5 }}>© Copyright Ninja Van Myanmar 2024. All rights reserved.</div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default TicketRepairPage;