import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CustomPath from "../../routes/custom-path";

function RedirectLogin() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(CustomPath.login)
    }, [navigate])
    return (<></>)
}

export default RedirectLogin