import { compose } from "@reduxjs/toolkit";
import { Checkbox, Col, List, Row, Skeleton, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { MainTitle, NJVButton, NJVCard, NJVEmptyData } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Colors } from "../../components/theme";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class AWBPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            totalPage: 0,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            data: [],
            selected_order: [],
            isDataFetching: false,
            isLoadMore: false,
            isCheckAll: false,
            isCheckSpecific: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        let { pageSize, page } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            let requestParams = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize,
                orderStatus: 'PENDING_PICK_UP'
            }
            const response = await ApiHandler({ url: Api.order_history, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            let updatedList = []
            response.content && response.content.map(order => {

                const orderTime = dayjs(order.createdDate, 'YYYY-MM-DD HH:mm:ss');
                if (orderTime) {
                    order = { ...order, createdDate: orderTime.format('DD MMM,YYYY hh:mm A') }
                }
                updatedList.push(order)
            })

            this.setState({
                page: pageNumber ? pageNumber : page,
                totalPagination: response.totalElements,
                data: updatedList

            })
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    fetchPagination = async (pageNumber) => {
        let { pageSize, page, data } = this.state
        this.setState({
            isLoadMore: true
        })
        try {
            let requestParams = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize,
                orderStatus: 'PENDING_PICK_UP'
            }
            const response = await ApiHandler({ url: Api.order_history, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            let isSelectedOne = false
            data && data.map(order => {
                if (order.isSelected) {
                    isSelectedOne = true
                    return
                }
            })

            let updatedList = []
            response.content && response.content.map(order => {
                const orderTime = dayjs(order.createdDate, 'YYYY-MM-DD HH:mm:ss');
                if (orderTime) {
                    order = { ...order, createdDate: orderTime.format('DD MMM,YYYY hh:mm A') }
                }
                updatedList.push(order)
            })

            this.setState({
                page: pageNumber ? pageNumber : page,
                totalPagination: response.totalElements,
                data: [...data, ...updatedList],
                isCheckAll: false,
                isCheckSpecific: isSelectedOne


            })
        } catch (error) {
        }
        this.setState({
            isLoadMore: false
        })
    }

    loadMore = () => {
        const { page } = this.state
        this.fetchPagination(page + 1)
    }



    onSelectItem = (e, item) => {
        let { selected_order, data } = this.state
        let isCheckAll = true
        if (e.target.checked) {
            let new_data_list = []
            data.map(order => {
                if (order.trackingNumber === item.trackingNumber) {
                    order = { ...order, 'isSelected': true }
                }
                if (!order.isSelected) {
                    isCheckAll = false
                }
                new_data_list.push(order)
            })
            data = new_data_list
            selected_order.push(item)
        } else {
            let new_selected_order_list = []
            selected_order.map(order => {
                if (order.trackingNumber !== item.trackingNumber) {
                    new_selected_order_list.push(order)
                }
            })
            selected_order = new_selected_order_list

            let new_data_list = []
            data.map(order => {
                if (order.trackingNumber === item.trackingNumber) {
                    order = { ...order, isSelected: false }
                }
                new_data_list.push(order)
            })
            data = new_data_list
            isCheckAll = false
        }

        this.setState({
            selected_order: selected_order,
            data: data,
            isCheckAll: isCheckAll,
            isCheckSpecific: isCheckAll ? false : true
        })

    }

    onSelectAllItem = (e) => {
        let { selected_order, data } = this.state
        let isCheckAll = false
        let selected_order_map = {}
        selected_order.map(order => {
            selected_order_map[order.trackingNumber] = order
        })
        if (e.target.checked) {
            let newList = []
            data.map(order => {
                order = { ...order, 'isSelected': true }
                newList.push(order)
                if (!selected_order_map[order.trackingNumber]) {
                    selected_order.push(order)
                }
            })
            data = newList
            isCheckAll = true
        } else {
            let removed_tracking_map = {}
            let new_data_list = []
            data.map(order => {
                order = { ...order, isSelected: false }
                new_data_list.push(order)
                removed_tracking_map[order.trackingNumber] = order.trackingNumber
            })
            data = new_data_list
            selected_order = []
        }

        this.setState({
            selected_order: selected_order,
            data: data,
            isCheckAll: isCheckAll,
            isCheckSpecific: false
        })
    }

    previewAWB = () => {
        const { selected_order } = this.state
        if (selected_order.length === 0) {
            message.destroy()
            message.error("Please select at least one record")
            return
        }

        if (selected_order) {
            this.props.navigate(CustomPath.airwaybill, { state: { data: selected_order, route: CustomPath.order_awb } })
        } else {
            message.error("Please select at least one record")
        }
    }

    render() {
        const { data, isDataFetching, isLoadMore, selected_order, totalPagination, isCheckAll, isCheckSpecific } = this.state
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <MainTitle label={i18next.t("awb")} />
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <Row gutter={[16, 16]}>


                                {
                                    data && data?.length !== 0 ?
                                        <>
                                            <Col span={24}>
                                                <Row style={{ alignItems: 'center' }}>
                                                    <Col span={12} >
                                                        <span>{selected_order.length} {i18next.t("selected")}</span> <NJVButton type="primary" style={{ backgroundColor: Colors.primary, marginLeft: 15 }} onClick={() => this.previewAWB()}>{i18next.t("awb_btn")}</NJVButton>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <Checkbox
                                                            indeterminate={isCheckSpecific}
                                                            checked={isCheckAll}
                                                            onChange={(e) => this.onSelectAllItem(e)}>
                                                            {i18next.t('select_all')}
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={data}
                                                    renderItem={(item, index) => (
                                                        <NJVCard
                                                            style={{
                                                                paddingLeft: 20,
                                                                marginBottom: 10,
                                                                borderRadius: 20,
                                                                width: '100%',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <Row style={{ alignItems: 'center' }}>
                                                                <Col span={22}>
                                                                    <div style={{ marginBottom: 2, fontSize: 16, fontWeight: '600' }}>{item.recipientName} - {item.recipientPhoneNumber} ({item.orderType})</div>
                                                                    <div style={{ marginBottom: 2 }}>{item.toAddress}, {item.toTownship.name}, {item.toTownship.division.name} </div>
                                                                    <div >Created Date : {item.createdDate}</div>
                                                                </Col>
                                                                <Col span={2} style={{ textAlign: 'center' }}>
                                                                    <Checkbox
                                                                        checked={item.isSelected}
                                                                        onChange={(e) => this.onSelectItem(e, item)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </NJVCard>
                                                    )}
                                                />
                                            </Col>
                                            <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {
                                                    data ?
                                                        <>
                                                            <span style={{ marginRight: 10, fontSize: 18 }}>
                                                                {data.length} of {totalPagination}
                                                            </span>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    data && data.length && totalPagination && totalPagination === data.length ?
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            <NJVButton type="primary" size="large" onClick={() => this.loadMore()} loading={isLoadMore}>
                                                                Load More
                                                            </NJVButton>
                                                        </>
                                                }
                                            </Col>
                                        </>
                                        :
                                        <Col span={24}>
                                            <NJVEmptyData />
                                        </Col>
                                }


                            </Row>
                    }
                </Col>
            </Row>
        )
    }
}




export default compose(withRouter)(AWBPage)