import { compose } from '@reduxjs/toolkit'
import { Col, Flex, Image, Row } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { MainTitle, NJVButton, NJVCard, NJVUpload } from '../../components/core-component'
import { SvgImgUpload } from '../../components/custom-svgIcon'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import { updateSettlementNoti } from '../../redux/profile-slice'
import CustomPath from '../../routes/custom-path'

class PaymentInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.bankData || null,
            isSendingScreenshot: false,
            previewOpen: false,
            previewImage: '',
            fileList: [],
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.bankData && this.props.bankData.id !== prevProps.bankData.id) {
            this.setState({
                data: this.props.bankData,
            })
        }
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
        });
    };

    handleChange = ({ fileList }) => {
        this.setState({ fileList });
    };

    handleImageSubmit = (imageFile, settlementId) => {
        this.setState({
            isSendingScreenshot: true,
        })
        const file = imageFile[0];
        const formData = new FormData()
        formData.append('file', file.originFileObj)
        formData.append('owedShipperId', settlementId)
        ApiHandler({ url: Api.shipper_settlement_screenshot, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            .then(() => {
                this.props.handleIsPending(false)
                this.props.navigate(CustomPath.invoice)
                this.props.dispatch(updateSettlementNoti(true))
            })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isSendingScreenshot: false,
                })
            })
    }

    render() {
        const { fileList, previewOpen, previewImage, data, isSendingScreenshot } = this.state

        const imageContainer = {
            border: '1px solid #C2002F66',
            height: 90,
            width: 120,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 3,
            backgroundColor: '#fff'
        }

        const uploadButton = (
            <div>
                <SvgImgUpload width={25} height={25} />
                <div style={{ fontStyle: 'italic' }}>Upload</div>
            </div>
        );

        const buttonStyle = {
            marginTop: 20,
            width: 140,
            backgroundColor: fileList.length === 0 ? '#D1D1D1' : `${Theme.colors.primary}`,
            color: '#fff'
        };

        return (
            <>
                {
                    data.isSelected &&
                    <NJVCard cardbgcolor={Theme.colors.secondary}>
                        <Flex align='center' gap={20}>
                            <div style={imageContainer}>
                                <Image width={80} src={data?.photo} preview={false} />
                            </div>
                            <MainTitle label={data?.name} />
                        </Flex>
                        {
                            data?.quickPay ?
                                <Row style={{ marginBlock: 10 }}>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Image src={data?.account?.acc1} preview={false} />
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Image src={data?.account?.acc2} preview={false} />
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Image src={data?.account?.acc3} preview={false} />
                                    </Col>
                                </Row>
                                : (
                                    <Col span={24} style={{ display: 'flex', gap: 4 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600, color: Theme.colors.primary }}>
                                            <p>Account Owner</p>
                                            <p>Account Number</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                            <p>- FLYING HELIOS CO., LTD</p>
                                            {data?.account?.acc1 && <p>- {data?.account?.acc1}</p>}
                                            {data?.account?.acc2 && <p>- {data?.account?.acc2}</p>}
                                        </div>
                                    </Col>
                                )
                        }
                        <Col span={24} style={{ textAlign: data?.quickPay ? 'center' : '' }}>
                            <span style={{ fontWeight: 600, color: Theme.colors.primary, paddingInline: 0 }}>{i18next.t("owed_msg")}</span>
                        </Col>
                        <Col span={data?.quickPay && 24} style={{ textAlign: 'center', display: data?.quickPay ? 'flex' : 'block', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 10 }}>
                            <NJVUpload
                                className="custom-upload"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                                maxCount={1}
                                accept={["image/png, image/jpeg"]}
                                beforeUpload={() => false}
                            >
                                {fileList.length === 1 ? null : uploadButton}
                            </NJVUpload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{ display: 'none' }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                        afterOpenChange: (visible) => !visible && this.setState({ previewImage: '' }),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Col>
                        <Col span={24} style={{ marginLeft: data?.quickPay ? 0 : this.props.isMobileSize ? 80 : 109, textAlign: data?.quickPay && 'center' }}>
                            <NJVButton loading={isSendingScreenshot}
                                type="primary"
                                disabled={fileList.length === 0}
                                size="large" style={buttonStyle} onClick={() => this.handleImageSubmit(fileList, data?.settlementId)}>{i18next.t("submit")}</NJVButton>
                        </Col>
                    </NJVCard>
                }
            </>
        )
    }
}

export default compose(withRouter)(PaymentInfo)