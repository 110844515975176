import { compose } from '@reduxjs/toolkit';
import { Col, Flex, Image, List, Pagination, Row, Skeleton, Tag } from 'antd';
import dayjs from 'dayjs';
import i18next from 'i18next';
import Lottie from "lottie-react";
import React, { Component } from 'react';
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import { MainTitle, NJVButton, NJVCard } from '../../components/core-component';
import { SvgArrowLeftFilled } from '../../components/custom-svgIcon';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import { updateSettlementNoti } from '../../redux/profile-slice';
import CustomPath from '../../routes/custom-path';
import { Constant } from '../../core/constant';

class PaymentHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSettlementFetching: false,
            show_load_more: false,
            isLoadMore: false,
            page: 0,
            totalElement: 0,
            pageSize: Constant.pageSize,
        }
    }

    componentDidMount() {
        this.fetchSettlementHistory()
    }

    fetchSettlementHistory = async (pageNumber) => {
        const { page, pageSize } = this.state;
        try {
            this.setState({
                isSettlementFetching: true
            })
            const requestParams = {
                page: pageNumber ? pageNumber - 1 : page,
                size: pageSize
            }
            const response = await ApiHandler({ url: Api.shipper_settlement_history, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams });
            if (response && response.content) {
                let updatedList = [];
                response.content && response.content.forEach(settlement => {
                    const time = dayjs(settlement.createdDate, 'YYYY-MM-DD HH:mm:ss');
                    if (time) {
                        settlement = {
                            ...settlement,
                            createdDate: time.format('DD MMM,YYYY hh:mm A')
                        }
                    }
                    updatedList.push(settlement)
                })
                this.setState({
                    historyData: updatedList,
                    isSettlementFetching: false,
                    show_load_more: response.totalElements < 20 ? false : true,
                    isLast: response?.last,
                    page: pageNumber ? pageNumber : page,
                    totalElement: response.totalElements,
                })
            }
        } catch (error) {
        }
    }

    handleBackButton = () => {
        this.props.navigate(CustomPath.invoice)
        this.props.dispatch(updateSettlementNoti(true))
    }

    pagination = (page) => {
        this.fetchSettlementHistory(page)
        this.setState({
            page: page - 1
        })
    }

    // loadMore = async () => {
    //     const { page } = this.state
    //     try {
    //         this.setState({
    //             isLoadMore: true
    //         })
    //         const requestParams = {
    //             page: page + 1
    //         }
    //         const response = await ApiHandler({ url: Api.shipper_settlement_history, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams });
    //         if (response && response.content) {
    //             let updatedList = [];
    //             response.content && response.content.forEach(settlement => {
    //                 const time = dayjs(settlement.createdDate, 'YYYY-MM-DD HH:mm:ss');
    //                 if (time) {
    //                     settlement = {
    //                         ...settlement,
    //                         createdDate: time.format('DD MMM,YYYY hh:mm A')
    //                     }
    //                 }
    //                 updatedList.push(settlement)
    //             })
    //             this.setState((prev) => ({
    //                 historyData: [...prev?.historyData, ...updatedList],
    //                 show_load_more: response.totalElements < 20 ? false : true,
    //                 isLoadMore: false,
    //                 isLast: response?.last,
    //                 page: page + 1
    //             }))
    //         }
    //     } catch (error) {
    //     }
    // }

    render() {
        const { historyData, isSettlementFetching, page, pageSize, totalElement } = this.state

        return (
            <NJVCard
                title={
                    <Flex align='center'>
                        <NJVButton type="text" shape="circle" onClick={() => this.handleBackButton()}><SvgArrowLeftFilled width={20} height={20} /></NJVButton>
                        <MainTitle label={i18next.t("settlement_history")} />
                    </Flex>}>
                {
                    isSettlementFetching ?
                        <Skeleton />
                        :
                        <>
                            {
                                historyData && historyData.length !== 0 ?
                                    <>
                                        <List itemLayout="vertical" size="large" dataSource={historyData} renderItem={(item) => (
                                            <NJVCard
                                                cardpadding="8px"
                                                cardbgcolor="#f7f7f7"
                                                style={{
                                                    marginBottom: 10,
                                                    borderRadius: 5,
                                                    width: '100%',
                                                }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{
                                                        borderRadius: 5, border: '1px solid #fff', backgroundColor: '#fff', padding: '5px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center',
                                                        marginRight: 20
                                                    }}>
                                                        <span style={{ color: Theme.colors.primary, fontWeight: 'bold' }}>{item.createdDate?.split(/,|\s/)[1]}</span>
                                                        <span style={{ fontWeight: 'bold' }}>{item.createdDate?.split(' ')[0]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', flexWrap: 'wrap', gap: 10 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                                            <span>
                                                                Settlement Amount
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontSize: 17,
                                                                    fontWeight: '600'
                                                                }}
                                                            > {item.amount} MMK</span>
                                                            {
                                                                item.rejectReason && <p style={{ color: '#cf1322', background: '#fff1f0', border: '1px solid #ffa39e', fontSize: 13, fontWeight: 400, margin: 0, paddingInline: 10, textAlign: 'center', borderRadius: 5 }}>{item.rejectReason}</p>
                                                            }
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Image width={50} src={item.evidence} preview={item.evidence ? true : false} />
                                                            <div style={{ width: '120px', display: 'flex', justifyContent: 'center' }}>
                                                                <Tag color={`${item.status === 'READY' ? '#2db7f5' : item.status === 'CANCEL' ? '#FFA500' : item.status === 'APPROVED' ? '#10C200' : item.status === 'REJECT' ? '#C2002F' : item.status === 'PENDING' ? '#D4D4D4' : ''}`}>{item.status}</Tag>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NJVCard>
                                        )
                                        }
                                        />
                                        {/* {
                                            show_load_more && !isLast ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <NJVButton buttonprimary={Theme.colors.primary} type="primary" onClick={() => this.loadMore()} loading={isLoadMore}>
                                                        Load More
                                                    </NJVButton>
                                                </div>
                                                :
                                                <></>
                                        } */}
                                        <Col span={24} style={{ marginTop: 20, display: 'flex', justifyContent: 'right' }}>
                                            <Pagination
                                                showSizeChanger={false}
                                                current={page}
                                                defaultPageSize={pageSize}
                                                total={totalElement}
                                                onChange={this.pagination}
                                            />
                                        </Col>
                                    </>
                                    :
                                    <Row>
                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                            <Lottie
                                                style={{ height: '200px' }}
                                                animationData={NoDataFoundAnimation}
                                                loop={true}
                                                autoplay={true}
                                            />
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                            <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                {i18next.t("no_settlement_yet")}
                                            </div>
                                            <div style={{ fontSize: 16 }}>
                                                {i18next.t("msg_settlement_history")}
                                            </div>
                                        </Col>
                                    </Row>
                            }
                        </>
                }
            </NJVCard>
        )
    }
}

export default compose(withRouter)(PaymentHistory)