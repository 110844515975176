import { compose } from "@reduxjs/toolkit";
import React from "react";
import withRouter from "../network/with-router";
import NewAdvanceOrderForm from "./shipper-order/new-advance-order-form";
import UnderMaintain from "./under-maintain";

class OrderCreateWithMaintain extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            system_under_maintain: false
        }
    }

    componentDidMount() {
        // let startDate = new Date('2024-06-23T18:00:00')
        // let endDate = new Date('2024-06-23T21:30:00')
        // let now = new Date()
        // if (startDate < now && now < endDate) {
        //     this.setState({ system_under_maintain: true })
        // }
    }

    render() {
        return (
            <>
                {
                    this.state.system_under_maintain ?
                        <UnderMaintain />
                        :
                        <NewAdvanceOrderForm />
                }

            </>
        )
    }
}

export default compose(withRouter)(OrderCreateWithMaintain)