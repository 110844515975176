import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const loyaltySlice = createSlice({
    name: 'loyalty',
    initialState: {
        loyaltyInfo: { name: 'loyaltyInfo', data: null, lastFetched: null },
        bannerSlide: { name: 'bannerItems', data: [], lastFetched: null },
        popularShops: { name: 'popularShops', data: [], lastFetched: null },
        shopTypes: { name: 'shopTypes', data: [], lastFetched: null },
    },
    reducers: {
        setLoyaltyInfo: (state, { payload }) => {
            state.loyaltyInfo = {
                ...state.loyaltyInfo,
                data: payload,
                lastFetched: moment().toISOString()
            }
        },
        setBannerSlide: (state, { payload }) => {
            state.bannerSlide = {
                ...state.bannerSlide,
                data: payload,
                lastFetched: moment().toISOString()
            }
        },
        setPopularShops: (state, { payload }) => {
            state.popularShops = {
                ...state.popularShops,
                data: payload,
                lastFetched: moment().toISOString()
            }
        },
        setShopTypes: (state, { payload }) => {
            state.shopTypes = {
                ...state.shopTypes,
                data: payload,
                lastFetched: moment().toISOString()
            }
        },
        clearLoyalty: (state) => {
            state.loyaltyInfo = { ...state.loyaltyInfo, data: null, lastFetched: null };
            state.bannerSlide = { ...state.bannerSlide, data: [], lastFetched: null };
            state.popularShops = { ...state.popularShops, data: [], lastFetched: null };
            state.shopTypes = { ...state.shopTypes, data: [], lastFetched: null };
        }
    }
});

export const { setLoyaltyInfo, setBannerSlide, setPopularShops, setShopTypes, clearLoyalty } = loyaltySlice.actions;
export const loyaltyInfo = (state) => state.loyalty.loyaltyInfo
export const bannerSlide = (state) => state.loyalty.bannerSlide
export const popularShops = (state) => state.loyalty.popularShops
export const shopTypes = (state) => state.loyalty.shopTypes

export default loyaltySlice.reducer;