import { compose } from "@reduxjs/toolkit";
import { Col, Divider, Flex, Image, Modal, QRCode, Row } from "antd";
import i18next from "i18next";
import React from "react";
import withRouter from "../network/with-router";
import { SvgQRFilled } from "../components/custom-svgIcon";
import Theme from "../components/theme";
import qrImage from '../asset/ninja_qr_image.png';
import {
    CloseOutlined
} from '@ant-design/icons'
import { NJVButton } from "../components/core-component";

const CardDisplayLabel = (props) => {
    const { label, value, style } = props
    return <Col span={24} style={{ background: '#F8F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '14px 10px', marginBottom: 2, ...style }}>
        <div style={{ fontSize: 15, fontWeight: '400' }}>
            {label}
        </div>
        <div style={{ fontSize: 15, fontWeight: '400' }}>
            {value}
        </div>
    </Col>
}

const navBarBubbleStyle = {
    width: 45, height: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f1f1f1', borderRadius: 100, marginRight: 5, cursor: 'pointer'
}

class ProfilePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: this.props?.shipperData,
            openQR: false
        }
    }

    render() {
        const { data, openQR } = this.state

        return (
            <>
                <Modal
                    title={null}
                    footer={null}
                    centered
                    open={openQR}
                    closeIcon={false}
                    onOk={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    okText="Logout"
                    cancelText="Cancel"
                    width={350}
                >
                    <div style={{ textAlign: 'center' }}>
                        <QRCode
                            errorLevel="H"
                            size={300}
                            iconSize={300 / 4}
                            value={this.props.shipperId}
                            icon={qrImage}
                            style={{
                                width: 'auto',
                                border: 0
                            }}
                        />
                        <h3>{this.props.shipperId}</h3>
                        <NJVButton type='primary' shape='circle' style={{ backgroundColor: 'red', borderRadius: '40 !important' }} onClick={() => {
                            this.setState({ openQR: false })
                        }}>
                            <CloseOutlined />
                        </NJVButton>
                    </div>
                </Modal>
                <Row>
                    <Col lg={5} md={5} sm={1} xs={1} />
                    <Col lg={14} md={14} sm={22} xs={22}>
                        <Row>
                            <Col lg={24} md={24} sm={0} xs={0}>
                                <h1>Profile</h1>
                                <Divider />
                            </Col>
                            <Col lg={0} md={0} sm={24} xs={24}>
                                <Flex justify="space-between" align="center">
                                    <h1>Profile</h1>
                                    <div
                                        onClick={() => {
                                            this.setState({ openQR: true })
                                        }} 
                                        style={navBarBubbleStyle}>
                                        <SvgQRFilled width={23} height={23} color={Theme.colors.primary} />
                                    </div>
                                </Flex>
                                <Divider />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <h3 style={{ marginTop: 0 }}>My Information</h3>
                                    </Col>
                                    <CardDisplayLabel label={"Unique ID"} value={data.uniqueId} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />
                                    <CardDisplayLabel label={"Full Name"} value={data.fullName} />
                                    <CardDisplayLabel label={"Phone Number"} value={data.phoneNumber} />
                                    <CardDisplayLabel label={"Email Address"} value={data.emailAddress} />
                                    <CardDisplayLabel label={"Address"} value={data.address1} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />
                                </Row>
                                <br />
                                <br />
                                {
                                    data.business ?
                                        <Row>
                                            <Col span={24}>
                                                <h3>Business Information</h3>
                                            </Col>
                                            <CardDisplayLabel label={"Business Name"} value={data.business.businessName} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />

                                            {
                                                data.business.productCategory ?
                                                    <CardDisplayLabel label={"Business Type"} value={data.business.productCategory.name} />
                                                    :
                                                    <></>
                                            }
                                            <CardDisplayLabel label={"E-commerce Registration Number"} value={data.business.ecommerceRegNumber} />
                                            <CardDisplayLabel label={"Business Owner NRC"} value={data.business.businessOwnerNRC} />
                                            <CardDisplayLabel label={"Facebook Page Link"} value={data.business.facebookPageLink} />
                                            <CardDisplayLabel label={"Primary Payment Type"} value={data.business.paymentType} />
                                            <CardDisplayLabel label={"Primary Payment Number"} value={data.business.paymentNumber} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />
                                            <Col span={24} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                <h3>{i18next.t("bank_acc_photo")}</h3>
                                                {
                                                    data.business.bankBookImg ? <Image width={200} height={200} src={data.business.bankBookImg} style={{ borderRadius: 8 }} /> : <div style={{ width: 200, height: 150, backgroundColor: '#f1f1f1', borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Image</div>
                                                }

                                            </Col>
                                        </Row>
                                        :
                                        <></>
                                }
                            </Col>
                        </Row>
                    </Col >
                </Row >
            </>
        )
    }
}

export default compose(withRouter)(ProfilePage)