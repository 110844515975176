import { compose } from "@reduxjs/toolkit";
import { Checkbox, Col, Row, Typography } from "antd";
import React from "react";
import withRouter from '../../network/with-router';
import Theme from "../../components/theme";
import i18next from "i18next";
const { Title, Paragraph, Text } = Typography;

class InsuranceTermsAndCondition extends React.Component {
    render() {
        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Title level={4} style={{textAlign: 'center'}}>ပါဆယ်အာမခံစည်းကမ်းချက်များ</Title>
                    <Paragraph>
                        ၁။ပါဆယ်အာမခံ၀ယ်ယူသူများသည် ပါဆယ်အာမခံနှင့်ပတ်သက်သောအဆုံးဖြတ်များမှာ First National Insurance (General) Co., Ltd ၏ အဆုံးဖြတ်အတိုင်း လက်ခံလိုက်နာပါမည်။
                    </Paragraph>
                    <Title level={5}>၂။ ပေးလျော်ရန်တာဝန်</Title>
                    <Paragraph>
                        ဤပါဆယ်အာမခံအလို့ငှာ အာမခံထားသူပေးသွင်းသောပရီမီယံကို အကြောင်းပြု၍ အာမခံပေါ်လစီ သက်တမ်းအတွင်း ပေါ်ပေါက်သည့် အာမခံထားသော ပါဆယ်ဆုံးရှုံးခြင်း သို့မဟုတ် ပျက်စီးခြင်းအတွက် (အာမခံထားငွေ သို့မဟုတ် အများဆုံးကျပ် ၁,၀၀၀,၀၀၀/-ထက်မပိုသည့်) လျော်ကြေးကို ပေါ်လစီစည်းကမ်းချက်များ နှင့်အညီပေးလျော်ရန် ဤကုမ္ပဏီက တာဝန်ယူသည်။
                    </Paragraph>
                    <Title level={5}>၃။ အာမခံထားခွင့်ရှိသူ</Title>
                    <Paragraph>
                        ပါဆယ်ပိုင်ရှင်များ
                    </Paragraph>
                    <Title level={5}>၄။ ပါဆယ်ပို့သူ</Title>
                    <Paragraph>
                        ပါဆယ်ပို့သူသည် ပါဆယ်ပစ္စည်းများအား တစ်နေရာမှ တစ်နေရာသို့ ပို့ဆောင်ပေးရန် အပ်နှံခြင်းခံရသည့် လုပ်ငန်းများကို တရားဝင်ဆောင်ရွက်ခွင့် ရရှိထားသော ကော်ပိုရိတ်အာမခံကိုယ်စားလှယ်လုပ်ငန်း လုပ်ကိုင်ခွင့်ရရှိထားသည့် ကုန်စည်ပို့ဆောင် သည့် ကုမ္ပဏီများ၊ အဖွဲ့အစည်းများ (နိုင်ငံပိုင်အဖွဲ့အစည်းဖြစ်ပါက သက်ဆိုင်ရာဝန်ကြီးဌာန၏ ကော်ပိုရိတ်အာမခံကိုယ်စားလှယ် လိုင်စင် လျှောက်ထားရန် ခွင့်ပြုချက်ရှိရမည်) ဖြစ်ပါသည်။
                    </Paragraph>
                    <Title level={5}>၅။ ပေါ်လစီသက်တမ်းကာလ</Title>
                    <Paragraph>
                        မြန်မာနိုင်ငံအတွင်း တိုင်းဒေသကြီး၊ ပြည်နယ်များရှိ မြို့များအကွာအဝေးအလိုက်
                        ပစ္စည်းပို့ဆောင်သူက ပစ္စည်းလက်ခံရာကောင်တာ သို့မဟုတ် ပေးပို့သူ၏ လိပ်စာရှိရာနေရာမှ စတင်သယ်ယူပြီး၊ ပို့ဆောင် ပေးရမည့်လိပ်စာသို့ ရောက်ရှိပေးအပ်သည့် အချိန်အထိ (ပစ္စည်းပို့ဆောင်သည့်ကုမ္ပဏီ၏ ကောင်တာ၊ ကုန်ရုံတွင် ပြန်လည် ထုပ်ပိုး ခြင်း အပါအဝင်နှင့် မြန်မာနိုင်ငံအတွင်းတွင်သာ)
                    </Paragraph>
                    <Title level={5}>၆။ အာမခံအကာအကွယ်အကျုံးမဝင်သည့်ပစ္စည်းများ</Title>
                    <Paragraph>
                    <ul style={{ listStyle: 'none' }}>
                        <li>(၁) နိုင်ငံတော်နှင့် သက်ဆိုင်ရာဝန်ကြီးဌာများမှ ချမှတ်ထားသော စည်းမျဉ်းစည်းကမ်းများ၊ တည်ဆဲတရားဥပဒေများဖြင့် ဆန့်ကျင်သည့်ပစ္စည်းများ၊</li>
                        <li>(၂) အကောက်ခွန်ဌာန၏ ခွင့်ပြုချက်မရှိသည့်ပစ္စည်းများ၊ ပစ္စည်းပေးဝေရမည့်နိုင်ငံမှ တင်သွင်း ခွင့်မပြုသည့် ပစ္စည်းများ၊</li>
                        <li>(၃) မူးယစ်ဆေးဝါးနှင့်စိတ်ကိုပြောင်းလဲစေတတ်သော ပစ္စည်းများအပါအဝင် ၎င်းတို့နှင့်ဆက်စပ် သောပစ္စည်းများ၊</li>
                        <li>(၄) မြန်မာ့ရှေးဟောင်းယဉ်ကျေးမှုအမွေအနှစ်ပစ္စည်းများ၊</li>
                        <li>(၅) ရွှေ၊ ငွေနှင့် ကျောက်မျက်ရတနာများ၊နာရီများ</li>
                        <li>(၆) အမြင်မတော်သော၊ အကျင့်စာရိတ္တကို ပျက်စီးစေနိုင်သော ပစ္စည်းများ၊</li>
                        <li>(၇) ပေါက်ကွဲစေတတ်သော၊ မီးလောင်လွယ်သော၊ ရေဒီယိုသတ္တိကြွသောပစ္စည်းများ၊ လက်နက် များ နှင့် အခြားအန္တရာယ်ရှိနိုင်သော ပစ္စည်းများ၊</li>
                        <li>(၈) သက်ရှိသတ္တဝါများ၊</li>
                        <li>(၉) ထီလက်မှတ်များ၊ ငွေအကြွေများ၊ ငွေစက္ကူများနှင့် အခြားတန်ဖိုးကြီးသောပစ္စည်းများ၊</li>
                        <li>(၁၀) ကြေမွနိုင်သော၊ ပုပ်သိုးပျက်စီးနိုင်သောအစားအသောက်များ၊</li>
                        <li>(၁၁) ယိုစိမ့်လွယ်သောအရည်ပါရှိသည့် ပစ္စည်းများ</li>
                        <li>(၁၂) လုံခြုံစွာထုပ်ပိုးချည်နှောင်ထားခြင်းမရှိသော ပစ္စည်းများ၊ ချွန်ထက်သော ပစ္စည်းများ</li>
                        <li>(၁၃) ပစ္စည်းအလေးချိန် (၃၀)kg နှင့် ပစ္စည်းအတိုင်းအတာ (အလျား+အနံ+အမြင့် ) ၂၀၀cm ထက် ကျော်လွန်ခြင်း</li>
                        <li>(၁၄) ဗေဒင်၊ ယတြာပစ္စည်းများ</li>
                    </ul>
                    </Paragraph>
                    <Title level={5}>၇။ အာမခံအကာအကွယ် အကျုံးမဝင်သောဆုံးရှုံးပျက်စီးမှုများ</Title>
                    <Paragraph>
                        အောက်ပါအချက်များကြောင့်ပေါ်ပေါက်သော ပျက်စီးဆုံးရှုံးမှုများ အတွက် FNIG တွင် တာဝန်မရှိစေရ -
                        <ul style={{ listStyle: 'none' }}>
                            <li>(က) မြန်မာနိုင်ငံနယ်နိမိတ်ပြင်ပတွင်ပေါ်ပေါက်သော ဆုံးရှုံးပျက်စီးမှု။</li>
                            <li>(ခ) ပို့ဆောင်သည့်ဌာန/ အဖွဲ့အစည်းများက ကန့်သတ်ထားသော ကုန်ပစ္စည်းများဖြစ်ခြင်း။</li>
                            <li>(ဂ) အာမခံထားသူ၏ ရည်ရွယ်ချက်ရှိရှိလုပ်ရပ် သို့မဟုတ် အမှားကြောင့်ဖြစ်ရသော ဆုံးရှုံးမှု သို့မဟုတ် ပျက်စီးဆုံးရှုံးမှု။</li>                            
                        </ul>
                    </Paragraph>
                    <Title level={5} style={{ textAlign: 'center', color: Theme.colors.primary}}>အာမခံထားသူများသိရှိရန်အချက်များ</Title>
                    <Title level={5}>လျော်ကြေးတောင်းခံခြင်း</Title>
                    <Paragraph>
                        <ul style={{ listStyle: 'none' }}>
                            <li>၁။ ပါဆယ်လက်ခံသည့်အချိန်၌ ပါဆယ်ပျက်စီးပျောက်ဆုံးမှုဖြစ်ကြောင်း တွေ့ရှိပါက Ninja Van ဘက်သို့ ပစ္စည်းလက်ခံရရှိချိန်မှ (၂၄)နာရီအတွင်းအကြောင်းကြားရပါမည်။</li>
                            <li>၂။ First National Insurance (General) Co., Ltd ၏ လျော်ကြေးတောင်းခံလွှာနှင့် လိုအပ်သောအထောက်အထားများ တင်ပြရပါမည်။</li>
                            <li>၃။ First National Insurance (General) Co., Ltd မှ လျော်ကြေးတောင်းခံမှုအပေါ် စီစစ်၍ လုံးဝပျက်စီးပျောက်ဆုံးမှုအတွက် အဆိုလွှာတွင်ဖော်ပြထားသော ပါဆယ်တန်ဖိုး၏ ၁၀၀% ကိုသော်လည်းကောင်း၊ တစ်စိတ်တစ်ဒေသပျက်စီးပါက ပျက်စီးမှု အတိုင်းအတာအရ အဆိုလွှာတွင်ဖော်ပြထားသော ပါဆယ်တန်ဖိုးပေါ်တွင် ရာခိုင်နှုန်းအချိုးကျသော်လည်းကောင်း ပေးလျော် ပါမည်။
                            </li>
                            <li>၄။ အငြင်းပွားဖွယ်တစ်စုံတစ်ရာဖြစ်ပေါ်ပါက FNIG ၏ဆုံးဖြတ်ချက်သာအတည်ဖြစ်ပါသည်။</li>
                        </ul>
                    </Paragraph>
                </Col >
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Checkbox checked={this.props.agreeButton} onChange={(e) => this.props.handleCheckbox(e.target.checked)}><span style={{ color: Theme.colors.primary }}>{i18next.t("msg_term_and_conditions")}</span></Checkbox>
                </Col>
            </Row >
        )
    }
}

export default compose(withRouter)(InsuranceTermsAndCondition)