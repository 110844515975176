import { compose } from "@reduxjs/toolkit";
import { Col, List, Row, Skeleton, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import { MainTitle, NJVCard } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const monthMap = {
    '0': 'January',
    '1': 'February',
    '2': 'March',
    '3': 'April',
    '4': 'May',
    '5': 'June',
    '6': 'July',
    '7': 'August',
    '8': 'September',
    '9': 'October',
    '10': 'November',
    '11': 'December',
}

const PENDING_PICK_UP = 'Pending Pickup'
const ARRIVED_AT_SORTING_HUB = 'Arrived at Sorting Hub'
const EN_ROUTE_TO_SORTING_HUB = 'En-route to Sorting Hub'
const ON_VEHICLE_FOR_DELIVERY = 'On Vehicle for Delivery'
const COMPLETED = 'Delivered'
const PENDING_RESCHEDULE = 'Pending Reschedule'
const RETURNED_TO_SENDER = 'Returned to Sender'
const CANCELLED = 'Cancelled'


const eventMap = {
    'PENDING_PICK_UP': 'Pending Pickup',
    'ARRIVED_AT_SORTING_HUB': 'Arrived at Sorting Hub',
    'EN_ROUTE_TO_SORTING_HUB': 'En-route to Sorting Hub',
    'ON_VEHICLE_FOR_DELIVERY': 'On Vehicle for Delivery',
    'COMPLETED': 'Delivered',
    'PENDING_RESCHEDULE': 'Pending Reschedule',
    'RETURNED_TO_SENDER': 'Returned to Sender',
    'CANCELLED': 'Cancelled'
}

const orderStatusList = [
    {
        key: 1,
        label: PENDING_PICK_UP,
        value: 'PENDING_PICK_UP'
    }, {
        key: 2,
        label: ARRIVED_AT_SORTING_HUB,
        value: 'ARRIVED_AT_SORTING_HUB'
    }, {
        key: 3,
        label: EN_ROUTE_TO_SORTING_HUB,
        value: 'EN_ROUTE_TO_SORTING_HUB'
    }, {
        key: 4,
        label: ON_VEHICLE_FOR_DELIVERY,
        value: 'ON_VEHICLE_FOR_DELIVERY'
    }, {
        key: 5,
        label: COMPLETED,
        value: 'COMPLETED'
    }, {
        key: 6,
        label: PENDING_RESCHEDULE,
        value: 'PENDING_RESCHEDULE'
    }, {
        key: 7,
        label: RETURNED_TO_SENDER,
        value: 'RETURNED_TO_SENDER'
    }, {
        key: 7,
        label: CANCELLED,
        value: 'CANCELLED'
    },
]

const empty_history_image = require('../../asset/shipper_history_empty.png')
class RTSOrderPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            order_history_collection: [],
            historyMap: {},
            titleList: [],
            isDataFetching: true,
            totalPage: 0,
            pageSize: Constant.pageSize,
            page: 1,
            filterData: {},
            orders_status_list: []
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchOrderStatus()
    }

    fetchOrderStatus = async () => {
        try {
            var requestParams = {
                'enumType': 'ORDER_STATUS'
            }
            const response = await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let temp = []
                response.map((res) => {
                    temp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    orders_status_list: temp
                })
            }
        } catch (error) {
        }
    }

    routeToOrderDetail = (trackingNumber, id) => {
        const data = {
            id: trackingNumber,
            rts_id: id
        }
        this.props.navigate(
            CustomPath.rts_order_detail,
            { state: { data: data } }
        );
    }

    fetchData = async (pageNumber) => {
        let { pageSize, page, filterData } = this.state
        try {
            this.setState({
                isDataFetching: true
            })
            let params = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize
            }
            if (filterData) {
                if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate > filterData.toDate) {
                    message.error("Invalid date")
                    return
                }

                if (filterData.fromDate) {
                    filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                if (filterData.recipientPhoneNumber) {
                    const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                    filterData = { ...filterData, recipientPhoneNumber: updatedValue }
                }

                params = {
                    ...params,
                    ...filterData
                }
            }

            const response = await ApiHandler({ url: Api.order_rts_shipper, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let newList = []
                response.map(rts_order => {
                    if (rts_order.responseTimeLeft) {
                        const responseTimeLeft = dayjs(rts_order.responseTimeLeft, 'YYYY-MM-DD HH:mm:ss');
                        rts_order = { ...rts_order, responseTimeLeft: responseTimeLeft.format('DD MMM, YYYY hh:mm A') }
                    }
                    newList.push(rts_order)
                })
                this.setState({
                    data: newList
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    search = () => {
        this.fetchData(null)
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }
    pagination = (page, size) => {
        this.fetchData(page)

    }
    render() {
        const { isDataFetching, data } = this.state
        return (
            <Row>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <MainTitle label={i18next.t("rts")} />
                        </Col>
                        <Col span={24}>
                            {
                                isDataFetching ?
                                    <Skeleton active />
                                    :
                                    <>
                                        {
                                            data && data.length !== 0 ?
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={data}
                                                    renderItem={(item, index) => (
                                                        <NJVCard
                                                            style={{
                                                                paddingBottom: 10,
                                                                paddingTop: 10,
                                                                marginBottom: 10,
                                                                borderRadius: 20,
                                                                width: '100%',
                                                                cursor: 'pointer'
                                                            }}
                                                            bordered={false}
                                                            onClick={() => this.routeToOrderDetail(item.order.trackingNumber, item.id)}
                                                        >
                                                            <Row>
                                                                <Col lg={19} xl={19} xs={0} sm={0}>
                                                                    <div style={{
                                                                        fontSize: 17,
                                                                        fontWeight: '600'
                                                                    }}>{item.order.trackingNumber}</div>
                                                                    <div>{item.order.recipientName}</div>
                                                                    <div>{item.order.recipientPhoneNumber}</div>
                                                                </Col>
                                                                <Col lg={5} xl={5} xs={0} sm={0} style={{ textAlign: 'right' }}>
                                                                    <div>Response Before</div>
                                                                    <div>{item.responseTimeLeft}</div>
                                                                </Col>
                                                                <Col lg={0} xl={0} xs={24} sm={24}>
                                                                    <div style={{
                                                                        fontSize: 17,
                                                                        fontWeight: '600'
                                                                    }}>{item.order.trackingNumber}</div>
                                                                    <div>{item.order.recipientName}</div>
                                                                    <div>{item.order.recipientPhoneNumber}</div>
                                                                    <br />
                                                                    <div>Response Before</div>
                                                                    <div>{item.responseTimeLeft}</div>
                                                                </Col>
                                                            </Row>
                                                        </NJVCard>
                                                    )}
                                                />
                                                :
                                                <Row style={{ borderRadius: 11, paddingBottom: 30, background: '#fff' }}>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Lottie
                                                            style={{ height: '200px' }}
                                                            animationData={NoDataFoundAnimation}
                                                            loop={true}
                                                            autoplay={true}
                                                        />
                                                    </Col>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10, color: '#808080' }}>
                                                            {i18next.t("no_notification_yet")}
                                                        </div>
                                                        <div style={{ fontSize: 16, color: '#808080' }}>
                                                            {i18next.t("msg_notification")}
                                                        </div>
                                                    </Col>
                                                </Row>

                                        }
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                </Col>
            </Row >
        )
    }
}

export default compose(withRouter)(RTSOrderPage);