import {
    CloseOutlined,
    DownOutlined,
    MenuOutlined
} from '@ant-design/icons';
import { Badge, Col, ConfigProvider, Drawer, Dropdown, Flex, Image, Layout, Menu, Modal, QRCode, Row, Space, theme } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import i18next from "i18next";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../../App.css';
import { NJVPhotoURL, SIDE_MENU } from '../../core/constant';
import { LOC_clear_token } from '../../core/custom-local-storage';
import { GenerateSvgIconBaseOnName } from '../../core/nvm-utils';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import { signOut } from '../../redux/auth-slice';
import { clearCookie, currentSelectedMenu, updateSelectedMenu } from '../../redux/cookie-slice';
import { businessName, clearProfile, mobileSize, profileImg, shipperUniqueId, updateSettlementNoti } from '../../redux/profile-slice';
import CustomPath from '../../routes/custom-path';
import { NJVButton } from '../core-component';
import { SvgMyanmarFlag, SvgNotificationOutlined, SvgPowerOffOutlined, SvgProfileFilled, SvgQRFilled, SvgUKFlag } from '../custom-svgIcon';
import Theme from '../theme';

const { Header } = Layout;

const navBarBubbleStyle = {
    width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f1f1f1', borderRadius: 100, marginRight: 5, cursor: 'pointer'
}

const dropDownStyle = { width: 230, height: 40, display: 'flex', justifyContent: 'start', alignItems: 'center' }

const dropDownChildBubbleStyle = {
    width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f1f1f1', borderRadius: 100, marginRight: 10, cursor: 'pointer'
}
const dropDownChildTextStyle = {
    fontSize: 15,
    fontWeight: '500'
}

const AppHeader = ({ currentLanguage, handleChangeLanguage, collapsed, setCollapsed }) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const logo = require('../../asset/Logo.png');
    const qrImage = require('../../asset/ninja_qr_image.png');
    const shipper_profile = require('../../asset/shipper_profile.png');

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const bnName = useSelector(businessName)
    const profileUrl = useSelector(profileImg);
    const shipperId = useSelector(shipperUniqueId)
    const currentSelectedMenuIndex = useSelector(currentSelectedMenu)
    const isMobileSize = useSelector(mobileSize)
    const [selected, setSelected] = useState(0)
    const [isExistUnread, setIsExistUnread] = useState(false)

    const [open, setOpen] = useState(false);
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const [openQR, setOpenQR] = useState(false);

    useEffect(() => {
        setSelected(currentSelectedMenuIndex)
        fetchExistNotifications()
    }, [currentSelectedMenuIndex, setSelected])

    const fetchExistNotifications = async () => {
        await ApiHandler({ url: Api.isExist_notification, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                setIsExistUnread(response)
            }).catch(() => { })
    }


    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const confirmLogout = () => {
        setLogoutModalOpen(false)
        dispatch(signOut())
        dispatch(clearCookie())
        dispatch(clearProfile())
        LOC_clear_token()
        navigate(CustomPath.login)
    }

    const handleSelectMenu = (event) => {
        setSelected(event.key)
        dispatch(updateSelectedMenu(event.key))
    }

    const goToProfilePage = () => {
        navigate(CustomPath.profile)
    }

    const changeLanguage = () => {
        if (currentLanguage === 'en') {
            i18next.changeLanguage("mm")
            handleChangeLanguage('mm')
        } else if (currentLanguage === 'mm') {
            i18next.changeLanguage("en")
            handleChangeLanguage('en')
        }
    }

    const handleMenuClick = (route) => {
        onClose();
        if (route === '/v1/invoice') {
            dispatch(updateSettlementNoti(false));
        }
    }
    const items = [
        {
            label: <div onClick={() => goToProfilePage()} style={{ ...dropDownStyle, height: 60 }}>
                <div>
                    <Image
                        preview={false}
                        onClick={(e) => e.preventDefault()}
                        style={{
                            width: profileUrl ? 45 : 35,
                            height: profileUrl ? 45 : 35,
                            objectFit: 'cover',
                            cursor: 'pointer',
                            borderRadius: profileUrl ? 100 : 0,
                            marginRight: 10
                        }}
                        src={profileUrl ? profileUrl : shipper_profile} />
                </div>
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 170, fontSize: 18, fontWeight: '600' }}>
                    {bnName}
                </div>
            </div>,
            key: '3',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={() => goToProfilePage()} style={dropDownStyle}><div style={dropDownChildBubbleStyle}><SvgProfileFilled width={20} height={20} /></div><span style={dropDownChildTextStyle}>Profile</span></div>,
            key: '2',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={() => navigate(CustomPath.notification)} style={dropDownStyle}>
                <div style={dropDownChildBubbleStyle}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Badge: {
                                    dotSize: 8,
                                    lineHeight: 0
                                }
                            }
                        }}>
                        <Badge dot={isExistUnread} color={Theme.colors.primary}>
                            <SvgNotificationOutlined width={20} height={20} color={Theme.colors.primary} />
                        </Badge>
                    </ConfigProvider>
                </div><span style={dropDownChildTextStyle}>Notification</span></div>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={() => setLogoutModalOpen(true)} style={dropDownStyle}><div style={dropDownChildBubbleStyle}><SvgPowerOffOutlined width={20} height={20} /></div><span style={dropDownChildTextStyle}>Logout</span></div>,
            key: '0',
        },
    ];
    return (
        <>
            <Modal
                title={i18next.t("logout_confirmation")}
                centered
                open={logoutModalOpen}
                onOk={() => confirmLogout()}
                onCancel={() => setLogoutModalOpen(false)}
                okText={i18next.t("logout")}
                cancelText={i18next.t("cancel")}
            >
                <p>{i18next.t("are_you_sure_to_logout")}</p>
            </Modal>
            <Modal
                title={null}
                footer={null}
                centered
                open={openQR}
                closeIcon={false}
                onOk={() => setOpenQR(false)}
                onCancel={() => setOpenQR(false)}
                okText="Logout"
                cancelText="Cancel"
                width={350}
            >
                <div style={{ textAlign: 'center' }}>
                    <QRCode
                        errorLevel="H"
                        size={300}
                        iconSize={300 / 4}
                        value={shipperId}
                        icon={qrImage}
                        style={{
                            width: 'auto',
                            border: 0
                        }}
                    />
                    <h3>{shipperId}</h3>
                    <NJVButton type='primary' shape='circle' style={{ backgroundColor: 'red', borderRadius: '40 !important' }} onClick={() => setOpenQR(false)}>
                        <CloseOutlined />
                    </NJVButton>
                </div>
            </Modal>
            <Header
                style={{
                    height: "70px",
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    width: '100%',
                    paddingLeft: 10,
                    paddingRight: 10,
                    padding: 0,
                    background: colorBgContainer,
                    border: '1px solid #E6EFF5'
                }}
            >
                <Row style={{ width: '100%' }}>
                    <Col lg={8} md={0} sm={0} xs={0}>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, width: 228, borderInlineEnd: collapsed ? '0px' : '1px solid #E6EFF5', height: '100%' }}>
                            <div onClick={() => setCollapsed(!collapsed)} style={{ width: 40, height: 40, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <MenuOutlined style={{ fontSize: 18 }} />
                            </div>
                            <Link to={CustomPath.dashboard}>
                                <Image src={logo} width={140} height={48} preview={false} style={{ padding: '5px 0' }} />
                            </Link>
                        </div>
                    </Col>
                    <Col lg={16} md={0} sm={0} xs={0}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', paddingRight: 10, height: 70, gap: 10 }}>

                            <div style={{ textAlign: 'right', marginRight: 10 }}>
                                <div
                                    style={{ fontSize: 18, fontWeight: '600' }}
                                >{bnName}</div>
                            </div>
                            <div onClick={() => navigate(CustomPath.notification)} style={navBarBubbleStyle}>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Badge: {
                                                dotSize: 8,
                                                lineHeight: 0
                                            }
                                        }
                                    }}>
                                    <Badge dot={isExistUnread} color={Theme.colors.primary} className='noti-badge'>
                                        <SvgNotificationOutlined width={20} height={20} color={Theme.colors.primary} />
                                    </Badge>
                                </ConfigProvider>
                            </div>
                            <div onClick={() => setOpenQR(true)} style={navBarBubbleStyle}>
                                <SvgQRFilled width={20} height={20} color={Theme.colors.primary} />
                            </div>
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            label: <Flex gap={5}>
                                                {currentLanguage === 'en' ? (
                                                    <Flex gap={10} align='center'>
                                                        <SvgMyanmarFlag width={30} height={30} /> Myanmar
                                                    </Flex>
                                                ) : (
                                                    <Flex gap={10} align='center'>
                                                        <SvgUKFlag width={30} height={30} /> English
                                                    </Flex>
                                                )}
                                            </Flex>,
                                            key: '0',
                                            onClick: changeLanguage
                                        }
                                    ]
                                }}
                                trigger={['click']}
                            >
                                <Space style={{ cursor: 'pointer' }}>
                                    {currentLanguage === 'en' ? (
                                        <Flex gap={8} align='center'>
                                            <SvgUKFlag width={30} height={30} /> English
                                        </Flex>
                                    ) : (
                                        <Flex gap={8} align='center'>
                                            <SvgMyanmarFlag width={30} height={30} /> Myanmar
                                        </Flex>
                                    )}
                                    <DownOutlined style={{ fontSize: 13 }} size={13} />
                                </Space>
                            </Dropdown>
                            <div style={profileUrl ? { display: 'flex', justifyContent: 'center' } : navBarBubbleStyle}>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                >
                                    <img
                                        alt='profile image'
                                        onClick={(e) => e.preventDefault()}
                                        style={{
                                            width: profileUrl ? 45 : 35,
                                            height: profileUrl ? 45 : 35,
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                            borderRadius: profileUrl ? 100 : 0
                                        }}
                                        src={profileUrl ? profileUrl : shipper_profile} />
                                </Dropdown>

                            </div>
                        </div>
                    </Col>
                    <Col lg={0} md={24} sm={24} xs={24}>
                        <Row>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <div onClick={showDrawer}
                                    style={{ width: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <MenuOutlined style={{ fontSize: isMobileSize ? 14 : 16 }} />
                                </div>
                                <Link to={CustomPath.dashboard}>
                                    <Image
                                        width={isMobileSize ? 100 : 120}
                                        src={NJVPhotoURL.logo_url}
                                        preview={false}
                                    />
                                </Link>
                            </Col>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', paddingRight: '5px', height: 70, gap: 12 }}>
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: <Flex gap={5}>
                                                    {currentLanguage === 'en' ? (
                                                        <Flex gap={10} align='center'>
                                                            <SvgMyanmarFlag width={20} height={20} /> Myanmar
                                                        </Flex>
                                                    ) : (
                                                        <Flex gap={10} align='center'>
                                                            <SvgUKFlag width={20} height={20} /> English
                                                        </Flex>
                                                    )}
                                                </Flex>,
                                                key: '0',
                                                onClick: changeLanguage
                                            }
                                        ]
                                    }}
                                    trigger={['click']}
                                >
                                    <Space style={{ cursor: 'pointer' }}>
                                        {currentLanguage === 'en' ? (
                                            <Flex gap={8} align='center'>
                                                <SvgUKFlag width={20} height={20} /> English
                                            </Flex>
                                        ) : (
                                            <Flex gap={8} align='center'>
                                                <SvgMyanmarFlag width={30} height={20} /> Myanmar
                                            </Flex>
                                        )}
                                        <DownOutlined style={{ fontSize: 10 }} size={10} />
                                    </Space>
                                </Dropdown>
                                <div style={profileUrl ? { display: 'flex', justifyContent: 'center' } : navBarBubbleStyle}>
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                    >
                                        <Image
                                            preview={false}
                                            onClick={(e) => e.preventDefault()}
                                            style={{
                                                width: profileUrl ? 35 : 25,
                                                height: profileUrl ? 35 : 25,
                                                objectFit: 'cover',
                                                cursor: 'pointer',
                                                borderRadius: profileUrl ? 100 : 0
                                            }}
                                            src={profileUrl ? profileUrl : shipper_profile} />
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Drawer
                        closable={false}
                        placement="left"
                        onClose={onClose}
                        open={open}
                        width={270}

                    >
                        <ConfigProvider
                            theme={{
                                token: {
                                    controlHeightLG: 55,
                                    margin: 0
                                },
                                components: {
                                    Menu: {
                                        itemMarginInline: 0,
                                        itemMarginBlock: 0
                                    }
                                }
                            }}
                        >
                            <Menu
                                style={{
                                    background: '#fff',
                                    border: 'none',
                                    display: 'inline'
                                }}
                                mode='inline'
                                onSelect={handleSelectMenu}
                                selectedKeys={[currentSelectedMenuIndex]}
                            >
                                {
                                    SIDE_MENU.map((menu, index) => {
                                        const currentIndex = '' + (index + 1)
                                        return <MenuItem key={currentIndex} >
                                            <Link to={menu.route} onClick={() => handleMenuClick(menu.route)}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '20' }}>
                                                    {GenerateSvgIconBaseOnName(menu.name, selected, currentIndex)}
                                                    <span className="sidebar-text">{i18next.t(menu.name)}</span>
                                                </div>
                                            </Link>
                                        </MenuItem>
                                    })
                                }
                            </Menu>
                        </ConfigProvider>
                    </Drawer>
                </Row>
            </Header>
        </>
    )
}

export default AppHeader