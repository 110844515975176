import {
  LoadingOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Col, Form, Radio, Row, Skeleton, Spin, message } from 'antd';
import i18next from 'i18next';
import React, { Component } from 'react';
import { NJVButton, NJVCard, NJVInput, NJVRadio, NJVSelect, NJVSpacer } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, ApiHandlerNoAuth } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import { updateStatusKey } from '../../redux/profile-slice';
import CustomPath from '../../routes/custom-path';

const HOME_DELIVERY = 1
const BUS_GATE = 2

const COLLECT_FROM_CUSTOMER = 1
const PAY_BY_SHIPPER = 2

class UpdateVoucher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uniqueId: this.props.location?.state?.uniqueId || null,
      isFetchingData: false,
      isFetchingTownship: false,
      openConfirmModal: false,
      isUpdating: false,
      is_fetching_price_script: false,
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.fetchVoucherData()
  }

  fetchVoucherData = async () => {
    const { uniqueId } = this.state;
    let requestParams = {
      uniqueId
    }
    this.setState({
      isFetchingData: true
    })
    try {
      const response = await ApiHandler({ url: Api.voucher_by_unique_id, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
      if (response) {
        const divisionId = response.customerTownship?.division?.id;
        const townshipId = response.customerTownship?.id;
        const paymentOption = response.paymentOption;
        let userInput = { ...response, 'divisionId': divisionId, 'paymentOption': paymentOption && paymentOption === "Collect from customer" ? 1 : paymentOption && paymentOption === "Pay by shipper" ? 2 : "Collect from customer" }
        if (response.orderType === 'Home Delivery') {
          userInput = { ...userInput, orderType: HOME_DELIVERY }
          if (response.customerTownship) {
            userInput = { ...userInput, townshipId }
          }
        } else if (response.orderType === 'Bus Gate') {
          userInput = { ...userInput, orderType: BUS_GATE, paymentOption: PAY_BY_SHIPPER }
          if (response.customerTownship) {
            userInput = { ...userInput, townshipId }
          }
          if (response.busGate) {
            userInput = { ...userInput, busGateId: response.busGate.id, deliveryCharges: 1000 }
          } else {
            userInput = { ...userInput, deliveryCharges: response.deliveryCharges ? response.deliveryCharges : 0 }
          }
        }

        this.setState({
          userInput: userInput,
          data: response,
          isFetchingData: false,
        }, () => {
          this.fetchBusGate(townshipId, BUS_GATE);
          this.formRef.current.setFieldsValue({ ...userInput, divisionId: null, townshipId: null })
          const currentDivision = this.formRef.current.getFieldValue('divisionId');
          const currentTownship = this.formRef.current.getFieldValue('townshipId');
          if (!currentDivision && !currentTownship) {
            this.fetchDivisionAndTownship(divisionId, townshipId)
          }
        }
        )
      }

    } catch (error) {
      this.setState({
        isFetchingData: false
      })
    }
  }

  fetchDivisionAndTownship = async (currentDivisionId, currentTownshipId) => {
    const { userInput } = this.state;
    try {
      this.setState({
        isFetchingTownship: true
      })
      const response = await ApiHandlerNoAuth({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON });
      if (response) {
        let divisionList = [];
        let townshipMapHomeDeliveryMap = {};
        let townshipMapBusGateMap = {};

        response.forEach(division => {
          divisionList.push(division)
          if (division.active) {
            let townshipListBusGateList = []
            division.townshipDTOList && division.townshipDTOList.forEach(township => {
              if (township.active && township.enableBusGatePostOfficeService) {
                townshipListBusGateList.push(township)
              }
            })
            const sortedTownshipListBusGateList = townshipListBusGateList.sort((a, b) => {
              if (a.name < b.name) return -1
              if (a.name > b.name) return 1
              return 0
          })
            townshipMapBusGateMap[division.id] = sortedTownshipListBusGateList
          }

          if (division.active && division.enableHomeDelivery) {
            let townshipListHomeDeliveryList = []
            division.townshipDTOList && division.townshipDTOList.forEach(township => {
              if (township.active && township.enableHomeDelivery) {
                townshipListHomeDeliveryList.push(township)
              }
            })
            const sortedTownshipListHomeDelivery = townshipListHomeDeliveryList.sort((a, b) => {
              if (a.name < b.name) return -1
              if (a.name > b.name) return 1
              return 0
          })
            townshipMapHomeDeliveryMap[division.id] = sortedTownshipListHomeDelivery
          }
        })


        let township_list = []

        if (userInput.orderType === HOME_DELIVERY) {
          township_list = townshipMapHomeDeliveryMap[currentDivisionId]
        } else if (userInput.orderType === BUS_GATE) {
          township_list = townshipMapBusGateMap[currentDivisionId]
        }


        this.setState({
          division_list: divisionList,
          township_list,
          township_map_home_delivery: townshipMapHomeDeliveryMap,
          township_map_bus_gate_service: townshipMapBusGateMap,
          isFetchingTownship: false
        }, () => {
          this.formRef.current.setFieldsValue({ divisionId: currentDivisionId, townshipId: currentTownshipId })
        })

      }
    } catch (error) {
    }
    this.setState({
      isFetchingTownship: false
    })
  }

  fetchBusGate = async (townshipId, orderType) => {
    const { data, userInput } = this.state
    let type = orderType ? orderType : userInput.orderType
    try {
      if (type === BUS_GATE) {
        this.setState({
          busGateList: [],
          is_busgate_data_fetching: true
        })
        const requestParams = {
          'receiverTownshipId': townshipId ? townshipId : userInput.townshipId,
          'senderLocationId': data.shipperInfo.townshipId
        }
        const response = await ApiHandlerNoAuth({ url: Api.public_bus_gate_by_receiver_and_sender, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
        if (response) {
          this.setState({
            busGateList: response
          })
        }
      }
    } catch (error) {
    }
    this.setState({
      is_data_fetching: false,
      is_busgate_data_fetching: false
    })
  }

  getPriceScript = async (senderTownshipId, receiverTownshipId, userInput) => {
    const { data } = this.state
    this.setState({
      is_fetching_price_script: true
    })
    try {
      const requestParams = {
        'senderTownshipId': senderTownshipId,
        'receiverTownshipId': receiverTownshipId,
        'specificUserId': data.shipperInfo.id
      }
      const response = await ApiHandlerNoAuth({ url: Api.get_price, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
      if (response || response === 0) {
        this.setState({
          userInput: { ...userInput, deliveryCharges: response }
        })
      }
    } catch (error) {
    }
    this.setState({
      is_fetching_price_script: false
    })
  }

  handleUserInput = (key, value) => {
    let { data, userInput, township_map_bus_gate_service, township_map_home_delivery, busGateList } = this.state
    userInput = {
      ...userInput,
      [key]: value
    }

    if (key === 'divisionId') {
      let townshipList = []
      userInput = { ...userInput, townshipId: null, busGate: null, busGateList: [], busGateId: null }
      if (userInput.orderType === HOME_DELIVERY) {
        townshipList = township_map_home_delivery[value]
      } else if (userInput.orderType === BUS_GATE) {
        townshipList = township_map_bus_gate_service[value]
      }
      this.setState({
        township_list: townshipList
      })
    } else if (key === 'townshipId') {
      userInput = { ...userInput, busGate: null, busGateList: [], busGateId: null }
      if (userInput.orderType === HOME_DELIVERY) {
        this.getPriceScript(data.shipperInfo.townshipId, value, userInput)
      } else if (userInput.orderType === BUS_GATE) {
        this.fetchBusGate(value, BUS_GATE)
      }
    } else if (key === 'orderType') {
      let townshipList = []
      if (value === HOME_DELIVERY && userInput.divisionId) {
        townshipList = township_map_home_delivery[userInput.divisionId]
      } else if (value === BUS_GATE && userInput.divisionId) {
        townshipList = township_map_bus_gate_service[userInput.divisionId]
      }
      userInput = {
        ...userInput,
        townshipId: null,
        busGateId: null,
        address: '',
        deliveryCharges: value === HOME_DELIVERY ? 0 : 2000,
        paymentOption: value === HOME_DELIVERY ? userInput.paymentOption : value === BUS_GATE ? PAY_BY_SHIPPER : COLLECT_FROM_CUSTOMER
      }
      this.setState({ township_list: townshipList })
    } else if (key === 'busGateId') {
      let busGateName = ''
      busGateList?.forEach(busgate => {
        if (busgate.id === value) {
          busGateName = busgate.busGateName
          return
        }
      })
      userInput = { ...userInput, address: busGateName }
    }

    this.setState({
      userInput: userInput,
    })
    const { busGateId, divisionId, orderType, paymentOption, townshipId } = userInput;
    this.formRef.current.setFieldsValue({ busGateId, divisionId, orderType, paymentOption, townshipId })
  }

  handleUpdateVoucher = async (values) => {
    const { uniqueId, userInput } = this.state

    const { customerName, customerPhoneNo, addressDetail, note } = values;
    const { townshipId, orderType, busGateId, paymentOption, deliveryCharges, codAmount, parcelAmount, pickupLocationId, facebookName, isSameDay } = userInput;

    try {
      this.setState({
        isUpdating: true
      })
      let requestData = {
        "uniqueId": uniqueId,
        "customerPhoneNo": customerPhoneNo,
        "customerName": customerName,
        "addressDetail": addressDetail,
        "townshipId": townshipId,
        "orderType": orderType && orderType === HOME_DELIVERY ? 'Home Delivery' : userInput && userInput === BUS_GATE ? 'Bus Gate' : 'Home Delivery',
        "busGateId": busGateId ? busGateId : null,
        "paymentOption": paymentOption && paymentOption === 1 ? "Collect from customer" : paymentOption && paymentOption === 2 ? "Pay by shipper" : "Collect from customer",
        "deliveryCharges": deliveryCharges,
        "codAmount": codAmount,
        "parcelAmount": parcelAmount,
        "pickupLocationId": pickupLocationId,
        "facebookName": facebookName,
        "isSameDay": isSameDay || null,
        "note": note
      }
      const response = await ApiHandler({ url: Api.update_voucher, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData, customMessage: "Your voucher has been updated" })
      if (response) {
        this.setState({
          isUpdating: false
        })
        this.routerToHome()
      }
    } catch (error) {
      if (error && error.response && error.response.status === 500) {
        this.setState({
          isUpdating: false
        });

        message.error("Something went wrong");
      }
    }
  }

  routerToHome = () => {
    const { userInput } = this.state;
    if (userInput?.status === 'READY') {
      this.props.dispatch(updateStatusKey({ isReady: true }))
    }
    this.props.navigate(CustomPath.confirm_order)
  }

  filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  render() {
    const { division_list, township_list, is_fetching_price_script, isFetchingData, isFetchingTownship, isUpdating, userInput, is_busgate_data_fetching, busGateList } = this.state;

    const orderTypeList = [
      {
        id: 1,
        value: <>{i18next.t("home_delivery")}</>
      }, {
        id: 2,
        value: <>{i18next.t("bus_gate")}</>
      }
    ]

    return (
      <Row style={{ marginBlock: 20 }}>
        <Col lg={6} md={6} sm={1} xs={1} />
        <Col lg={12} md={12} sm={22} xs={22}>
          <Row>
            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
              <TitleLevel3 label={i18next.t("update_confirm_order")} />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <NJVButton type="primary" onClick={() => this.routerToHome()}>{i18next.t('back')}</NJVButton>
            </Col>
          </Row>
          <NJVSpacer height={20} />
          {
            isFetchingData ? <Skeleton active /> : <Row>
              <Col span={24}>
                <NJVCard style={{ paddingInline: 20, marginBottom: 20, paddingBottom: 20 }}>
                  <Form ref={this.formRef} onFinish={this.handleUpdateVoucher} layout='vertical'>
                    <Row gutter={16}>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <h4>{i18next.t("customer_info")}</h4>
                        <Row gutter={24}>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item name="customerName" label={i18next.t("name")} rules={[
                              {
                                required: true,
                                message: i18next.t('Please enter a name.'),
                              },
                              {
                                max: 50,
                                message: i18next.t('Name cannot exceed 50 characters.'),
                              }
                            ]}>
                              <NJVInput size="large" />
                            </Form.Item>
                          </Col>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item name="customerPhoneNo" label={i18next.t("phone_number")} rules={[
                              {
                                required: true,
                                message: i18next.t('err_msg_enter_receiver_phone_number')
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: i18next.t('err_msg_invalid_phone_number')
                              },
                              {
                                min: 6,
                                message: i18next.t('err_msg_phone_number_too_short')
                              },
                              {
                                max: 11,
                                message: i18next.t('err_msg_phone_number_too_long')
                              }
                            ]}>
                              <NJVInput size="large" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <h4>{i18next.t("general_info")}</h4>
                        <Form.Item name='orderType' label={i18next.t("order_type")} rules={[
                          {
                            required: true,
                            message: 'Choose order type'
                          }
                        ]}>
                          <Radio.Group value={userInput && userInput.orderType ? userInput.orderType : 1} onChange={(event) => this.handleUserInput('orderType', event.target.value)}
                          >
                            {
                              orderTypeList.map((order) => {
                                return <NJVRadio key={order.id} value={order.id}>{order.value}</NJVRadio>
                              })
                            }
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name="paymentOption" label={i18next.t("payment_option")} rules={[
                          {
                            required: true,
                            message: 'Choose payment type'
                          }
                        ]}>
                          <Radio.Group
                            value={userInput?.orderType === HOME_DELIVERY ? (userInput && userInput?.paymentOption) ?? userInput?.paymentOption : PAY_BY_SHIPPER}
                            onChange={(event) => this.handleUserInput('paymentOption', event.target.value)} >
                            <NJVRadio
                              value={1}
                              disabled={userInput?.orderType === HOME_DELIVERY ? false : true}
                            >
                              {i18next.t("collect_from_customer")}
                            </NJVRadio>
                            <NJVRadio
                              value={2}>{i18next.t("pay_by_shipper")}
                            </NJVRadio>
                          </Radio.Group>
                        </Form.Item>
                        <Row gutter={24}>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item name="divisionId" label={i18next.t("division")} rules={[
                              {
                                required: true,
                                message: "Select division"
                              }
                            ]}>
                              <NJVSelect size="large" showSearch
                                filterOption={this.filterOption}
                                loading={isFetchingTownship}
                                options={
                                  division_list && division_list?.map((division) => ({
                                    value: division.id,
                                    label: division.name,
                                    type: 'division'
                                  }))
                                } onChange={(event) => this.handleUserInput('divisionId', event)} />
                            </Form.Item>
                          </Col>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item name="townshipId" label={i18next.t("township")} rules={[
                              {
                                required: true,
                                message: "Select township"
                              }
                            ]}>
                              <NJVSelect size="large" showSearch
                                filterOption={this.filterOption}
                                loading={isFetchingTownship}
                                options={
                                  township_list && township_list?.map((township) => ({
                                    value: township.id,
                                    label: township.name,
                                    type: 'township'
                                  }))
                                } onChange={(event) => this.handleUserInput('townshipId', event)} />
                            </Form.Item>
                          </Col>
                          {
                            userInput?.orderType === 2 ?
                              <Col span={24}>
                                <Form.Item name="busGateId" label={i18next.t("bus_gate_name")} rules={[
                                  {
                                    required: true,
                                    message: i18next.t('err_msg_select_bus_gate')
                                  }
                                ]}>
                                  <NJVSelect
                                    value={(userInput && userInput.busGateId) ?? userInput.busGateId}
                                    placeholder={i18next.t("bus_gate_name")}
                                    size="large"
                                    onChange={(event) => this.handleUserInput('busGateId', event)}
                                    loading={is_busgate_data_fetching}
                                    options={
                                      busGateList && busGateList.map((busGate) => {
                                        return {
                                          value: busGate.id,
                                          label: busGate.busGateName,
                                          type: 'busGate'
                                        }
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              :
                              <></>
                          }

                          {
                            userInput?.orderType === HOME_DELIVERY || userInput?.orderType === 3 ?
                              <Col span={24}>
                                <Form.Item name="addressDetail" label={i18next.t("address")} rules={[
                                  {
                                    required: true,
                                    message: "Enter address"
                                  }
                                ]}>
                                  <NJVInput size="large" istextareainput="true" showCount maxLength={180} />
                                </Form.Item>
                              </Col>
                              :
                              <></>
                          }
                        </Row>
                        <Row>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item>
                              <>{i18next.t("parcel_value")} - {userInput?.parcelAmount ? `${userInput?.parcelAmount} MMK` : `0 MMK`}</>
                            </Form.Item>
                          </Col>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item>
                              {
                                userInput?.orderType === HOME_DELIVERY ?
                                  <>
                                    {
                                      is_fetching_price_script ?
                                        <Spin
                                          indicator={
                                            <LoadingOutlined
                                              style={{
                                                fontSize: 24,
                                              }}
                                              spin
                                            />
                                          }
                                        />
                                        :
                                        <>{i18next.t('delivery_fees')} -{userInput?.deliveryCharges} MMK</>
                                    }
                                  </>
                                  :
                                  <>
                                    {i18next.t('delivery_fees')} -{userInput?.deliveryCharges} MMK
                                  </>
                              }
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item name="note" label={i18next.t("note")}>
                          <NJVInput size="large" istextareainput="true" showCount maxLength={180} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <NJVButton htmlType="submit" type="primary" onClick={() => this.setState({ openConfirmModal: true })} style={{ background: Theme.colors.primary }} loading={isFetchingTownship || isUpdating} size='large'>Update Voucher</NJVButton>
                      </Col>
                    </Row>
                  </Form>
                </NJVCard>
              </Col>
            </Row>
          }
        </Col>
      </Row>
    )
  }
}

export default compose(withRouter)(UpdateVoucher)