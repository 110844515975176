import { compose } from "@reduxjs/toolkit";
import { Col, Flex, Form, Image, Typography, message } from "antd";
import i18next from "i18next";
import React from "react";
import { NJVButton, NJVForm, NJVInput } from "../../components/core-component";
import { SvgUserPassword, SvgUserPhone } from "../../components/custom-svgIcon";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandlerNoAuth } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { Title, Text } = Typography;

const forgotLogo = require('../../asset/ninja_forgot.png');
const creatingLogo = require('../../asset/ninja_create_order.png');
const newPwLogo = require('../../asset/ninja_good.png');
const successLogo = require('../../asset/ninja_forgot_pw_success.png');

const initialState = 1
const tokenState = 2
const passwordConfrimState = 3
const successState = 4
const resendPhoneNumberCountdownNumber = 90

const tokenInputStyle = {
    width: 40,
    height: 40,
    borderRadius: 7,
    border: 'none',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: '500'
}

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            userId: null,
            currentStep: initialState,
            phoneNumber: null,
            token: '',
            phoneNumberLoading: false, tokenLoading: false, confirmLoading: false, resendPhoneNumberLoading: false,
            visiblePassword: false,
            resendPhoneNumberEnable: false,
            countdown: resendPhoneNumberCountdownNumber
        }
        this.inputRef1 = React.createRef(null)
        this.inputRef2 = React.createRef()
        this.inputRef3 = React.createRef()
        this.inputRef4 = React.createRef()
        this.inputRef5 = React.createRef()
        this.inputRef6 = React.createRef()
    }

    componentDidMount = () => {

    }
    backToInitialState = () => {
        this.props.navigate(CustomPath.login)
        this.setState({
            currentStep: initialState,
            phoneNumber: null,
            userId: null
        })
    }

    checkPhoneNumberExist = async (formData) => {
        if(!formData.phoneNumber){
            message.destroy()
            message.error(i18next.t('enter_phone'));
            return false
        }
        try {
            this.setState({
                phoneNumberLoading: true,
                phoneNumber: formData.phoneNumber
            })
            const requestParams = { 'phoneNumber': formData.phoneNumber }
            const response = await ApiHandlerNoAuth({ url: Api.get_otp, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.setState({
                    userId: response.key,
                    currentStep: tokenState,
                    phoneNumberLoading: false,
                })
                this.handleButtonClickWithDelay()
            }
        } catch (error) {
            this.setState({
                phoneNumberLoading: false
            })
        }
    }

    resendCheckPhoneNumberExist = async () => {
        const { phoneNumber } = this.state
        try {
            this.setState({
                resendPhoneNumberLoading: true
            })
            const requestParams = { 'phoneNumber': phoneNumber }
            const response = await ApiHandlerNoAuth({ url: Api.get_otp, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.setState({
                    userId: response.key,
                    resendPhoneNumberLoading: false,
                    resendPhoneNumberEnable: false,
                    countdown: resendPhoneNumberCountdownNumber
                })
                this.handleButtonClickWithDelay()
            }
        } catch (error) {
            this.setState({
                resendPhoneNumberLoading: false,
                resendPhoneNumberEnable: false,
                countdown: resendPhoneNumberCountdownNumber
            })
        }
    }

    confirmOTP = async () => {
        const { userId } = this.state
        let otp = ''
        if (this.inputRef1.current.value && this.inputRef2.current.value && this.inputRef3.current.value
            && this.inputRef4.current.value && this.inputRef5.current.value && this.inputRef6.current.value) {
            otp = '' + this.inputRef1.current.value + this.inputRef2.current.value + this.inputRef3.current.value
                + this.inputRef4.current.value + this.inputRef5.current.value + this.inputRef6.current.value
        } else {
            message.error("Please enter 6 digit")
            return
        }

        try {
            this.setState({
                tokenLoading: true
            })
            const requestParams = { 'otp': otp, 'key': userId + '' }
            const response = await ApiHandlerNoAuth({ url: Api.check_otp, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.setState({
                    userId: response.key,
                    currentStep: passwordConfrimState,
                    tokenLoading: false,
                    resendPhoneNumberEnable: false
                })
            }
        } catch (error) {

            this.setState({
                tokenLoading: false
            })
        }
    }

    confirmPassword = async (formData) => {
        const { userId } = this.state
        try {
            if (formData.newPassword === formData.confirmPassword) {
                try {
                    this.setState({
                        confirmLoading: true
                    })
                    const requestParams = { 'password': formData.confirmPassword, 'id': userId }
                    // const response = await ApiHandlerNoAuth(Api.forgetPassword, HTTP_METHOD.POST, MEDIA_TYPE.JSON,null,null, param)
                    const response = await ApiHandlerNoAuth({ url: Api.forgetPassword, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestParams })
                    if (response) {
                        this.setState({
                            userId: response.key,
                            currentStep: successState,
                            tokenLoading: false
                        })
                        this.props.navigate(CustomPath.login)
                    }
                } catch (error) {

                    this.setState({
                        tokenLoading: false
                    })
                }
            } else {
                message.destroy()
                message.error("Password does not match")
                this.setState({
                    tokenLoading: false
                })
            }
        } catch (error) {
            message.destroy()
            message.error("Something Wrong Please")
            this.setState({
                tokenLoading: false
            })
        }
    }

    handleButtonClickWithDelay = () => {
        const countdownInterval = setInterval(() => {
            const { countdown } = this.state;
            if (countdown === 0) {
                this.setState({ resendPhoneNumberEnable: true, countdown: 0 });
                clearInterval(countdownInterval);
            } else {
                this.setState({ countdown: countdown - 1 });
            }
        }, 1000);
    };

    handleTokenChange = (event, key) => {
        if (key === 1 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef2.current.focus()
            } else {
                this.inputRef1.current.value = null
            }
        } else if (key === 2 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef3.current.focus()
            } else {
                this.inputRef2.current.value = null
            }
        } else if (key === 3 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef4.current.focus()
            } else {
                this.inputRef3.current.value = null
            }
        } else if (key === 4 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef5.current.focus()
            } else {
                this.inputRef4.current.value = null
            }
        } else if (key === 5 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef6.current.focus()
            } else {
                this.inputRef5.current.value = null
            }
        } else if (key === 6 && event.target.value) {
            if (this.checkIsNumberOrNot(event.target.value)) {
                this.inputRef6.current.blur()
            } else {
                this.inputRef6.current.value = null
            }
        }
    }

    inputdelete = (event, key) => {
        if (event.key === 'Backspace') {
            if (key === 2 && this.inputRef2.current.value === '') {
                this.inputRef1.current.focus()
            } else if (key === 3 && this.inputRef3.current.value === '') {
                this.inputRef2.current.focus()
            } else if (key === 4 && this.inputRef4.current.value === '') {
                this.inputRef3.current.focus()
            } else if (key === 5 && this.inputRef5.current.value === '') {
                this.inputRef4.current.focus()
            } else if (key === 6 && this.inputRef6.current.value === '') {
                this.inputRef5.current.focus()
            }

        }
    }

    checkIsNumberOrNot = (value) => {
        const numberPattern = /^[0-9]+$/;
        if (!value || !numberPattern.test(value)) {
            return false
        } else {
            return true
        }
    }

    render() {
        const { currentStep, phoneNumberLoading, tokenLoading, confirmLoading, visiblePassword, countdown, resendPhoneNumberEnable, resendPhoneNumberLoading } = this.state
        return (
            <>
                {
                    currentStep === initialState ?
                        <Col lg={18} md={20} sm={20} xs={24}>
                            <Image
                                width={150}
                                height={150}
                                src={forgotLogo}
                                preview={false}
                            />
                            <Title level={4}>{i18next.t("forgot_password")}</Title>
                            <Text>{i18next.t("otp_code_sent_to_phone_number")}</Text>
                            <NJVForm
                                name="normal_login"
                                className="login-form"
                                onFinish={this.checkPhoneNumberExist}
                                ref={this.formRef}
                            >
                                <Form.Item
                                    style={{ marginBottom: 30 }}
                                    name="phoneNumber"
                                >
                                    <NJVInput
                                        size="large"
                                        style={{ marginTop: 20 }}
                                        prefix={<SvgUserPhone width={18} height={18} />}
                                        placeholder={i18next.t("verify_phone_number")}
                                        onChange={this.handleInputChange}
                                    />

                                </Form.Item>
                                <Form.Item>
                                    <Flex justify="space-between">
                                        <NJVButton style={{ width: 150 }} type="text" onClick={() => this.props.setInitial()}>
                                            {i18next.t("go_back_to_home")}
                                        </NJVButton>
                                        <NJVButton style={{ backgroundColor: Theme.colors.primary, width: 150 }} type="primary" htmlType="submit" loading={phoneNumberLoading}>
                                            {i18next.t("continue")}
                                        </NJVButton>
                                    </Flex>
                                </Form.Item>
                            </NJVForm>
                        </Col>
                        :
                        <>
                            {
                                currentStep === tokenState ?
                                    <Col lg={18} md={20} sm={20} xs={24}>
                                        <Image
                                            width={220}
                                            height={140}
                                            src={creatingLogo}
                                            preview={false}
                                        />
                                        <Title level={4}>OTP Verification</Title>
                                        <Text>{i18next.t("otp_code_enter_to_phone_number")}</Text>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                                            <input
                                                onChange={(e) => this.handleTokenChange(e, 1)}
                                                on
                                                maxLength={1}
                                                onKeyDown={(e) => this.inputdelete(e, 1)}
                                                ref={this.inputRef1}
                                                style={tokenInputStyle}
                                            />
                                            <input
                                                onChange={(e) => this.handleTokenChange(e, 2)}
                                                onKeyDown={(e) => this.inputdelete(e, 2)}
                                                maxLength={1}
                                                ref={this.inputRef2}
                                                style={tokenInputStyle}
                                            />
                                            <input
                                                onChange={(e) => this.handleTokenChange(e, 3)}
                                                onKeyDown={(e) => this.inputdelete(e, 3)}
                                                maxLength={1}

                                                ref={this.inputRef3}
                                                style={tokenInputStyle}
                                            />
                                            <input
                                                onChange={(e) => this.handleTokenChange(e, 4)}
                                                onKeyDown={(e) => this.inputdelete(e, 4)}
                                                maxLength={1}
                                                ref={this.inputRef4}
                                                style={tokenInputStyle}
                                            />
                                            <input
                                                onChange={(e) => this.handleTokenChange(e, 5)}
                                                onKeyDown={(e) => this.inputdelete(e, 5)}
                                                maxLength={1}
                                                ref={this.inputRef5}
                                                style={tokenInputStyle}
                                            />
                                            <input
                                                onChange={(e) => this.handleTokenChange(e, 6)}
                                                onKeyDown={(e) => this.inputdelete(e, 6)}
                                                maxLength={1}
                                                ref={this.inputRef6}
                                                style={tokenInputStyle}
                                            />
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            {
                                                resendPhoneNumberEnable ?
                                                    <NJVButton style={{ width: 150, marginTop: 20 }} type="text" loading={resendPhoneNumberLoading} onClick={() => this.resendCheckPhoneNumberExist()}>
                                                        {i18next.t("resend_code")}
                                                    </NJVButton>
                                                    :
                                                    <Text>
                                                        {i18next.t("code_resend_info")} {countdown}s
                                                    </Text>
                                            }
                                        </div>
                                        <div style={{ marginTop: 30 }}>
                                            <NJVButton style={{ backgroundColor: Theme.colors.primary, width: 150 }} type="primary" onClick={() => this.confirmOTP()} loading={tokenLoading}>
                                                {i18next.t("continue")}
                                            </NJVButton>
                                        </div>
                                    </Col>
                                    :
                                    currentStep === successState ?
                                        <Col span={24}>
                                            <Image
                                                width={150}
                                                height={150}
                                                src={successLogo}
                                                preview={false}
                                            />
                                            <div>
                                                <Title level={4}>Your password was successfully changed!</Title>
                                                <Text>Login to explore ninjavan Myanmar</Text>
                                            </div>
                                            <NJVButton style={{ backgroundColor: Theme.colors.primary, width: 200, marginTop: 30 }}
                                                type="primary" size="large" onClick={() => this.props.setInitial()}>
                                                {i18next.t("login")}
                                            </NJVButton>
                                        </Col>
                                        :
                                        <Col lg={18} md={20} sm={24} xs={24}>
                                            <Image
                                                width={220}
                                                height={140}
                                                src={newPwLogo}
                                                preview={false}
                                            />
                                            <Title level={4}>{i18next.t("enter_new_password")}</Title>
                                            <NJVForm
                                                name="normal_login"
                                                onFinish={this.confirmPassword}
                                                ref={this.formRef}
                                            >
                                                <Form.Item
                                                    style={{ marginBottom: 10 }}
                                                    name="newPassword"
                                                >
                                                    <NJVInput
                                                        size="large"
                                                        prefix={<SvgUserPassword width={20} height={20} color={"#898989"} />}
                                                        type={visiblePassword ? "text" : "password"}
                                                        placeholder={i18next.t("new_password")}
                                                        ispasswordinput={true}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 10 }}
                                                    name="confirmPassword"
                                                >
                                                    <NJVInput
                                                        size="large"
                                                        prefix={<SvgUserPassword width={20} height={20} />}
                                                        type={visiblePassword ? "text" : "password"}
                                                        placeholder={i18next.t("confirm_password")}
                                                        ispasswordinput={true}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Flex justify="space-between" style={{ marginTop: 20 }}>
                                                        <NJVButton style={{ width: 150 }} type="text" onClick={() => this.backToInitialState()}>
                                                            {i18next.t("previous")}
                                                        </NJVButton>
                                                        <NJVButton style={{ backgroundColor: Theme.colors.primary, width: 150 }} type="primary" htmlType="submit" loading={confirmLoading} >
                                                            {i18next.t("confirm")}
                                                        </NJVButton>
                                                    </Flex>
                                                </Form.Item>
                                            </NJVForm>
                                        </Col>
                            }
                        </>
                }
            </>
        )
    }
}

export default compose(withRouter)(ForgotPassword)