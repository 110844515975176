import { Col, Image, Row, Skeleton, Typography } from 'antd'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NJVButton, NJVCard } from '../../components/core-component'
import CustomPath from '../../routes/custom-path'
import { PlusCircleFilled, EditFilled } from '@ant-design/icons'
import i18next from 'i18next'
import { TitleLevel3 } from '../../components/general-component'
import Api from '../../network/api'
import { ApiHandler } from '../../network/network-manager'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { compose } from '@reduxjs/toolkit'
import withRouter from '../../network/with-router'
const { Text } = Typography;

const empty_product_image = require('../../asset/no-data-found.png')

class AllProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      isFetchingData: false,
      invalidImageUrl: {}
    }
  }

  componentDidMount = () => {
    this.fetchProducts();
  }


  fetchProducts = async () => {
    this.setState({
      isFetchingData: true
    })
    await ApiHandler({ url: Api.product_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
      .then(response => {
        this.setState({
          isFetchingData: false,
        })
        response.content.forEach(item => {
          this.setState(prevState => ({
            items: [...prevState.items, item]
          }))
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isFetchingData: false,
        })
      })
  }

  render() {
    let { items, isFetchingData, invalidImageUrl } = this.state;

    return (
      <Row>
        <Col span={1} />
        <Col span={22}>
          <Row style={{ marginBottom: 40 }}>
            <Col span={12}>
              <TitleLevel3 label={i18next.t("all_products")} />
            </Col>
            <Col span={12} style={{ textAlign: "end" }}>
              <Link to={CustomPath.add_product}>
                <NJVButton
                  type="primary"
                  icon={<PlusCircleFilled />}
                >
                  {i18next.t("add_product")}
                </NJVButton>
              </Link>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            {
              isFetchingData ? <Skeleton active />
                :
                <>
                  {
                    items.length === 0 ?
                      <Col span={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 60, alignItems: 'center' }}>
                        <Image
                          width={200}
                          preview={false}
                          src={empty_product_image} />
                        <span style={{ marginTop: 20, fontSize: 18 }}>Sorry, no products are available.</span>
                      </Col>
                      : (
                        <>
                          {
                            items.map((item) => {
                              return (
                                <Col lg={6} md={8} sm={12} xs={12} key={item.id}>
                                  <NJVCard
                                    cardBgColor="#fff"
                                    cover={
                                      <div className='img-container' style={{ position: 'relative' }}>
                                        <NJVButton type="primary" shape="round" icon={<EditFilled />} className="edit-button" onClick={() => this.props.navigate(CustomPath.update_product, { state: { data: item } })} />
                                        <img alt='product'
                                          src={item.imageUrls[0]}
                                          onError={() => {
                                            invalidImageUrl[item.id] = true
                                            this.setState(() => ({
                                              invalidImageUrl
                                            }))
                                          }}
                                          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, position: 'absolute' }} />
                                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', objectFit: 'cover', borderRadius: 8, position: 'absolute', backgroundColor: '#f1f1f1', zIndex: invalidImageUrl[item.id] ? 100 : -100 }} >
                                          No Image
                                        </div>
                                      </div>
                                    }>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <Text style={{ fontSize: 17, fontWeight: 600 }}>{item.name}</Text>
                                      <Text style={{ textOverflow: 'ellipsis', color: 'gray', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.description}</Text>
                                      <Text>
                                        {item.price} MMK
                                      </Text>
                                    </div>
                                  </NJVCard>
                                </Col>
                              )
                            }
                            )
                          }
                        </>
                      )
                  }
                </>
            }
          </Row>
        </Col>
      </Row>
    )
  }
}

export default compose(withRouter)(AllProducts)