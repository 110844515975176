import React, { useRef, useState } from 'react';
import { Button, Col, Divider, Flex, Form, message, notification, Row, Typography } from "antd";
import { NJVButton, NJVBoldFont, NJVForm, NJVInput } from "../../components/core-component";
import Theme from "../../components/theme";
import i18next from "i18next";
import {
    SvgAppleStore,
    SvgDirectDownload,
    SvgLockFilled,
    SvgMobileFilled,
    SvgPlayStore
} from "../../components/custom-svgIcon";
import { Link, useNavigate } from "react-router-dom";
import CustomPath from "../../routes/custom-path";
import { Authenticate } from "../../auth/auth-provider";
import { LOC_storeToken } from "../../core/custom-local-storage";
import { updateAccessToken } from "../../redux/auth-slice";
import { ApiHandler } from "../../network/network-manager";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { updateSettlementNoti } from "../../redux/profile-slice";
import { useDispatch } from "react-redux";

const playStoreUrl = 'http://bit.ly/3TUWSYD'
const appStoreUrl = 'http://apple.co/3TzdHqs'
const directUrl = 'https://ninjavanstorages.s3.amazonaws.com/NVM+Shipper+Application/NinjaVanMyanmar.apk'

function Login(prop) {
    const formRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loggingIn, setLoggingIn] = useState(false);

    const login = async (values) => {

        message.destroy()
        if ((values.phoneNumber === undefined || values.phoneNumber === '') && (values.password === undefined || values.password === '')) {
            message.error("Please Enter Phone number & Password")
        } else if (values.phoneNumber === undefined || values.phoneNumber === '') {
            message.error("Please Enter Phone number")
        } else if (values.password === undefined || values.password === '') {
            message.error("Please Enter Password")
        } else {
            setLoggingIn(true)
            try {
                const response = await Authenticate(values.phoneNumber, values.password)

                if (response && response.status === 200) {
                    LOC_storeToken(response.data)
                    dispatch(updateAccessToken(response.data))
                    navigate(CustomPath.dashboard)
                    try {
                        const owedResponse = await ApiHandler({
                            url: Api.shipper_settlement_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON
                        })
                        if (owedResponse && owedResponse.status === 'PENDING') {
                            const key = 'settlementInfo';
                            notification.info({
                                message: 'Settlement Info', description: <p style={{
                                    color: Theme.colors.primary,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 20,
                                    alignItems: 'end'
                                }}>{owedResponse.content} <NJVButton type="primary" style={{
                                    backgroundColor: Theme.colors.primary, width: 100
                                }} onClick={() => {
                                    navigate(CustomPath.invoice);
                                    notification.destroy(key);
                                    dispatch(updateSettlementNoti(true))
                                }}>Pay Now</NJVButton></p>, duration: 0, placement: 'top', key: key
                            })
                        }
                    } catch (error) {
                    }
                } else if (response && response.response.status === 401) {
                    message.error("Phone Number or Password wrong!")
                    loginFail()
                } else if (response && response.response.status === 500) {
                    message.error(response.response.data.message)
                    loginFail()
                }

            } catch (error) {
                message.error("System Error!")
                loginFail()
            }
        }
    }

    const loginFail = () => {
        if (formRef && formRef.current) {
            formRef.current.setFieldsValue({ 'password': '' })
        }
        setLoggingIn(false)
    }

    const handleInputChange = (fieldName, e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^a-zA-Z0-9!@#$%^&*()]/g, '');
        formRef.current.setFieldsValue({ [fieldName]: numericValue })
    }

    return (
        <Row style={{ marginTop: 10 }}>
            <Col lg={18} md={20} sm={21} xs={24} style={{ paddingInline: 0 }}>
                <NJVForm
                    name="normal_login"
                    className="login-form"
                    onFinish={login}
                    layout='vertical'
                    ref={formRef}
                >
                    <Form.Item
                        style={{ marginBottom: 20 }}
                        name="phoneNumber"
                        label={<NJVBoldFont>{i18next.t("phone_number")}</NJVBoldFont>}
                    >
                        <NJVInput
                            size='large'
                            prefix={<SvgMobileFilled width={20} height={20} color={"#898989"} />}

                            placeholder={i18next.t("enter_phone")}
                            onChange={(e) => handleInputChange("phoneNumber", e)}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 10 }}
                        name="password"
                        label={<NJVBoldFont>{i18next.t("password")}</NJVBoldFont>}
                    >
                        <NJVInput
                            ispasswordinput="true"
                            size="large"
                            prefix={<SvgLockFilled width={20} height={20} color={"#898989"} />}
                            placeholder={i18next.t("enter_password")}
                            onChange={(e) => handleInputChange("password", e)}
                        />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Link onClick={() => prop.openForgotPasswordPage(true)}
                            style={{ textDecoration: 'underline', fontSize: 13 }}>
                            {i18next.t("forgot_password")}
                        </Link>
                    </Form.Item>
                    <Row style={{ height: 42 }}>
                        <Col span={4} />
                        <Col span={16}>
                            <Form.Item>
                                <Button block style={{
                                    backgroundColor: Theme.colors.primary, fontWeight: 600
                                }} shape='rounded' type="primary" size="large" htmlType="submit"
                                    loading={loggingIn}>
                                    {i18next.t("login")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </NJVForm>
                <Row>
                    <Col lg={6} md={4} sm={7} xs={4}></Col>
                    <Col lg={12} md={16} sm={10} xs={16}>
                        <Flex style={{ position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                            <Divider style={{ borderColor: '#212121' }} />
                            <span style={{
                                position: 'absolute',
                                backgroundColor: '#fff',
                                paddingInline: 10
                            }}>Get Ninjavan App on</span>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={2} sm={3} xs={0} />
                    <Col lg={16} md={20} sm={18} xs={24}>
                        <Flex justify={"space-between"} align={"center"} gap={8}>
                            <NJVButton style={{ width: 112 }} size={"large"}
                                icon={<SvgPlayStore width={20} height={20} />}
                                onClick={() => window.open(playStoreUrl)} ><span style={{
                                    fontSize: 11,
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexWrap: "wrap"
                                }}>
                                    Play
                                    <span>Store</span></span></NJVButton>
                            <NJVButton style={{ width: 112 }} size={"large"}
                                icon={<SvgAppleStore width={20} height={20} />}
                                onClick={() => window.open(appStoreUrl)} ><span style={{
                                    fontSize: 11,
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexWrap: "wrap"
                                }}>
                                    App
                                    <span>Store</span></span></NJVButton>
                            <NJVButton style={{ width: 110 }} size={"large"}
                                icon={<SvgDirectDownload
                                    width={20}
                                    height={20}
                                    color={Theme.colors.primary} />}
                                onClick={() => window.open(directUrl)}>
                                <span style={{
                                    fontSize: 11,
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexWrap: "wrap"
                                }}>
                                    Direct
                                    <span
                                        style={{ fontWeight: 'bold' }}>Download</span></span></NJVButton>
                        </Flex>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Login;