import { Col, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { updateMobileSize } from '../../redux/profile-slice';
import { useDispatch } from 'react-redux';

const AppContent = () => {
    const [isMobileSize, setIsMoblieSize] = useState(window.innerWidth < 768)
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleResize = () => {
        setIsMoblieSize(window.innerWidth < 768)
        dispatch(updateMobileSize(window.innerWidth < 768))
    }

    return (
        <Content
            style={{
                padding: '20px 0px',
                background: '#F5F7FA'
            }}
        >
            <Row>
                <Col span={24} style={{ paddingInline: isMobileSize ? 10 : 25 }}>
                    <Outlet />
                </Col>
            </Row>
        </Content>
    )
}

export default AppContent