import {
    CloseOutlined
} from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Col, Flex, Form, Image, Modal, QRCode, Row, Skeleton, Tabs } from "antd";
import i18next from "i18next";
import React from "react";
import PlatinumAward from '../asset/award_grey.png';
import ShipperAward from '../asset/award_red.png';
import GoldAward from '../asset/award_yellow.png';
import qrImage from '../asset/ninja_qr_image.png';
import ThumbnailImage from '../asset/ninja_thumbnail.jpg';
import { MainTitle, ModalTitle, NJVButton, NJVCard, NJVInput, NJVTabs } from "../components/core-component";
import { SvgCheck, SvgProfileEditFilled, SvgQRFilled } from '../components/custom-svgIcon';
import Theme from '../components/theme';
import { payment_type_map } from '../core/constant';
import Api from '../network/api';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import { ApiHandler } from '../network/network-manager';
import withRouter from "../network/with-router";

const CardDisplayLabel = (props) => {
    const { label, value } = props
    return <Col span={24} style={{ marginBottom: 15 }}>
        <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 3, color: 'gray' }}>
            {label}</div>
        <div style={{ fontSize: 16, background: '#ECECEC', display: 'flex', alignItems: 'center', padding: 8, borderRadius: 8 }}>
            {value}
        </div>
    </Col>
}

const navBarBubbleStyle = {
    width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f1f1f1', borderRadius: 100, marginRight: 5, cursor: 'pointer'
}

const shipper_profile = require('../asset/shipper_profile.png');

const awardMap = {
    'Shipper': ShipperAward,
    'Gold': GoldAward,
    'Platinum': PlatinumAward,
}

class ProfilePage2 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: null,
            openQR: false,
            loyaltyInfo: null
        }
        this.formRef = React.createRef();
    }

    componentDidMount = () => {
        this.fetchProfile()
        this.fetchLoyaltyInfo()
    }

    fetchProfile = async () => {
        this.setState({
            isFetchingData: true
        })
        await ApiHandler({ url: Api.shipper_profile, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    data: response
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isFetchingData: false
                })
            })
    }

    fetchLoyaltyInfo = async () => {
        await ApiHandler({ url: Api.loyalty_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    loyaltyInfo: response
                })
            })
            .catch((error) => {
            }).finally(() => {
            })
    }

    submitRequestSupport = async (value) => {
        this.setState({ is_submit_request: true })

        let formData = new FormData();
        formData.append('category', "Request Support")
        formData.append('subCategory', 'Shipper address or phone number change')
        formData.append('reason', value.reason)
        formData.append('type', 'NORMAL_ORDER')
        await ApiHandler({ url: Api.order_issue, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, disableShowMessage: true })
            .then(() => {
                this.formRef.current.resetFields();
                this.setState({
                    openEditInfo: false,
                    requestSuccessModal: true
                })
            })
            .catch(error => { })
            .finally(() => {
                this.setState({
                    is_submit_request: false
                })
            })
    }

    render() {
        const { data, openQR, isFetchingData, openEditInfo, is_submit_request, requestSuccessModal, loyaltyInfo } = this.state

        return (
            <>
                <Modal
                    title={null}
                    footer={null}
                    centered
                    open={openQR}
                    closeIcon={false}
                    onOk={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    okText="Logout"
                    cancelText="Cancel"
                    width={350}
                >
                    <div style={{ textAlign: 'center' }}>
                        <QRCode
                            errorLevel="H"
                            size={300}
                            iconSize={300 / 4}
                            value={this.props.shipperId}
                            icon={qrImage}
                            style={{
                                width: 'auto',
                                border: 0
                            }}
                        />
                        <h3>{this.props.shipperId}</h3>
                        <NJVButton type='primary' shape='circle' style={{ backgroundColor: 'red', borderRadius: '40 !important' }} onClick={() => {
                            this.setState({ openQR: false })
                        }}>
                            <CloseOutlined />
                        </NJVButton>
                    </div>
                </Modal>
                <Modal title={i18next.t("edit_profile_info")} centered open={openEditInfo} onCancel={() => { this.setState({ openEditInfo: false }) }} footer={null} width={400}>
                    <Form onFinish={this.submitRequestSupport} style={{ marginTop: 30 }} ref={this.formRef}>
                        <Form.Item name="reason" rules={[{ required: true, message: 'required **' }]}>
                            <NJVInput
                                bgcolor={Theme.colors.secondary}
                                size="large"
                                istextareainput="true"
                                placeholder={i18next.t("request_info")}
                                showCount
                                maxLength={200} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <NJVButton
                                type="primary"
                                style={{ backgroundColor: Theme.colors.primary }}
                                loading={is_submit_request}
                                size="large"
                                htmlType="submit"
                            >{i18next.t("submit_request")}</NJVButton>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title={null} centered width={350}
                    onOk={() => this.setState({ requestSuccessModal: false })} open={requestSuccessModal} footer={null} onCancel={() => this.setState({ requestSuccessModal: false })}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: 16 }}>
                        <SvgCheck width={50} height={50} color="#00BA00" />
                        <p style={{ fontWeight: 700, fontSize: 20 }}>{i18next.t("success")}</p>
                        <div style={{ fontWeight: 500 }}>{i18next.t("msg_success_request")}</div>
                    </div>
                </Modal>
                <Row>
                    <Col lg={5} md={5} sm={1} xs={1} />
                    <Col lg={14} md={14} sm={22} xs={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <MainTitle label="Profile" />
                            </Col>
                            {
                                isFetchingData ? <Skeleton /> :
                                    <>
                                        <Col span={24} style={{ marginBottom: 5 }}>
                                            <NJVCard>
                                                <Flex justify='space-between' align='center'>
                                                    <Flex>
                                                        <Image
                                                            preview={false}
                                                            onClick={(e) => e.preventDefault()}
                                                            style={{
                                                                width: 60,
                                                                height: 60,
                                                                objectFit: 'cover',
                                                                cursor: 'pointer',
                                                                borderRadius: data?.profileUrl ? 100 : 0
                                                            }}
                                                            src={data?.profileUrl ? data?.profileUrl : shipper_profile} />
                                                        <Flex align='center'>
                                                            <div style={{ marginLeft: 20 }}>
                                                                <Flex gap={10} style={{ marginBottom: 10 }}>
                                                                    <Image src={awardMap[loyaltyInfo?.currentMonthLevel]} width={20} height={25} preview={false} />
                                                                    <div style={{ fontSize: 16, fontWeight: 600 }}>{data?.fullName}</div>
                                                                </Flex>
                                                                <div style={{ color: 'gray', fontWeight: 'bold' }}>{data?.business?.businessName}</div>
                                                            </div>

                                                        </Flex>
                                                    </Flex>
                                                    <Flex align='center'>
                                                        <div
                                                            onClick={() => {
                                                                this.setState({ openEditInfo: true })
                                                            }}
                                                            style={navBarBubbleStyle}>
                                                            <SvgProfileEditFilled width={20} height={20} color={Theme.colors.primary} />
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                this.setState({ openQR: true })
                                                            }}
                                                            style={navBarBubbleStyle}>
                                                            <SvgQRFilled width={20} height={20} color={Theme.colors.primary} />
                                                        </div>
                                                    </Flex>
                                                </Flex>
                                            </NJVCard>
                                        </Col>

                                        <Col span={24}>
                                            <NJVTabs
                                                tabPosition={"top"}
                                                items={[
                                                    {
                                                        label: 'Personal Information',
                                                        key: 1,
                                                        children: <NJVCard>
                                                            <CardDisplayLabel label={"Unique ID"} value={data?.uniqueId} />
                                                            <CardDisplayLabel label={"Full Name"} value={data?.fullName} />
                                                            <CardDisplayLabel label={"Phone Number"} value={data?.phoneNumber} />
                                                            <CardDisplayLabel label={"Email Address"} value={data?.emailAddress} />
                                                            <CardDisplayLabel label={"Address"} value={data?.address1} />
                                                        </NJVCard>,
                                                    },
                                                    {
                                                        label: 'Business Information',
                                                        key: 2,
                                                        children: <NJVCard>

                                                            {
                                                                data?.business ?
                                                                    <Row>
                                                                        <CardDisplayLabel label={"Business Name"} value={data?.business?.businessName} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />

                                                                        {
                                                                            data?.business.productCategory ?
                                                                                <CardDisplayLabel label={"Business Type"} value={data?.business?.productCategory.name} />
                                                                                :
                                                                                <></>
                                                                        }
                                                                        <CardDisplayLabel label={"E-commerce Registration Number"} value={data?.business?.ecommerceRegNumber} />
                                                                        {
                                                                            data?.business?.businessOwnerNRC &&
                                                                            <CardDisplayLabel label={"Business Owner NRC"} value={data?.business?.businessOwnerNRC} />
                                                                        }
                                                                        <CardDisplayLabel label={"Facebook Page Link"} value={data?.business?.facebookPageLink} />
                                                                        <CardDisplayLabel label={"Primary Payment Type"} value={payment_type_map[data?.business?.paymentType]} />
                                                                        <CardDisplayLabel label={"Primary Payment Number"} value={data?.business?.paymentNumber} style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />
                                                                        {
                                                                            data?.business?.bankBookImg &&
                                                                            <Col span={24} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                                                <ModalTitle label={i18next.t("bank_acc_photo")} />
                                                                                <Image width={200} height={180} src={data?.business?.bankBookImg} style={{ borderRadius: 8, marginTop: 5 }} fallback={ThumbnailImage} />
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    :
                                                                    <></>
                                                            }
                                                        </NJVCard>,
                                                    }
                                                ]}

                                            />

                                        </Col>
                                    </>
                            }

                        </Row>
                    </Col >
                </Row >
            </>
        )
    }
}

export default compose(withRouter)(ProfilePage2)