import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider as Provider, useQuery } from '@apollo/client';
import { ENUM_QUERY } from './graph-qurey';

const client = new ApolloClient({
    uri: process.env.REACT_APP_SERVER_URL +"/graphql",
    cache: new InMemoryCache()
});

const GraphProvider = ({ children }) => {
    return <Provider client={client}>{children}</Provider>;
}
export default GraphProvider

export const OrderStatusProvider = ({ children }) => {
    let { loading, error, data } = useQuery(ENUM_QUERY, { variables: { enumType: "ORDER_STATUS" } });
    let temp = []
    data?.fetchEnum?.map((res) => {
        if (res.key === 'ARRIVED_AT_ORIGIN_HUB' || res.key === 'ARRIVED_AT_SORTING_HUB' || res.key === 'ARRIVED_AT_TRANSIT_HUB' ||
            res.key === 'ARRIVED_AT_DESTINATION_HUB' || res.key === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' || res.key === 'AT_PUDO') {
        } else {
            temp.push({
                value: res.key,
                label: res.value,
            });
        }
    })
    data = temp
    return children({ loading, error, data });
};