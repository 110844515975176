import { Col, Image, Row, Skeleton } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { TitleLevel3 } from '../../components/general-component'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import { MainTitle } from '../../components/core-component'

class Coverage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetchingData: false,
            imageItems: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isFetchingData: true
        })
        await ApiHandler({url: Api.coverage_image, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON})
            .then((response) => {
                this.setState({
                    isFetchingData: false
                })
                response.map((item) => {
                    this.setState((prevState) => ({
                        imageItems: [...prevState.imageItems, item]
                    }
                    ))
                })
            })
            .catch((error) => {
                this.setState({
                    isFetchingData: false
                })
            })


    }

    render() {
        const { imageItems, isFetchingData } = this.state;

        return (
            <Row>
                <Col lg={6} md={5} sm={0} xs={0} />
                <Col lg={12} md={14} sm={24} xs={24}>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <MainTitle label={i18next.t("price_coverage")} />
                        </Col>
                        {isFetchingData ? (
                            <Skeleton active />
                        ) : (
                            <div>
                                {imageItems?.map(img => (
                                    <Image
                                        key={img.id}
                                        alt="example"
                                        style={{ width: '100%',background: 'red' }}
                                        src={img.photoUrl}
                                        preview={false}
                                    />
                                ))}
                            </div>
                        )}
                    </Row>
                </Col>
            </Row>

        )
    }
}

export default Coverage;