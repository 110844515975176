import { Col, Image, message, Row, Skeleton, Tag, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { NJVBackButtonWithIcon, NJVButton, NJVCard, NJVInput } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandlerNoAuth } from '../../network/network-manager';
import CustomPath from '../../routes/custom-path';
import { Link } from 'react-router-dom';
const { Text } = Typography;
const TitleLabel = ({ label }) => (
    <div style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</div>
)

const TextLabel = ({ label }) => (
    <div style={{ fontSize: 15, fontWeight: '600', marginBottom: 10 }}>{label}</div>
)

const StatusTitle = ({ title, date, color }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 13, fontWeight: '600', marginBottom: 10, color: 'white', backgroundColor: color ? color : Theme.colors.default, padding: '1px 10px', borderRadius: 4 }}>
        <span>{title}</span>
        <span>{date}</span>
    </div>
)
const logo = require('../../asset/Logo.png');
class TicketRepairSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ticketDetailData: null,
            timeLineItem: [],
            isFetching: false,
            ticketId: null,
            isSearch: false
        }
    }
    fetchData = async () => {
        const { ticketId } = this.state
        if (ticketId === null || ticketId === '' || ticketId === undefined) {
            message.error("Please enter a ticket id")
            return
        }
        this.setState({ isFetching: true, isSearch: true })
        const requestParams = {
            ticketId
        }
        ApiHandlerNoAuth({ url: Api.ticket_repair_search, method: HTTP_METHOD.GET, requestParams })
            .then(response => {
                let temp = [];
                let ticketDetailData = null
                if (response.receiverITName) {
                    temp.push({
                        key: 'receiverITName',
                        children: (
                            <>
                                <StatusTitle title={"Receive By IT"} date={dayjs(response?.receiveTime).format("MMM DD, YYYY hh:mm A")} />
                                <TitleLabel label="IT ဌာနမှ လက်ခံသူအမည်" />
                                <TextLabel label={response?.receiverITName} />
                                <TitleLabel label="ပစ္စည်းအခြေအနေ" />
                                <TextLabel label={response?.receiveDeviceCondition} />
                            </>
                        )
                    })
                }
                if (response.ticketRepairShop) {
                    temp.push({
                        children: (
                            <>
                                <StatusTitle title={"At Repair Shop"} date={dayjs(response?.ticketRepairShop?.createdDate).format("MMM DD, YYYY hh:mm A")} />
                                <TitleLabel label="ဆိုင်နာမည်" />
                                <TextLabel label={response?.ticketRepairShop?.shopName} />
                                <TitleLabel label="ဆက်သွယ်ရန်အချက်အလက်" />
                                <TextLabel label={response?.ticketRepairShop?.contactInfo} />
                                <TitleLabel label="ပြန်လည်ရောက်မည့် ရက်" />
                                <TextLabel label={response?.ticketRepairShop?.expectedReturnDate} />
                                <TitleLabel label="ပြုပြင်စရိတ်" />
                                <TextLabel label={response?.ticketRepairShop?.expectedRepairCost} />
                            </>
                        )
                    })
                }
                if (response.ticketRepairReturn) {
                    temp.push({
                        children: (
                            <>
                                <StatusTitle title={"Returned To IT"} date={dayjs(response?.ticketRepairShop?.createdDate).format("MMM DD, YYYY hh:mm A")} />
                                <TitleLabel label="လက်ခံသူအမည်" />
                                <TextLabel label={response?.ticketRepairReturn?.receiverName} />
                                <TitleLabel label="ပြုပြင်စရိတ်" />
                                <TextLabel label={response?.ticketRepairReturn?.repairCost} />
                                <TitleLabel label="ပစ္စည်းအခြေအနေ" />
                                <TextLabel label={response?.ticketRepairReturn?.deviceCondition} />
                                <TitleLabel label="ထပ်လောင်းမှတ်စုများ" />
                                <TextLabel label={response?.ticketRepairReturn?.additionalNotes} />
                            </>
                        )
                    })
                }
                if (response.ticketRepairComplete) {
                    temp.push({
                        color: 'green',
                        children: (
                            <>
                                <StatusTitle title={"Completed"} date={dayjs(response?.ticketRepairShop?.createdDate).format("MMM DD, YYYY hh:mm A")} color={Theme.colors.success} />
                                <TitleLabel label="လက်ခံသူအမည် (၀န်ထမ်းနံပါတ်)" />
                                <TextLabel label={`${response?.ticketRepairComplete?.receiverName} (${response?.ticketRepairComplete?.employeeId})`} />
                                <TitleLabel label="တာ၀န်ထမ်းဆောင်ရာဌာန" />
                                <TextLabel label={response?.ticketRepairComplete?.department} />

                                <>
                                    {
                                        response?.ticketRepairComplete?.images && response?.ticketRepairComplete?.images?.length !== 0 ?
                                            <>
                                                <TitleLabel label="Completetion Photos" /><br />
                                                {
                                                    response?.ticketRepairComplete?.images.map((item) => <Image width={100} height={100} src={item} />)
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </>
                            </>
                        )
                    })
                }
                ticketDetailData = { ...response, status: '' }
                if (ticketDetailData?.ticketRepairStatus === 'PENDING') {
                    ticketDetailData['status'] = 'Pending'
                } else if (ticketDetailData?.ticketRepairStatus === 'RECEIVE_BY_IT') {
                    ticketDetailData['status'] = 'Received by IT'
                } else if (ticketDetailData?.ticketRepairStatus === 'AT_REPAIR_SHOP') {
                    ticketDetailData['status'] = 'At Repair Shop'
                } else if (ticketDetailData?.ticketRepairStatus === 'RETURNED_TO_IT') {
                    ticketDetailData['status'] = 'Returned to IT'
                } else if (ticketDetailData?.ticketRepairStatus === 'COMPLETED') {
                    ticketDetailData['status'] = 'Completed'
                }

                this.setState({
                    ticketDetailData,
                    timeLineItem: temp,
                })
            })
            .catch(err => {
                this.setState({
                    ticketDetailData: null,
                    timeLineItem: [],
                })
            })
            .finally(() => { this.setState({ isFetching: false }) })
    }
    render() {
        const { ticketDetailData, timeLineItem, isFetching, isSearch } = this.state
        return (
            <Row>
                <Col lg={7} md={6} sm={1} xs={1} />
                <Col lg={10} md={12} sm={22} xs={22}>
                    <Row>
                        <Col span={24} style={{ marginTop: 10 }}>
                            <Image width={200} height={67} src={logo} preview={false} style={{ padding: '5px 0' }} />
                        </Col>
                        <Col span={24} style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                            <Link to={CustomPath.ticket_repair_request}>
                                <NJVBackButtonWithIcon />
                            </Link>
                            <TitleLevel3 label="Repair Ticket's Status စစ်ဆေးရန်" />
                        </Col>
                        <Col span={24} style={{ marginTop: 20 }}>
                            <NJVCard className="card-shadow" cardBgColor={Theme.colors.white} style={{ marginBottom: 20, marginTop: 10 }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <NJVInput onChange={(e) => this.setState({ ticketId: e.target.value })} placeholder="Ticket ID ရိုက်ထည့်ပါ" bgcolor={Theme.colors.light_gray} size='large' />
                                    </Col>
                                    <Col span={24}>
                                        <NJVButton primarycolor={Theme.colors.primary} type="primary" size="middle" onClick={() => this.fetchData()}>ရှာမည်</NJVButton>
                                    </Col>
                                </Row>
                            </NJVCard>
                            {
                                isFetching ?
                                    <Skeleton active />
                                    :
                                    <>
                                        {
                                            ticketDetailData ?
                                                <>
                                                    <NJVCard title={"တောင်းဆိုသူအချက်အလက်"} className="card-shadow" cardBgColor={Theme.colors.white} style={{ marginBottom: 20 }}>
                                                        <TitleLabel label="၀န်ထမ်းအမည် (၀န်ထမ်းနံပါတ်)" />
                                                        <TextLabel label={`${ticketDetailData?.requestorName} (${ticketDetailData?.employeeId})`} />
                                                        <TitleLabel label="ဆက်သွယ်ရန်ဖုန်းနံပါတ်" />
                                                        <TextLabel label={ticketDetailData?.contactInfo} />
                                                        <TitleLabel label="တာ၀န်ထမ်းဆောင်ရာဌာန" />
                                                        <TextLabel label={ticketDetailData?.departmentName} />
                                                        <TitleLabel label="တာ၀န်ထမ်းဆောင်ရာလိပ်စာ" />
                                                        <TextLabel label={ticketDetailData?.address} />
                                                    </NJVCard>
                                                    <NJVCard title={"ပစ္စည်းအချက်အလက်"} className="card-shadow" cardBgColor={Theme.colors.white} style={{ marginBottom: 20 }}>
                                                        <TitleLabel label="ပစ္စည်းအမျိုးအစားရွေးချယ်ပါ" />
                                                        <TextLabel label={ticketDetailData?.deviceType} />
                                                        <TitleLabel label="Asset Codeနံပါတ်" />
                                                        <TextLabel label={ticketDetailData?.assetId} />
                                                        <TitleLabel label="Issueအကြောင်းအရာရေးပါ" />
                                                        <TextLabel label={ticketDetailData?.issueDescription} />
                                                        <TitleLabel label="အရေးကြီးမှုအဆင့်" />
                                                        <TextLabel label={ticketDetailData?.urgencyLevel} />

                                                        <TitleLabel label="Photo" />
                                                        <>
                                                            {
                                                                ticketDetailData?.images.map((item) => <Image width={100} height={100} src={item} />)
                                                            }
                                                        </>
                                                    </NJVCard>
                                                    <NJVCard
                                                        title={"Ticket အခြေအနေ"}
                                                        className="card-shadow"
                                                        cardBgColor={Theme.colors.white}
                                                        style={{ marginBottom: 20, }}
                                                        extra={<Tag color={Theme.colors.default}>{ticketDetailData?.status}</Tag>}>
                                                        {
                                                            timeLineItem && timeLineItem.length !== 0 ?
                                                                <Timeline items={timeLineItem} />

                                                                :
                                                                <></>
                                                        }
                                                    </NJVCard>
                                                </>
                                                :
                                                <>
                                                    {
                                                        isSearch ?
                                                            <NJVCard className="card-shadow" cardBgColor={Theme.colors.white} style={{ marginBottom: 20 }}>
                                                                <Text type="danger">We can't find this ticket</Text>
                                                            </NJVCard>
                                                            : <></>
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </Col>
                    </Row>

                </Col >
            </Row >
        );
    }
}

export default TicketRepairSearch;