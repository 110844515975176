import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyD-TG0oZIcRG229lCF9CDf0W1XthFmp8og",
    authDomain: "prod-shipper-portal.firebaseapp.com",
    projectId: "prod-shipper-portal",
    storageBucket: "prod-shipper-portal.appspot.com",
    messagingSenderId: "226892795898",
    appId: "1:226892795898:web:f258d5a4a66681527ede43",
    measurementId: "G-72N7XSZC6P"
}

const app = initializeApp(firebaseConfig)
// const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export { analytics };