import { compose } from "@reduxjs/toolkit";
import { Col, Collapse, Flex, Image, Row, Skeleton, Typography } from "antd";
import i18next from "i18next";
import React from "react";
import ninjaFaq from '../asset/ninja-faq.png';
import NoData from '../asset/no-data-found.png';
import { NJVCard, NJVCollapse, NJVSearchBoxCard } from "../components/core-component";
import { SvgChevronDown, SvgChevronRight } from "../components/custom-svgIcon";
import Theme from "../components/theme";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import withRouter from "../network/with-router";

class FAQ extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isDataFetching: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const params = {
            "targetType": "SHIPPER"
        }
        this.setState({ isDataFetching: true })
        try {
            const response = await ApiHandler({ url: Api.faq, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: params })
            if (response) {
                this.setState({ isDataFetching: false, data: response })
            }
        } catch (error) {

        }
    }

    handleFilter = (e) => {
        const { data } = this.state;
        const filtered = data?.map((item) => {
            const filteredFaqs = item.faqs?.filter(faq =>
                item.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                faq.question?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                faq.questionMM?.toLowerCase().includes(e.target.value.toLowerCase())
            );

            if (filteredFaqs && filteredFaqs.length > 0) {
                return {
                    ...item,
                    faqs: filteredFaqs
                }
            }
            return null;
        }
        ).filter(item => item !== null);

        this.setState({
            filtered_data: filtered
        })
    }

    render() {
        const { data, isDataFetching, filtered_data } = this.state

        const itemsNest = filtered_data?.length > 0 ? filtered_data?.map(faq => faq?.faqs?.length > 0 && faq?.faqs?.map(faqs => ({
            key: faqs.id,
            label: faqs.question,
            children: faqs.answer.split('\n').map((faq, index) => <span style={{ fontWeight: 600 }} key={index}>{faq} <br /></span>),
        }))) : data?.map(faq => faq?.faqs?.length > 0 && faq?.faqs?.map(faqs => ({
            key: faqs.id,
            label: faqs.question,
            children: faqs.answer.split('\n').map((faq, index) => <span style={{ fontWeight: 600 }} key={index}>{faq} <br /></span>),
        })))

        const items = filtered_data?.length > 0 ? filtered_data?.map((faqData, index) => ({
            key: index,
            label: faqData.title,
            children: <Collapse defaultActiveKey="1" items={itemsNest[index]} />,
        })) : data?.map((faqData, index) => ({
            key: index,
            label: faqData.title,
            children: <Collapse defaultActiveKey="1" items={itemsNest[index]} />,
        }));

        return (
            <NJVCard
                cardheaderbg={Theme.colors.primary}
                headerheight="225px"
                cardpadding="0px"
                title={null}
            >
                <Row style={{ background: Theme.colors.primary, borderTopLeftRadius: 8, borderTopRightRadius: 8, padding: '30px 40px' }}>
                    <Col lg={16} md={16} style={{ display: 'flex' }}>
                        <Flex vertical justify="center">
                            <Typography.Title level={3} style={{ color: '#fff', marginTop: 0 }}>{i18next.t("faq")}</Typography.Title>
                            <Typography.Paragraph style={{ color: '#fff', fontWeight: 600, marginTop: 10, marginBottom: 0, fontSize: 15 }}>
                                {i18next.t("msg_faq")}
                            </Typography.Paragraph>
                        </Flex>
                    </Col>
                    <Col lg={8} md={8} sm={0} xs={0} style={{ textAlign: 'right' }}>
                        <Image src={ninjaFaq} preview={false} width={120} height={150} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={1}></Col>
                    <Col span={22} style={{ paddingBlock: 50 }}>
                        {
                            isDataFetching ?
                                <Skeleton />
                                :
                                items?.length === 0 ?
                                    <Flex justify='center' align='center' style={{ marginTop: 80 }}>
                                        <Image src={NoData} alt='no faq' width={120} preview={false} />
                                    </Flex> :
                                    <>
                                        <NJVSearchBoxCard placeholder={i18next.t('enter_your_question')} buttonname={i18next.t("search")} handleFilter={this.handleFilter} showSearch={false} />
                                        <NJVCollapse items={items}
                                            expandIconPosition="end"
                                            bordered={false}
                                            expandIcon={({ isActive }) => <>
                                                {isActive ? <SvgChevronDown width={20} height={20} color={'black'} /> : <SvgChevronRight width={20} height={20} color={'black'} />}</>}
                                        />
                                    </>
                        }
                    </Col>
                </Row>
            </NJVCard>
        )
    }
}

export default compose(withRouter)(FAQ)
