import {
    RightCircleFilled
} from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, ConfigProvider, Divider, Flex, Image, Progress, Row, Skeleton, Typography } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PlatinumAward from '../../asset/award_grey.png'
import ShipperAward from '../../asset/award_red.png'
import GoldAward from '../../asset/award_yellow.png'
import { MainTitle, NJVCard } from '../../components/core-component'
import { BackTitleCompo } from '../../components/general-component'
import Theme from '../../components/theme'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'
import HowItWork from './how-it-work'

const awardMap = {
    'Shipper': ShipperAward,
    'Gold': GoldAward,
    'Platinum': PlatinumAward,
}

const pointInfoList = [
    {
        'image': ShipperAward,
        'level': 'Shipper',
        'point': '1 - 9'
    },
    {
        'image': GoldAward,
        'level': 'Gold',
        'point': '10 - 199'
    },
    {
        'image': PlatinumAward,
        'level': 'Platinum',
        'point': '200 and above'
    }
]

class PointPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loyaltyInfo: this.props?.loyaltyInfo?.data || null,
            isFetchingData: false,
        }
    }

    render() {
        const { loyaltyInfo, isFetchingData } = this.state
        const { isMobileSize } = this.props;

        return (
            <NJVCard title={
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={24}>
                        <BackTitleCompo label={'Point Information'} onClickRoute={CustomPath.loyalty} />
                    </Col>
                </Row>}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <NJVCard
                            cardpadding="13px"
                            cardbgcolor="#f7f7f7">
                            {
                                isFetchingData ? <Skeleton /> : <Flex vertical gap={20}>
                                    <Flex justify='space-between'>
                                        <Flex align='center' gap={10}>
                                            <Image src={awardMap[loyaltyInfo?.currentMonthLevel]} preview={false} width={45} height={49} />
                                            <div>
                                                <Typography.Text style={{ margin: 0 }}>Member - <span style={{ fontWeight: 'bold', fontSize: 16, textTransform: 'uppercase' }}>{loyaltyInfo?.currentMonthLevel}</span></Typography.Text>
                                                <Typography.Title level={5} style={{ margin: 0, color: Theme.colors.primary, fontWeight: 'bold' }}>ID No - {loyaltyInfo?.uniqueId}</Typography.Title>
                                            </div>
                                        </Flex>
                                        {
                                            isMobileSize ?
                                                <Flex vertical align='center' style={{ position: 'absolute', right: 2, bottom: 45 }}>
                                                    <Typography.Text style={{ fontWeight: 'bold' }}>{`${loyaltyInfo?.nextMonthLevel === 'Platinum' ? '' : 'Progress to '}`}{loyaltyInfo?.nextMonthLevel}</Typography.Text>
                                                    <Image src={awardMap[loyaltyInfo?.nextMonthLevel]}
                                                        alt="level"
                                                        width={50}
                                                        height={50}
                                                        preview={false}
                                                    />
                                                </Flex>
                                                :
                                                <Flex align='center' gap={10}>
                                                    <Typography.Text style={{ fontWeight: 'bold' }}>{`${loyaltyInfo?.nextMonthLevel === 'Platinum' ? '' : 'Progress to '}`}{loyaltyInfo?.nextMonthLevel}</Typography.Text>
                                                    <Image src={awardMap[loyaltyInfo?.nextMonthLevel]}
                                                        alt="level"
                                                        width={50}
                                                        height={50}
                                                        preview={false}
                                                    />
                                                </Flex>
                                        }
                                    </Flex>
                                    <ConfigProvider theme={{
                                        components: {
                                            Progress: {
                                                defaultColor: Theme.colors.primary,
                                                // remainingColor: 'linear-gradient(to left, #c2002f, #ff5e66)'
                                                // remainingColor: '#c2002f'
                                            }
                                        }
                                    }}>
                                        <Progress percent={Math.round(loyaltyInfo?.nextMonthLevelPercent)} percentPosition={{
                                            align: 'end',
                                            type: 'inner',
                                        }} size={['100%', 15]} />
                                    </ConfigProvider>
                                    <Typography.Text>Valid till {dayjs(loyaltyInfo?.validDate).format('YYYY-MM-DD')}</Typography.Text>
                                </Flex>
                            }
                        </NJVCard>
                        <ConfigProvider theme={{
                            components: {
                                Divider: {
                                    orientationMargin: 4
                                }
                            }
                        }}>
                            <Divider variant='dotted' style={{ margin: 0 }} />
                        </ConfigProvider>
                        <NJVCard
                            cardpadding="13px"
                            cardbgcolor="#f7f7f7">
                            <Link to={CustomPath.point_history}>
                                <Flex gap={10} justify='right' align='center'>
                                    <Typography.Title level={5} style={{ margin: 0, color: Theme.colors.primary }}>Point History</Typography.Title>
                                    <RightCircleFilled style={{ fontSize: 25, color: Theme.colors.primary, marginRight: 10 }} />
                                </Flex>
                            </Link>
                        </NJVCard>
                    </Col>
                    <Col span={24}>
                        <MainTitle label={"Target Points"} />
                        <Row gutter={[16, 24]} style={{ marginTop: 20 }}>
                            {
                                pointInfoList?.map((list, index) => (
                                    <Col lg={8} md={8} sm={24} xs={24} key={index}>
                                        <NJVCard cardbgcolor="#f7f7f7">
                                            <Flex align='center' gap={20}>
                                                <Image src={list.image} preview={false} width={45} height={49} />
                                                <Flex vertical>
                                                    <Typography.Text style={{ fontWeight: 'bold' }}>{list.level}</Typography.Text>
                                                    <Typography.Text style={{ fontWeight: 500, color: Theme.colors.primary }}>{list.point}</Typography.Text>
                                                </Flex>
                                            </Flex>
                                        </NJVCard>
                                    </Col>
                                ))
                            }
                        </Row>
                        <HowItWork />
                    </Col>
                </Row>
            </NJVCard>
        )
    }
}

export default compose(withRouter)(PointPage)