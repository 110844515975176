
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase-config";

export const logView = (which_page) => {
    try {
        logEvent(analytics, 'banner_visit', {
            page: which_page,
        })
    } catch (error) {
        console.error(`Error logging view event: ${error}`);
    }
};

export const logClick = (which_page, which_banner) => {

    try {
        logEvent(analytics, 'banner_click', {
            banner_info: `${which_banner} inside ${which_page}`
        })
    } catch (error) {
        console.error(`Error logging click event: ${error}`);
    }
}