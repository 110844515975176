import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";

import RouteGuard from "../components/route-guard";
import withRouter from "../network/with-router";
import OrderCreateFailLog from "../page/activity-log/order-create-fail-log";
import AWBPage from "../page/awb/AWB-page";
import PrintAirwayBillSlip from "../page/awb/print-airway-bill-slip";
import Coverage from "../page/coverage/coverage";
import DashboardPage from "../page/dashboard";
import DeleteAccountPage from "../page/deleteAccountPage";
import NotFound from '../page/error-page/not-found';
import RedirectLogin from "../page/error-page/redirect-login";
import Faq from "../page/faq";
import ImageGuide from "../page/image-guide";
import InvoicePage from "../page/invoice/invoice-page";
import PaymentHistory from "../page/invoice/payment-history";
import UserAddressForm from '../page/location/user-address-form';
import UserAddressPage from "../page/location/user-address-page";
import UserAddressUpdate from "../page/location/user-address-update";
import ForgotPassword from "../page/login/forgot-password";
import LogoutPage from "../page/login/logout-page";
import SignUpPage from "../page/login/sign-page";
import NotificationDetail from "../page/notification/notification-detail";
import NotificationPage from "../page/notification/notification-page";
import PickupCall from "../page/pickup-call/pickup-call";
import PrivacyAndPolicyPage from "../page/privacy-and-policy/privacy-and-policy-page";
import PublicProductApp from "../page/product/PublicApp";
import CartItems from "../page/product/cart-items-list";
import ProductDetail from "../page/product/product-detail";
import Products from "../page/product/products";
import ProfilePage from '../page/profilePage';
import PublicOrderDetail from "../page/public/public-order-detail";
import PublicApp from "../page/public/publicApp";
import QuickVoucherCustomerConfirm from "../page/public/quick-voucher-customer-confirm";
import CreateVoucher from "../page/quick-voucher/create-voucher";
import QuickVoucher from "../page/quick-voucher/quick-voucher";
import UpdateVoucher from "../page/quick-voucher/update-voucher";
import RtsOrderDetail from "../page/rts/rts-order-detail";
import RtsOrderPage from "../page/rts/rts-order-page";
import NewAdvanceOrderForm from "../page/shipper-order/new-advance-order-form";
import OrderReturnForm from "../page/shipper-order/order-return-form";
import RomRequest from "../page/shipper-order/rom-request";
import AddProduct from "../page/store/add-product";
import AllProducts from "../page/store/all-products";
// import UpdateProduct from "../page/store/update-product";
import TermsAndCondition from "../page/terms-and-condition/terms-and-condition";
import TutorialPage from "../page/tutorial";
import CustomPath from "./custom-path";
import PrinterSetting from "../page/printer-setting";
import TestApollo from "../page/TestApollo";
import OrderCreateWithMaintain from "../page/order-create-with-maintain";
import PrivacyAndPolicyDriver from "../page/privacy-and-policy/privacy-and-policy-driver";
import Feedback from "../page/feedback/feedback";
import PaymentInfo from "../page/invoice/payment-info";
import ProfilePage2 from "../page/profile-page-2";
import UploadVideo from "../page/test-22";
import TicketRepairRequestForm from "../page/ticket-repair/ticket-repair-request-form";
import TicketRepairPage from "../page/ticket-repair/ticket-repair-page";
import TicketRepairSearch from "../page/ticket-repair/ticket-repair-search";
import LoyaltyPage from "../page/loyalty/loyalty-page";
import LoyaltyShopDetail from "../page/loyalty/loyalty-shop-detail";
import PointPage from "../page/loyalty/point-page";
import PointHistory from "../page/loyalty/point-history";
import ShopTypePage from "../page/loyalty/shop-type";
import LoyaltyFaq from "../page/loyalty/loyalty-faq";
import NearbyPage from "../page/nearby/nearby-page";
import LandingPage from "../page/login/landing-page";
import ParcelHistory from "../page/shipper-order/parcel-history";
import ParcelDetail from "../page/shipper-order/parcel-detail";
import SingleParcelResult from "../page/single-parcel-result";

class Router extends Component {

    render() {
        return (
            <Routes>
                {
                    this.props.authenticated ?
                        <>
                            <Route element={<App />}>
                                <Route path={CustomPath.profile} element={<ProfilePage2 />} />
                                <Route path={CustomPath.dashboard} element={<DashboardPage />} />
                                <Route path={CustomPath.user_address} element={<UserAddressPage />} />
                                <Route path={CustomPath.user_address_create} element={<UserAddressForm />} />
                                <Route path={CustomPath.user_address_update_param} element={<UserAddressUpdate />} />
                                <Route path={CustomPath.nearby_ninjas} element={<NearbyPage />} />
                                <Route path={CustomPath.order_create} element={<OrderCreateWithMaintain />} />
                                <Route path={CustomPath.pickup_call} element={<PickupCall />} />
                                <Route path={CustomPath.confirm_order} element={<QuickVoucher />} />
                                <Route path={CustomPath.confirm_order_create} element={<CreateVoucher />} />
                                <Route path={CustomPath.confirm_order_update} element={<UpdateVoucher />} />
                                <Route path={CustomPath.rom_order} element={<RomRequest />} />
                                <Route path={CustomPath.order_history} element={<ParcelHistory status="fetch" />} />
                                {/* <Route path={CustomPath.order_history} element={<OrderHistory />} /> */}
                                <Route path={CustomPath.store} element={<AllProducts />} />
                                <Route path={CustomPath.add_product} element={<AddProduct />} />
                                {/* <Route path={CustomPath.update_product} element={<UpdateProduct />} /> */}
                                <Route path={CustomPath.order_create_fail_log} element={<OrderCreateFailLog />} />
                                <Route path={CustomPath.order_return} element={<OrderReturnForm />} />
                                <Route path={CustomPath.order_awb} element={<AWBPage />} />
                                <Route path={CustomPath.order_detail} element={<ParcelDetail />} />
                                <Route path={CustomPath.rts_order} element={<RtsOrderPage />} />
                                <Route path={CustomPath.rts_order_detail} element={<RtsOrderDetail />} />
                                <Route path={CustomPath.notification} element={<NotificationPage />} />
                                <Route path={CustomPath.notification_detail} element={<NotificationDetail />} />
                                <Route path={CustomPath.terms_and_condition} element={<TermsAndCondition />} />
                                <Route path={CustomPath.coverage} element={<Coverage />} />
                                <Route path={CustomPath.guide} element={<TutorialPage />} />
                                <Route path={CustomPath.logout} element={<LogoutPage />} />
                                <Route path={CustomPath.single_order_result_param} element={<SingleParcelResult />} />
                                <Route path={CustomPath.faq} element={<Faq />} />
                                <Route path={CustomPath.feedback} element={<Feedback />} />
                                <Route path={CustomPath.invoice} element={<InvoicePage />} />
                                <Route path={CustomPath.payment_history} element={<PaymentHistory />} />
                                <Route path={CustomPath.payment_info} element={<PaymentInfo />} />
                                <Route path={CustomPath.privacy_and_policy} element={<PrivacyAndPolicyPage />} />
                                {/* <Route path={CustomPath.printer_setting} element={<PrinterSetting />}/> */}
                                {/* <Route path="/*" element={<NotFound />} /> */}
                                <Route path={CustomPath.loyalty} element={<LoyaltyPage />} />
                                <Route path={CustomPath.loyalty_shop_detail_params} element={<LoyaltyShopDetail />} />
                                <Route path={CustomPath.shops} element={<ShopTypePage />} />
                                <Route path={CustomPath.point} element={<PointPage />} />
                                <Route path={CustomPath.point_history} element={<PointHistory />} />
                                <Route path={CustomPath.loyalty_faq} element={<LoyaltyFaq />} />
                            </Route>
                            <Route path="/*" element={<NotFound />} />
                            {/* <Route path={CustomPath.login} element={<RouteGuard><LandingPage /></RouteGuard>} /> */}
                        </>
                        :
                        <Route path="/*" element={<RedirectLogin />} />
                }
                <Route element={<PublicApp />}>
                    <Route path={CustomPath.public_parcel_detail} element={<PublicOrderDetail />} />
                    <Route path={CustomPath.quick_voucher_customer_confirm} element={<QuickVoucherCustomerConfirm />} />
                </Route>
                <Route path={CustomPath.privacy_and_policy_public} element={<PrivacyAndPolicyPage />} />
                <Route path={CustomPath.privacy_and_policy_public_driver} element={<PrivacyAndPolicyDriver />} />
                <Route element={<PublicProductApp />}>
                    <Route path={CustomPath.products_param} element={<Products />} />
                    <Route path={CustomPath.product_detail} element={<ProductDetail />} />
                    <Route path={CustomPath.cart_items_list} element={<CartItems />} />
                </Route>
                <Route path={CustomPath.login} element={<LandingPage />} />
                <Route path={CustomPath.airwaybill} element={<PrintAirwayBillSlip />} />
                <Route path={CustomPath.forgot_password} element={<ForgotPassword />} />
                <Route path={CustomPath.delete_account} element={<DeleteAccountPage />} />
                <Route path={CustomPath.guide_public} element={<ImageGuide />} />
                <Route path="testApollo" element={<TestApollo />} />
                <Route path={CustomPath.public_guide} element={<ImageGuide />} />
                <Route path={"/test"} element={<UploadVideo />} />
                <Route path={CustomPath.ticket_repair_request} element={<TicketRepairPage />} />
                <Route path={CustomPath.ticket_repair_request_form} element={<TicketRepairRequestForm />} />
                <Route path={CustomPath.ticket_repair_search} element={<TicketRepairSearch />} />
            </Routes>
        );
    }
}
export default compose(withRouter)(Router)
