import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Badge, Col, Flex, Image, message, Modal, Row, Select, Skeleton, Tag, Upload } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import IssuePendingAnimation from '../../animate-lottie/lottie-issue-pending.json';
import AyaBank from '../../asset/aya_bank.png';
import CBBank from '../../asset/cb_bank.jpg';
import KBZBank from '../../asset/kbz_bank.jpg';
import KBZPay from '../../asset/kbz_pay.png';
import NoInvoiceFound from '../../asset/ninja-invoice-not-found.png';
import QuickPay1 from '../../asset/quick_pay_guide_one.png';
import QuickPay3 from '../../asset/quick_pay_guide_three.png';
import QuickPay2 from '../../asset/quick_pay_guide_two.png';
import YomaBank from '../../asset/yoma_bank.jpg';
import { NJVButton, NJVCard, NJVDatePicker, NJVInput, NJVSelect, NJVTable, NJVTabs } from "../../components/core-component";
import { SvgExcelOutlined, SvgPdfOutlined, SvgPngOutlined, SvgSupportOutlined } from "../../components/custom-svgIcon";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, InvoiceDownloadByFormat } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import { subCategory } from '../shipper-order/parcel-detail';
import PaymentInfo from "./payment-info";

const { Option } = Select;

const bankLinksData = [
    {
        id: 0,
        name: 'YOMA Bank',
        photo: YomaBank,
        account: {
            acc1: '007 1111 4300 3305 (Flexi Everyday Account)',
        },
        isSelected: false
    },
    {
        id: 1,
        name: 'AYA Bank',
        photo: AyaBank,
        account: {
            acc1: '100 0404 1670 (Normal Account)',
            acc2: '100 0418 9607 (Special Account)',
        },
        isSelected: false
    },
    {
        id: 2,
        name: 'KBZ Bank',
        photo: KBZBank,
        account: {
            acc1: '06010 3060 0563 2301 (Normal Account)',
            acc2: '06013 9060 0623 6001 (Special Account)',
        },
        isSelected: false
    },
    {
        id: 3,
        name: 'CB Bank',
        photo: CBBank,
        account: {
            acc1: '0183 1009 0000 7846',
        },
        isSelected: false
    },
    {
        id: 4,
        name: 'KBZPay',
        photo: KBZPay,
        quickPay: true,
        account: {
            acc1: QuickPay1,
            acc2: QuickPay2,
            acc3: QuickPay3,
        },
        isSelected: false
    },
];

class InvoicePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalPagination: 0,
            page: 1,
            pageSize: 20,
            last_invoice: null,
            filterData: {},
            isDataFetching: false,
            ispaymentFetching: false,
            isSendingScreenshot: false,
            isFilterAll: false,
            isFilterByDateRange: false,
            defaultFilter: 1,
            activeKey: 1,
            selectedBankData: { isSelected: false },
            isFormSubmittedSuccess: false,
            is_submit_request: false,
            form_category: null,
            form_sub_category: null,
            form_reason: null,
            showInfoModal: false,
            sub_category_collection: [],
            fileList: [],
            previewOpen: false,
            previewImage: null,
        }
    }

    componentDidMount() {
        this.fetchInvoice()
        this.fetchPayment()
    }

    fetchInvoice = async (pageNumber) => {
        const { page, pageSize } = this.state
        try {
            this.setState({
                isDataFetching: true
            })

            const requestParams = {
                "page": pageNumber ? pageNumber - 1 : page - 1,
                "size": pageSize,
                "startDate": "",
                "endDate": "",
            }
            const response = await ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response && response.data && response.data.items) {
                this.setState({
                    data: response.data.items,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                    last_invoice: response.data.items[0],
                    isDataFetching: false,
                    page: pageNumber ? pageNumber : 1,
                    totalPagination: response.data.totalRecords
                })
            } else if (response && !response.success) {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            }

        } catch (error) {
            this.setState({
                isDataFetching: false,
                isFilterAll: true,
                isFilterByDateRange: false,
            })
        }
    }

    fetchPayment = async () => {
        this.setState({
            ispaymentFetching: true
        })
        try {
            const response = await ApiHandler({ url: Api.shipper_settlement_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    paymentInfo: response,
                    isPending: response.status === "PENDING" ? true : false,
                    ispaymentFetching: false
                })
            }
        } catch (error) {
        }
    }

    downloadInvoice = async (type, invoice) => {
        try {
            const param = {
                format: type
            }
            const requestBody = {
                "guid": invoice.guid
            }
            // let contentType = 'application/pdf'
            await InvoiceDownloadByFormat(Api.invoice_download, requestBody, param, invoice.merchantName, this.props)

        } catch (error) {
        }
    }

    filterBySpecific = (value) => {
        this.setState({
            defaultFilter: value
        })
        if (value === 1) {
            this.fetchInvoice()
        } else if (value === 2) {
            const endDate = dayjs();
            const startDate = endDate.subtract(7, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        } else if (value === 3) {
            const endDate = dayjs();
            const startDate = endDate.subtract(30, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        }
    }

    filterByDateRage = async (startDate, endDate, pageNumber) => {
        const { page, pageSize } = this.state

        try {
            this.setState({
                isDataFetching: true,
                // filterData: { 'startDate': startDate, 'endDate': endDate },
            })
            const requestParams = {
                "page": pageNumber ? pageNumber - 1 : page - 1,
                "size": pageSize,
                "startDate": startDate || '',
                "endDate": endDate || '',
            }
            const response = await ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response && response.data && response.data.items) {
                this.setState({
                    data: response.data.items,
                    last_invoice: response.data.items[0],
                    isDataFetching: false,
                    isFilterAll: false,
                    page: pageNumber ? pageNumber : 1,
                    isFilterByDateRange: true,
                    totalPagination: response.data.totalRecords,
                })
            } else if (response && !response.success) {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            }
        } catch (error) {
            this.setState({
                isDataFetching: false,
                isFilterAll: false,
                isFilterByDateRange: true,
            })
        }
    }

    search = () => {
        const { filterData } = this.state

        if (filterData) {

            if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                message.destroy()
                message.error("Invalid date")
                return
            }
            if (filterData.fromDate > filterData.toDate) {
                message.destroy()
                message.error("Invalid date")
                return
            }
            const startDate = filterData?.fromDate?.format("YYYY-MM-DD")
            const endDate = filterData?.toDate?.format("YYYY-MM-DD")
            this.setState({
                defaultFilter: null
            })
            this.filterByDateRage(startDate, endDate)
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        const { isFilterAll, isFilterByDateRange, filterData } = this.state
        if (isFilterAll) {
            this.fetchInvoice(pageNumber);
        } else if (isFilterByDateRange && filterData && filterData.fromDate && filterData.toDate) {
            this.filterByDateRage(filterData.fromDate, filterData.toDate, pageNumber)
        }
    };

    onchangeCategory = (value) => {
        this.setState({
            form_sub_category: null,
            form_category: value,
            sub_category_collection: subCategory[value]
        })
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {

        if (!file.thumbUrl && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.thumbUrl || file.preview,
            previewOpen: true,
        });

    };

    handleChange = (key, { fileList }) => {
        if (key === 'support') {
            this.setState({
                fileList: fileList
            })
        }
    }

    submitRequestSupport = async () => {
        const { form_category, form_reason, form_sub_category, fileList } = this.state

        if (form_category === null || form_category === '') {
            message.error("Please select category")
            return
        } else if (form_sub_category === null || form_sub_category === '') {
            message.error("Please select sub category")
            return
        } else if (form_reason === null || form_reason === '') {
            message.error("Please fill reason")
            return
        }
        if (form_sub_category === 'Bus Gate Pickup' && fileList[0] === undefined) {
            message.error("Please select photo")
            return
        }
        if (form_sub_category === 'Parcel Damage' && fileList[0] === undefined) {
            message.error("Please select photo")
            return
        }
        this.setState({ is_submit_request: true })

        let formData = new FormData();
        formData.append('category', form_category)
        formData.append('subCategory', form_sub_category)
        formData.append('reason', form_reason)
        formData.append('type', 'NORMAL_ORDER')
        if (fileList && fileList[0] && fileList[0].originFileObj) {
            formData.append('imageFile', fileList[0].originFileObj)
        }
        await ApiHandler({ url: Api.order_issue, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, disableShowMessage: true })
            .then(() => {
                this.setState({
                    isFormSubmittedSuccess: true,
                    is_submit_request: false,
                    form_category: null,
                    form_sub_category: null,
                    form_reason: null,
                    fileList: []
                })
            })
            .catch(error => { });
    }

    handleSettlementPending = (value) => {
        this.setState({
            isPending: value
        })
    }

    invoiceSection = () => {
        const { data, isDataFetching, totalPagination, page, pageSize, last_invoice, defaultFilter, showInfoModal, isFormSubmittedSuccess, form_category, form_sub_category, sub_category_collection, form_reason, fileList, previewImage, previewOpen, is_submit_request } = this.state

        const NJVLabel = ({ label }) => {
            return (
                <>
                    <span style={{ fontSize: 12, fontWeight: '600', color: '#999999' }}>{label}</span>
                    <br />
                </>)

        }

        const category = [{ label: "Request Support", value: "Request Support", key: 'request_support' }, { label: "Complaint Issue", value: "Complaint Issue", key: 'complaint_issue' }]

        const columns = [
            {
                title: <>{i18next.t("no")}</>,
                width: 30,
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
            },
            {
                title: 'Invoice Date',
                key: 'invoiceDate',
                render: (invoice) => {
                    let invoiceDate = ""
                    if (invoice && invoice.invoiceDate) {
                        const convertedInvoiceDate = dayjs(invoice.invoiceDate, 'YYYY-MM-DD');
                        if (convertedInvoiceDate) {
                            invoiceDate = convertedInvoiceDate.format('DD-MMM-YYYY')
                        }
                    }
                    return <span>{invoiceDate}{last_invoice.invoiceDate === invoice.invoiceDate ? <Tag color='#B19478' style={{marginLeft: 10}}>Latest</Tag> : ''}</span>
                }
            }, {
                title: 'PDF',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('pdf', invoice)}><SvgPdfOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }, {
                title: 'Image',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('png', invoice)}><SvgPngOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            },
            {
                title: 'Excel',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('excel', invoice)}><SvgExcelOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }
        ]

        return (
            <>
                <Modal
                    title={i18next.t("request_support")}
                    centered
                    open={showInfoModal}
                    onCancel={() => this.setState({ showInfoModal: false, form_category: null, form_sub_category: null, form_reason: null, fileList: [] })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        isFormSubmittedSuccess ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssuePendingAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_successfully_submitted")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ isFormSubmittedSuccess: false, showInfoModal: false })}>Close</NJVButton>
                            </div>
                            :
                            <>
                                <NJVLabel label={i18next.t("category")} />
                                <NJVSelect
                                    value={form_category}
                                    onChange={(value) => this.onchangeCategory(value)}
                                    size="large"
                                    allowClear={true}
                                    style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                                    className='my-location-selector'
                                    placeholder={i18next.t("select_category")}
                                >
                                    {
                                        category && category.map((cat) => {
                                            return <Option key={cat.key} value={cat.value}>{i18next.t(cat.key)}</Option>
                                        })
                                    }
                                </NJVSelect>
                                <NJVLabel label={i18next.t("sub_category")} />
                                <NJVSelect
                                    value={form_sub_category}
                                    onChange={(value) => this.setState({ form_sub_category: value })}
                                    size="large"
                                    allowClear={true}
                                    style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                                    placeholder={i18next.t("select_sub_category")}
                                >
                                    {
                                        sub_category_collection && sub_category_collection.map((subCategory) => {
                                            return <Option value={subCategory.value}>{i18next.t(subCategory.key)}</Option>
                                        })
                                    }
                                </NJVSelect>
                                <NJVLabel label={i18next.t("reason")} />
                                <NJVInput value={form_reason} bgcolor={Theme.colors.secondary} onChange={(e) => this.setState({ form_reason: e.target.value })} placeholder={i18next.t("enter_reason")} style={{ marginBottom: 20, marginTop: 5 }}
                                    showCount
                                    maxLength={200}
                                    istextareainput="true" />
                                <>
                                    <NJVLabel label={i18next.t("Photo")} />
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={(list) => this.handleChange('support', list)}
                                        beforeUpload={() => false}
                                    >
                                        {fileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}

                                    </Upload>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{
                                                display: 'none',
                                            }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                                afterOpenChange: (visible) => !visible && this.setState({ previewOpen: '' }),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </>
                                <div style={{ textAlign: 'right' }}>
                                    <NJVButton type="primary" onClick={() => this.submitRequestSupport()} style={{ backgroundColor: Theme.colors.primary }} loading={is_submit_request}>{i18next.t("request")}</NJVButton>
                                </div>
                            </>
                    }
                </Modal>
                <Row>
                    {
                        last_invoice ?
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col lg={6} md={8} sm={0} xs={0} style={{ fontWeight: 600, borderRight: '1px solid #e4e4e4' }}>Invoice No: {last_invoice.invoiceNo}</Col>
                                    <Col lg={0} md={0} sm={24} xs={24} style={{ fontWeight: 600 }}>Invoice No: {last_invoice.invoiceNo}</Col>
                                    <Col lg={6} md={8} sm={0} xs={0} style={{ borderRight: '1px solid #e4e4e4', textAlign: 'center' }}>Invoice Date : {last_invoice.invoiceDate}</Col>
                                    <Col lg={0} md={0} sm={24} xs={24}>Invoice Date : {last_invoice.invoiceDate}</Col>
                                    <Col lg={6} md={8} sm={0} xs={0} style={{ textAlign: 'center' }}>Value : <span style={{ color: 'rgba(16, 194, 0, 1)', fontWeight: '700', fontSize: 15 }}>{last_invoice.transferAmount}</span></Col>
                                    <Col lg={0} md={0} sm={24} xs={24}>Value : <span style={{ color: 'rgba(16, 194, 0, 1)', fontWeight: '700', fontSize: 15 }}>{last_invoice.transferAmount}</span></Col>
                                    <Col lg={6} md={8} sm={24} xs={24} style={{ textAlign: 'right' }}>
                                        <NJVButton type="primary" shape="square" className="icon" buttonprimary={Theme.colors.primary} icon={<SvgSupportOutlined width={18} height={18} color='#fff' />} onClick={() => {
                                            this.setState({ showInfoModal: true })
                                        }}>{i18next.t("request_support")}</NJVButton>
                                    </Col>
                                </Row>
                            </Col>
                            : <></>
                    }
                    <Col span={24} style={{ marginBottom: 20, marginTop: 20 }}>
                        <Row gutter={[16, 16]}>
                            <Col lg={6} md={6} sm={24} xs={24}>
                                <NJVSelect
                                    size="large"
                                    style={{
                                        width: '100%'
                                    }}
                                    selectborder="#E6EFF5"
                                    selectprimary="#E6EFF5"
                                    placeholder="Select"
                                    value={defaultFilter}
                                    onChange={(value) => this.filterBySpecific(value)}
                                    options={[
                                        {
                                            'key': 1,
                                            'label': <>{i18next.t('order_billing')}</>,
                                            'value': 1
                                        }, {
                                            'key': 2,
                                            'label': 'Last 7 days',
                                            'value': 2
                                        }, {
                                            'key': 3,
                                            'label': 'Last 30 days',
                                            'value': 3
                                        }
                                    ]}
                                />
                            </Col>

                            <Col lg={6} md={6} sm={24} xs={24}>
                                <NJVDatePicker
                                    datepickerborder="#E6EFF5"
                                    datepickerprimary="#E6EFF5"
                                    onChange={(value) => this.updateFilterData('fromDate', value)}
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder={i18next.t("from_date")} />
                            </Col>
                            <Col lg={6} md={6} sm={24} xs={24}>
                                <NJVDatePicker
                                    datepickerborder="#E6EFF5"
                                    datepickerprimary="#E6EFF5"
                                    onChange={(value) => this.updateFilterData('toDate', value)}
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder={i18next.t("to_date")} />
                            </Col>
                            <Col lg={6} md={6} sm={24} xs={24}>
                                <NJVButton
                                    buttonprimary={Theme.colors.primary}
                                    size="large"
                                    style={{ width: '100%' }}
                                    type="primary" onClick={() => this.search()}>
                                    {i18next.t("search")}
                                </NJVButton>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton />
                                :
                                <NJVTable
                                    headerbgcolor="#F7F7F7"
                                    headerborderradius='0px'
                                    cellinline="3px"
                                    tableborder="#EAEAEA"
                                    rowKey={(record) => record.id}
                                    style={{ borderRadius: 0 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns} dataSource={data} />
                        }
                    </Col>
                </Row>
            </>
        )
    }

    paymentSection = () => {
        const { ispaymentFetching, paymentInfo, isPending, selectedBankData } = this.state


        const imageContainer = {
            width: 120,
            height: 90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 3,
        }

        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {
                        ispaymentFetching ?
                            <Skeleton />
                            :
                            <>
                                {
                                    isPending ?
                                        <Row style={{ marginBottom: 40 }} gutter={[16, 16]}>
                                            <Col span={24}>
                                                <NJVCard cardbgcolor="#F7F7F7" style={{ marginBottom: 30 }}>
                                                    <p style={{ fontWeight: 700, textAlign: 'center' }}>{paymentInfo?.content}</p>
                                                </NJVCard>
                                            </Col>
                                            <Col lg={14} md={24} sm={24} xs={24}>
                                                <Flex wrap={true} gap={20}>
                                                    {bankLinksData.map((bankData, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Badge count={selectedBankData?.id === bankData.id && selectedBankData?.isSelected ? <CheckCircleFilled
                                                                    style={{
                                                                        color: Theme.colors.primary,
                                                                    }}
                                                                /> : 0}
                                                                >
                                                                    <div style={{
                                                                        ...imageContainer,
                                                                        border: selectedBankData?.id === bankData.id && selectedBankData?.isSelected ? '1px solid #C2002F66' : '1px solid #ccc',
                                                                    }} className="pointer" onClick={() => this.setState({ selectedBankData: { ...bankData, isSelected: true } })}>
                                                                        <Image width={80} src={bankData.photo} preview={false} style={{ cursor: 'pointer', objectFit: 'cover' }} />
                                                                    </div>
                                                                </Badge>
                                                            </div>
                                                        )
                                                    })}
                                                </Flex>
                                            </Col>
                                            {
                                                selectedBankData &&
                                                <Col lg={10} md={24} sm={24} xs={24}>
                                                    <PaymentInfo bankData={{ ...selectedBankData, settlementId: paymentInfo?.id }} handleIsPending={this.handleSettlementPending} />
                                                </Col>
                                            }
                                        </Row>
                                        :
                                        <Row>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Image src={NoInvoiceFound} preview={false} width={130} height={130} />
                                                <div style={{ fontWeight: '600', marginTop: 30 }}>
                                                    {i18next.t("msg_settlement")}
                                                </div>
                                            </Col>
                                        </Row>
                                }
                            </>
                    }
                </Col>
            </Row>
        )
    }

    render() {
        const items = [
            {
                key: '1',
                label: <span style={{ fontSize: 18 }}>{i18next.t("invoice")}</span>,
                children: this.invoiceSection(),
            },
            {
                key: '2',
                label: <span style={{ fontSize: 18 }}>{i18next.t("credit_payment")}</span>,
                children: this.paymentSection(),
            },
        ]

        return (
            <NJVTabs
                tabBarExtraContent={(this.state.activeKey === '2' || this.props.isSettlement) && <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.props.navigate(CustomPath.payment_history)}>{i18next.t("view_history")}</NJVButton>}
                defaultActiveKey={`${this.props.isSettlement ? "2" : "1"}`}
                items={items}
                style={{
                    background: '#fff', borderRadius: 12, padding: 15,
                }}
                onChange={(key) => this.setState({ activeKey: key })}
            />
        )
    }
}

export default compose(withRouter)(InvoicePage)