import { Col, Divider, Flex, Image, Pagination, Row, Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import { BackTitleCompo } from '../../components/general-component';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../routes/custom-path';
import { NJVButton, NJVCard } from '../../components/core-component';
import Theme from '../../components/theme';
import { Constant } from '../../core/constant';
import NoData from '../../asset/no-data-found.png';

export default class PointHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      historyData: [],
      isFetchingData: false,
      page: 0,
      pageSize: Constant.pageSize,
      loadMore: false,
      notFound: false
    }
  }
  componentDidMount() {
    this.fetchHistoryData();
  }

  fetchHistoryData = async (pageNumber) => {
    const { page, pageSize } = this.state;
    this.setState({
      isFetchingData: true,
    })
    const requestParams = {
      page: pageNumber ? pageNumber - 1 : page,
      size: pageSize
    }
    await ApiHandler({ url: Api.point_history, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
      .then(response => {
        this.setState({
          historyData: response,
          page: pageNumber ? pageNumber : page,
          totalElement: response.totalElements,
        })
      }).catch(err => { })
      .finally(() => {
        this.setState({
          isFetchingData: false
        })
      })
  }

  pagination = (page) => {
    this.fetchHistoryData(page)
    this.setState({
      page: page - 1
    })
  }

  // HistoryLoadMore = async () => {
  //   const { historyData, page, pageSize } = this.state;
  //   this.setState({
  //     loadMore: true
  //   })
  //   try {
  //     const requestParams = {
  //       page: page + 1,
  //       size: pageSize
  //     };

  //     const response = await ApiHandler({
  //       url: Api.point_history,
  //       method: HTTP_METHOD.GET,
  //       mediaType: MEDIA_TYPE.JSON,
  //       requestParams
  //     });

  //     const updatedData = [...historyData.content, ...response.content];

  //     this.setState(prev => ({
  //       historyData: {
  //         ...prev.historyData,
  //         content: updatedData,
  //         last: response.last
  //       },
  //       totalElements: response.totalElements,
  //       page: page + 1
  //     }))
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     this.setState({
  //       loadMore: false
  //     })
  //   }
  // }

  render() {
    const { isFetchingData, historyData, page, pageSize, totalElement } = this.state

    return (
      <Row>
        <Col lg={4} md={2} sm={1} xs={1} />
        <Col lg={16} md={20} sm={22} xs={22}>
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <BackTitleCompo label={i18next.t("point_history")} onClickRoute={CustomPath.point} />
          </Row>
          {
            isFetchingData ?
              <Skeleton />
              :
              <>
                {
                  historyData?.content && historyData?.content?.length !== 0 ?
                    <Flex gap={10} vertical>
                      {
                        historyData?.content?.map((history, index) => (
                          <Link key={index} to={`${CustomPath.loyalty_shop_detail}/${history?.loyaltyShop?.id}`} state={{ prevRoute: CustomPath.point_history }}>
                            <NJVCard>
                              <Flex gap={20} align='center' style={{ cursor: 'pointer' }}>
                                <Image src={history?.loyaltyShop?.profileUrl} style={{ objectFit: 'cover', borderRadius: 10 }} alt={history?.loyaltyShop?.fullName} width={60} height={60} preview={false} />
                                <div>
                                  <Typography.Title level={5} style={{ marginTop: 0 }}>{history?.loyaltyShop?.fullName}</Typography.Title>
                                  <Typography.Text>{dayjs(history?.loyaltyShop?.modifiedDate).format('YYYY-DD-MM HH:mm:ss A')}</Typography.Text>
                                </div>
                              </Flex>
                            </NJVCard>
                            {/* {
                                index !== historyData?.content?.length - 1 && <Divider
                                  variant="dotted"
                                  style={{
                                    borderColor: '#7cb305'
                                  }}
                                />
                              } */}
                          </Link>
                        ))
                      }
                      {/* <div style={{ textAlign: 'center' }}>
                        {
                          historyData?.last ?
                            <></>
                            :
                            <NJVButton type="primary"
                              style={{ backgroundColor: Theme.colors.primary }}
                              onClick={() => this.HistoryLoadMore()}
                              loading={loadMore}
                            >
                              Load More
                            </NJVButton>
                        }
                      </div> */}
                      <Col span={24} style={{ marginTop: 20, display: 'flex', justifyContent: 'right' }}>
                        <Pagination
                          showSizeChanger={false}
                          current={page}
                          defaultPageSize={pageSize}
                          total={totalElement}
                          onChange={this.pagination}
                        />
                      </Col>
                    </Flex >
                    :
                    <Flex justify='center' align='center' style={{ marginTop: 80 }}>
                      <Image src={NoData} alt='no data' width={120} />
                    </Flex>
                }
              </>
          }
        </Col>
      </Row >
    )
  }
}
