import L from 'leaflet';
import React, { Component } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Col, ConfigProvider, Dropdown, Input, Row, Spin, Typography } from 'antd';
import "leaflet/dist/leaflet.css";
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';

const defaultLocation = [16.8166942, 96.2072375];

export default class NearbyPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            map: null,
            data: [],
            searchValue: '',
            flyTo: null
        }
        this.mapRef = React.createRef()
    }

    componentDidMount = async () => {
        let data;

        const requestParams = {
            lat: defaultLocation[0],
            lng: defaultLocation[1],
            distance: 5000
        };

        this.setState({ loading: true })
        await ApiHandler({
            url: Api.nearby,
            method: HTTP_METHOD.GET,
            mediaType: MEDIA_TYPE.JSON,
            requestParams
        }).then((response) => {
            data = response;
            this.setState({ data })
        }).catch(() => { })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    customIcon = (type) => L.divIcon({
        className: `marker ${type === 'PARTNERSHIP_STORE' ? 'ninja-partner-icon' : type === 'PARTNERSHIP_STORE_CM' ? 'ninja-citymall-icon'  : 'ninja-icon'}`,
        iconSize: [30, 40],
        iconAnchor: [12, 41],
    });

    addMarkers = () => {
        const { data } = this.state;

        return data?.map((item, index) => (
            <Marker
                key={index}
                position={[item.latitude, item.longitude]}
                icon={this.customIcon(item.nearbyType)}
                // eventHandlers={{
                //     mouseover: (event) => event.target.openPopup(),
                // }}
            >
                <Popup>
                    <h3>{item.name}</h3><p>Phone: {item.phone}</p><p>Address: {item.address}</p>
                </Popup>
            </Marker>))
    }

    searchMarkers = (searchTerm) => {
        const map = this.mapRef.current;

        if (!searchTerm) {
            if (map) {
                map.setView(defaultLocation, 7, { animate: true })
            }
            this.setState({
                filteredData: [],
                flyTo: null
            })
            return
        }
        const filteredMarkers = this.state.data?.filter(item => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.address.toLowerCase().includes(searchTerm.toLowerCase())
        }
        );

        this.setState({
            searchValue: searchTerm,
            filteredData: filteredMarkers
        });
    }

    handleSearchItemClick = (item) => {
        const { longitude, latitude } = item;

        if (latitude && longitude) {
            this.setState({ flyTo: [latitude, longitude] });
        }

        this.setState({
            searchValue: '',
            filteredData: []
        });
    }

    renderFlyTo = () => {
        const { flyTo } = this.state;
        const map = this.mapRef.current;

        if (map && flyTo) {
            map.setView(flyTo, 15, { animate: true });
        }
    };

    render() {
        const { loading, filteredData } = this.state;

        const items = filteredData?.map((item, index) => ({
            key: index,
            label: <>
                <Typography.Text style={{ color: Theme.colors.primary }}>{item.name}</Typography.Text>
                <Typography.Paragraph style={{ marginBottom: 5 }}>{item.address}</Typography.Paragraph>
            </>,
            onClick: () => this.handleSearchItemClick(item)
        }))

        return (
            <Row>
                <Col span={24} style={{ position: 'relative' }}>
                    <div className="map-container">
                        <MapContainer
                            ref={this.mapRef}
                            center={defaultLocation}
                            style={{ height: '85vh' }}
                            zoom={7}
                            zoomControl={false}
                            whenReady={(map) => this.mapRef.current = map}>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {
                                this.addMarkers()
                            }
                            {
                                this.renderFlyTo()
                            }
                        </MapContainer>
                        {loading && <div className="loading-layer"><Spin /></div>}
                        <div style={{ position: 'absolute', top: 20, left: 10, zIndex: 999 }}>
                            <ConfigProvider theme={{
                                token: {
                                    boxShadowSecondary: 'none'
                                }
                            }}>
                                <Dropdown menu={{ items }} open={filteredData?.length > 0} disabled={filteredData?.length < 0} trigger={['click']} dropdownRender={menu => (
                                    <div style={{ maxHeight: '460px', overflowY: 'auto', width: '340px', backgroundColor: '#fff' }}>
                                        {menu}
                                    </div>
                                )}>
                                    <Input size='large' placeholder="Search" onChange={(e) => this.searchMarkers(e.target.value)} style={{ width: 200 }} />
                                </Dropdown>
                            </ConfigProvider>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
