import { Tag, Typography } from "antd";
import { SvgPackLocationFilled, SvgPickupFilled } from "./custom-svgIcon";
import { GenerateOrderStatusCode } from "../core/nvm-utils";
import { Link } from "react-router-dom";
import { MainTitle, NJVButton } from "./core-component";
import { SvgArrowLeftFilled } from "./custom-svgIcon"
import Theme from "./theme";
import {
    ArrowLeftOutlined
} from '@ant-design/icons'

const { Title } = Typography

export const FormLabel = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <span style={{ color: "red" }}>*</span> <span>{label}</span>
        </div>

    );
}
export const FormLabelOptional = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5, fontWeight: 500 }}>
            <span>{label}</span>
        </div>

    );
}
export const ErrorLabel = (props) => {
    return (
        <div style={{ marginLeft: 5 }}>
            <span style={{ color: "red" }}>( *required )</span>
        </div>

    );
}

export const TitleLevel3 = (props) => {
    const { label } = props;
    return (
        <span style={{ fontSize: 24, fontWeight: '600', color: '#000' }}>{label}</span>
    );
}
export const TitleLevel4 = (props) => {
    const { label } = props;
    return (
        <Title level={4}>{label}</Title>
    );
}

export const NJVCustomOrderStatusTag = (props) => {
    const { orderStatus, value } = props;
    let key = orderStatus
    let status = value
    const success = '#10C200'
    const cancel = '#C2002F'
    const pending = '#F7B935'
    const processing = '#2E6FE8'
    const pickup = '#B19478'
    let colorCode = pending


    if (key === 'ARRIVED_AT_ORIGIN_HUB' || key === 'ARRIVED_AT_SORTING_HUB' || key === 'ARRIVED_AT_TRANSIT_HUB' ||
        key === 'ARRIVED_AT_DESTINATION_HUB' || key === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' || key === 'AT_PUDO') {
        key = 'PROCESSING'
        status = 'Processing'
    }
    if (key === 'PROCESSING') {
        colorCode = processing
    } else if (key === 'DELIVERED') {
        colorCode = success
    } else if (key === 'CANCELLED') {
        colorCode = cancel
    } else if (key === 'PENDING_PICK_UP') {
        colorCode = pending
    } else if (key === 'PICKED_UP') {
        colorCode = pickup
    } else if (key === 'DELIVERY_EXCEPTION') {
        colorCode = cancel
    }
    return <Tag style={{ marginRight: 0 }} color={colorCode}>{GenerateOrderStatusCode(key, status)}</Tag>

}

export const NJVDeliveryStep = (props) => {
    let { from, to, status } = props;
    const processing = '#2E6FE8'
    const success = '#10C200'
    const cancel = '#C2002F'
    const pending = '#F7B935'
    const pickup = '#B19478'

    if (status === 'ARRIVED_AT_ORIGIN_HUB' || status === 'ARRIVED_AT_SORTING_HUB' || status === 'ARRIVED_AT_TRANSIT_HUB' ||
        status === 'ARRIVED_AT_DESTINATION_HUB' || status === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' || status === 'AT_PUDO') {
        status = 'PROCESSING'
    }

    let iconColor = '#A6A6A6'
    if (status === 'CANCELLED') {
        iconColor = cancel
    } else if (status === 'PENDING_PICK_UP') {
        iconColor = pending
    } else if (status === 'DELIVERED') {
        iconColor = success
    } else if (status === 'PROCESSING') {
        iconColor = processing
    } else if (status === 'PICKED_UP') {
        iconColor = pickup
    } else if (status === 'DELIVERY_EXCEPTION') {
        iconColor = cancel
    }
    return (
        <>
            <div style={{ display: 'flex', marginTop: 8 }}>
                <div style={{ width: 20, display: 'flex', justifyContent: 'center' }}>
                    <SvgPickupFilled width={18} height={18} color={iconColor} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: '600', marginLeft: 10, color: iconColor }}>{from}</div>
            </div>
            <div style={{ display: 'flex', margin: '0px 0px 3px 0px' }}>
                <div style={{ width: 20, display: 'flex', justifyContent: 'center' }}>
                    <div style={{
                        borderLeft: `3px dotted ${iconColor}`,
                        height: 15,
                        margin: ' 0 auto'
                    }}></div>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: 20, display: 'flex', justifyContent: 'center' }}>
                    <SvgPackLocationFilled width={20} height={20} color={iconColor} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: '600', marginLeft: 10, color: iconColor }}>{to}</div>
            </div>
        </>
    );
}


export const BackTitleCompo = (props) => {
    const { label, onClickRoute, stateName, stateData } = props;

    return (
        <Link to={onClickRoute} style={{ display: 'flex' }} state={{ [stateName]: stateData }}>
            <NJVButton type="text" className="icon" shape="circle" icon={<SvgArrowLeftFilled width={19} height={19} />} style={{ marginRight: 10 }} />
            <MainTitle label={label} />
        </Link>
    )
}