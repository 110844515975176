import { compose } from "@reduxjs/toolkit";
import { Result } from "antd";
import React from "react";
import { NJVButton } from "../../components/core-component";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class NotFound extends React.Component {

    render() {
        const { navigate } = this.props
        return (
            // <Layout>
            //     <div
            //         style={{
            //             height: "70px",
            //             display: 'flex',
            //             alignItems: 'center',
            //             position: 'fixed',
            //             left: 0,
            //             right: 0,
            //             top: 0,
            //             paddingLeft: 0,
            //             paddingRight: 0,
            //             background: '#ffffff',
            //             zIndex: 2,
            //             boxShadow: '2px 2px 150px rgba(0, 0, 0, 0.1)',
            //         }}
            //     >
            //         <Row style={{ width: '100%' }}>
            //             <Col span={12} style={{ paddingLeft: 10 }}>
            //                 <Image
            //                     width={200}
            //                     src={NJVPhotoURL.logo_url}
            //                     preview={false}
            //                 />

            //             </Col>
            //             <Col span={12} style={{ display: 'flex', justifyContent: 'end', paddingRight: 10, alignItems: 'center' }}>
            //                 {
            //                     this.props.authenticated ?
            //                         <Button type="primary" onClick={() => navigate(CustomPath.dashboard)}>Go To dashboard</Button>
            //                         :
            //                         <Button type="primary" onClick={() => navigate(CustomPath.login)}>Login</Button>
            //                 }

            //             </Col>
            //         </Row>
            //     </div>
            //     <Layout style={{ backgroundColor: 'white' }}>

            //         <Content>

            //             <Lottie
            //                 style={{ height: '700px' }}
            //                 animationData={NotFoundAnimation}
            //                 loop={true}
            //                 autoplay={true}
            //             />
            //         </Content>
            //     </Layout>
            // </Layout>


            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<NJVButton type="primary" onClick={() => navigate(CustomPath.dashboard)}>Back Home</NJVButton>}
            />
        )
    }
}

export default compose(withRouter)(NotFound);
