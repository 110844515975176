import { compose } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { NJVTabs } from '../../components/core-component'
import withRouter from '../../network/with-router'
import { updateStatusKey } from '../../redux/profile-slice'
import VoucherListPage from './voucher-list-page'

class QuickVoucher extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount = () => {
    this.props.dispatch(updateStatusKey({ isReady: false }))
  }

  render() {
    const items = [
      {
        key: '1',
        label: <span style={{ fontSize: 18 }}>Pending</span>,
        children: <VoucherListPage status='isPending' />,
      },
      {
        key: '2',
        label: <span style={{ fontSize: 18 }}>Ready</span>,
        children: <VoucherListPage status="isReady" />,
      },
      {
        key: '3',
        label: <span style={{ fontSize: 18 }}>Confirm</span>,
        children: <VoucherListPage status="isConfirm" />,
      },
    ]

    return (
      <NJVTabs defaultActiveKey={`${this.props?.statusKey?.isPending ? '1' : this.props.statusKey?.isReady ? '2' : this.props.statusKey?.isConfirm ? '3' : '1'}`} items={items} />
    )
  }
}

export default compose(withRouter)(QuickVoucher)