import {
  LoadingOutlined,
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Checkbox, Col, Form, Modal, Row, Select, Spin, Tag } from 'antd';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import React, { Component } from 'react';
import SuccessAnimation from '../../animate-lottie/lottie-success.json';
import { NJVButton, NJVCard, NJVInput, NJVSelect } from '../../components/core-component';
import { FormLabel, TitleLevel3 } from '../../components/general-component';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from '../../network/with-router';
const { Option } = Select;

class PickupCall extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userAddress_collection: [],
      defaultLocation: {},
      isDataSending: false,
      isFetchingMyLocation: false,
      isFetchingSameDay: false,
      isSameDayAvailable: false,
      openConfirmModal: false,
      sameDay: false,
      userInfo: {},
    }
    this.pickupRef = React.createRef()
  }

  componentDidMount() {
    this.fetchMyLocation();
  }

  fetchMyLocation = async () => {
    this.setState({
      isFetchingMyLocation: true
    })
    try {
      const requestParams = { isMyLocation: true }
      const response = await ApiHandler({url: Api.user_address_available, method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
      if (response) {
        let location = {}
        let foundDefaultLocation = false
        let userAddress = {}
        response.forEach((address) => {
          if (address.defaultLocation) {
            location = address.id
            foundDefaultLocation = true
            userAddress = address
          }
        })
        if (!foundDefaultLocation && response.length !== 0) {
          location = response[0].id
          userAddress = response[0]
        }
        this.setState({
          userAddress_collection: response,
          defaultLocation: location,
          userInfo: userAddress,
          isFetchingMyLocation: false
        }, () => {
          this.checkSameDay(userAddress?.township?.id)
        })
      }
    } catch (error) {
    }
  }

  handlePickupLocation = async (value) => {
    const { userAddress_collection } = this.state
    let userAddress = {}
    userAddress_collection.forEach(ad => {
      if (ad.id === value) {
        userAddress = ad
      }
    })
    this.setState({
      defaultLocation: value,
      userInfo: userAddress,
    }, () => this.checkSameDay(userAddress?.township?.id));
  }


  checkSameDay = async (townshipId) => {
    try {
      if (townshipId) {
        const requestParams = {
          'townshipId': townshipId
        }
        const response = await ApiHandler({url: Api.pickup_call_check_same_day, method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON,requestParams})
        this.setState({
          isSameDayAvailable: response
        })
      }

    } catch (error) {
    }
    this.setState({
      isFetchingSameDay: false
    })
  }

  createPickupCall = async (value) => {
    const { userInfo, sameDay, isSameDayAvailable } = this.state
    this.setState({
      isDataSending: true
    })

    const data = {
      "businessName": this.props.businessName,
      "address": userInfo.address1,
      "parcelCount": value?.parcelAmount,
      "township": userInfo?.township?.name,
      "division": userInfo?.township?.division?.name,
      "sameDay": sameDay,
      "note": value.notes ? value.notes : ""
    }
    let requestData = null;
    if (isSameDayAvailable) {
      requestData = { ...data }
    } else {
      requestData = { ...data, "sameDay": false }
    }

    try {
      // const response = await ApiHandler(Api.pickup_call, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestBody, null, null, null, true);
      const response = await ApiHandler({url: Api.pickup_call,method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.JSON, requestData, disableShowMessage: true});
      if (response && response.status === 200) {
        this.setState({
          openConfirmModal: true
        }, () => this.clearForm())
      }
    } catch (error) {
    }
    this.setState({
      isDataSending: false
    })
  }

  clearForm = () => {
    this.pickupRef.current.setFieldsValue({ parcelAmount: null, notes: null })
  }

  render() {
    const { userAddress_collection, defaultLocation, isFetchingSameDay, isFetchingMyLocation, isSameDayAvailable, sameDay, openConfirmModal, isDataSending } = this.state;

    return (
      <>
        <Modal
          title={null}
          centered
          open={openConfirmModal}
          onOk={() => this.setState({ openConfirmModal: false })}
          footer={null}
          onCancel={() => this.setState({ openConfirmModal: false })}
          className='custom-modal'
        >
          <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '100%', textAlign: 'center', marginBottom: 20 }}>
              <Lottie
                style={{ height: '200px' }}
                animationData={SuccessAnimation}
                loop={false}
                autoplay={true}
              />
              <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_success_pickup")}</div>
            </div>
          </div>
        </Modal >
        <Row>
          <Col lg={6} md={6} sm={1} xs={1} />
          <Col lg={12} md={12} sm={22} xs={22}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TitleLevel3 label={i18next.t("pickup_call")} />
              </Col>
              <Col span={24}>
                <NJVCard style={{ paddingTop: 15 }}>
                  <Form
                    ref={this.pickupRef}
                    layout='vertical' autoComplete='off' onFinish={this.createPickupCall} >
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <FormLabel label={i18next.t("pickup_address")} />
                        <NJVSelect
                          className='my-location-selector'
                          value={defaultLocation}
                          placeholder={i18next.t("pickup_address")}
                          onChange={(value) => this.handlePickupLocation(value)}
                          size='large'
                          style={{ width: '100%' }}
                          loading={isFetchingMyLocation}
                        >
                          {
                            userAddress_collection && userAddress_collection?.map((userAddress, index) => {
                              return <Option key={index} value={userAddress.id}>{userAddress.name}{userAddress.defaultLocation ? <Tag style={{ borderRadius: 20, marginLeft: 10 }} color='#8f8f8f'>Default</Tag> : <></>}</Option>
                            })
                          }
                        </NJVSelect>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="parcelAmount" label={`${i18next.t("parcel_quantity")} (${i18next.t("msg_parcel_quantity")})`} rules={[
                          {
                            required: true,
                            message: i18next.t("err_msg_pickup_call"),
                          },
                        ]}
                          style={{ marginBottom: 0 }}
                        >
                          <NJVInput size="large" min={1} style={{ width: '100%' }} isNumberInput={true} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="notes" label={i18next.t("note")}>
                          <NJVInput
                            maxLength={100}
                            style={{
                              height: 100,
                            }}
                            istextareainput="true"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                          <Checkbox
                            value={sameDay}
                            onChange={(e) => this.setState({ sameDay: e.target.checked })}
                            disabled={!isSameDayAvailable}
                          >
                            {i18next.t("same_day")}
                          </Checkbox>
                          {
                            isFetchingSameDay ?
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{
                                      fontSize: 24,
                                    }}
                                    spin
                                  />
                                }
                              />
                              :
                              <></>
                          }
                        </div>
                      </Col>
                      <Col span={24}>
                        <Form.Item>
                          <NJVButton size='large' htmlType='submit' loading={isDataSending} style={{ width: '100%', backgroundColor: Theme.colors.primary, color: '#fff' }}>{i18next.t("create_pickup_call")}
                          </NJVButton>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </NJVCard>
              </Col>
            </Row>
          </Col>
          <Col span={6} />
        </Row>
      </>
    )
  }
}

export default compose(withRouter)(PickupCall)