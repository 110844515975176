import { PlusOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Col, Flex, Form, Image, Row, Upload, Typography, Button, message, Alert } from "antd";
import React from "react";
import { NJVBackButtonWithIcon, NJVButton, NJVCard, NJVInput, NJVSelect } from "../../components/core-component";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandlerNoAuth } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import { PhoneNumberValidator, RestrictPhoneNumberValidator } from "../../core/validator";
import CustomPath from "../../routes/custom-path";
import { Link } from "react-router-dom";
import { TitleLevel3 } from "../../components/general-component";

const working = require("../../asset/success.gif");
const UrgencyItem = [
    {
        value: "Low",
        label: "Low",
    },
    {
        value: "Medium",
        label: "Medium",
    },
    {
        value: "High",
        label: "High",
    }
]

const DeviceTypeItem = [
    {
        value: "Laptop",
        label: "Laptop"
    },
    {
        value: "Monitor(Display)",
        label: "Monitor(Display)"
    },
    {
        value: "Desktop(System Unit)",
        label: "Desktop(System Unit)"
    },
    {
        value: "Printer",
        label: "Printer"
    }
]

const DepartmentItem = [
    {
        "value": "Management Office",
        "label": "Management Office"
    },
    {
        "value": "Admin",
        "label": "Admin"
    },
    {
        "value": "YGN Operation",
        "label": "YGN Operation"
    },
    {
        "value": "Fleet",
        "label": "Fleet"
    },
    {
        "value": "Recovery",
        "label": "Recovery"
    },
    {
        "value": "Account & Finance",
        "label": "Account & Finance"
    },
    {
        "value": "Sale & Marketing",
        "label": "Sale & Marketing"
    },
    {
        "value": "CX",
        "label": "CX"
    },
    {
        "value": "HR",
        "label": "HR"
    },
    {
        "value": "Organization Development",
        "label": "Organization Development"
    },
    {
        "value": "Intercity",
        "label": "Intercity"
    },
    {
        "value": "IT",
        "label": "IT"
    },
    {
        "value": "Warehouse",
        "label": "Warehouse"
    },
    {
        "value": "Station",
        "label": "Station"
    }
]
const logo = require('../../asset/Logo.png');
class TicketRepairRequestForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requesting: false,
            isFormSubmited: false,
            ticketId: null,
            fileList1: [],
            fileList2: [],
            fileList3: []
        }
        this.formRef = React.createRef();
    }

    requestFom = async (object) => {
        this.setState({ requesting: true })
        const imageFileSize = [
            object.image1?.[0]?.size || 0,
            object.image2?.[0]?.size || 0,
            object.image3?.[0]?.size || 0,
        ]

        const totalImageSize = imageFileSize.reduce((sum, imageFileSize) => sum + imageFileSize, 0);
        if (!this.checkValidFileSize(totalImageSize)) {
            return;
        }

        this.setState({ requesting: true })
        let formData = new FormData()
        formData.append('requestorName', object.requestorName)
        formData.append('employeeId', object.employeeId)
        formData.append('departmentName', object.departmentName)
        formData.append('contactInfo', object.contactInfo)
        formData.append('emailAddress', object.emailAddress)
        formData.append('deviceType', object.deviceType)
        formData.append('assetId', object.assetId)
        formData.append('issueDescription', object.issueDescription)
        formData.append('urgencyLevel', object.urgencyLevel)
        formData.append('address', object.address)

        if (object?.image1?.length > 0) {
            formData.append('image1', object.image1[0]?.originFileObj)
        }
        if (object?.image2?.length > 0) {
            formData.append('image2', object.image2[0]?.originFileObj)
        }
        if (object?.image3?.length > 0) {
            formData.append('image3', object.image3[0]?.originFileObj)
        }
        await ApiHandlerNoAuth({ url: Api.ticket_repair_request, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, disableShowMessage: true })
            .then((response) => {
                this.setState({ ticketId: response?.data?.ticketId, isFormSubmited: true })
            })
            .catch((error) => {
            })
            .finally(() => {
                this.setState({ requesting: false })
            })
    }

    checkValidFileSize = (size) => {
        const isLt5M = size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.destroy()
            message.error('Images must smaller than 5MB!');
        }
        return isLt5M;
    };


    handleChange1 = ({ fileList: newFileList }) => {

        let updateFileList = []
        newFileList && newFileList.map(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList1: updateFileList
        })
        this.formRef.current?.setFieldsValue({ 'image1': updateFileList })
    }

    handleChange2 = ({ fileList: newFileList }) => {
        let updateFileList = []
        newFileList && newFileList.map(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList2: updateFileList
        })
        this.formRef.current?.setFieldsValue({ 'image2': updateFileList })

    }

    handleChange3 = ({ fileList: newFileList }) => {
        let updateFileList = []
        newFileList && newFileList.map(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList3: updateFileList
        })
        this.formRef.current?.setFieldsValue({ 'image3': updateFileList })

    }
    render() {
        const { requesting, fileList1, fileList2, fileList3, isFormSubmited, ticketId } = this.state
        return (
            <Row>
                <Col lg={7} md={6} sm={1} xs={1} />
                <Col lg={10} md={12} sm={22} xs={22}>
                    <Row>
                        <Col span={24} style={{ marginTop: 10 }}>
                            <Image width={200} height={67} src={logo} preview={false} style={{ padding: '5px 0' }} />
                        </Col>
                        <Col span={24} style={{ marginTop: 20, marginBottom: 20, display: 'flex', alignItems: 'center' }}>
                            <Link to={CustomPath.ticket_repair_request}>
                                <NJVBackButtonWithIcon />
                            </Link>
                            <TitleLevel3 label="Repair Ticket တင်ရန်" />
                        </Col>
                        {
                            !isFormSubmited ?
                                <>
                                    <Col span={24}>
                                        <NJVCard cardBgColor={Theme.colors.default_bgcolor} style={{ marginBottom: 40 }}>
                                            <Form
                                                ref={this.formRef}
                                                layout="vertical"
                                                onFinish={this.requestFom}>
                                                <Form.Item name="requestorName" label="၀န်ထမ်းအမည်" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    }
                                                ]}>
                                                    <NJVInput placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Form.Item name="employeeId" label="၀န်ထမ်းနံပါတ် (NVMxxxx)" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    },
                                                ]}>
                                                    <NJVInput placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Form.Item name="departmentName" label="တာ၀န်ထမ်းဆောင်ရာဌာန" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    }
                                                ]}>
                                                    <NJVSelect options={DepartmentItem} placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Form.Item name="address" label="တာ၀န်ထမ်းဆောင်ရာလိပ်စာ" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    }
                                                ]}>
                                                    <NJVInput placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Form.Item name="contactInfo" label="ဆက်သွယ်ရန်ဖုန်းနံပါတ် (09xxxxxxxxxxx)" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    },
                                                    {
                                                        validator: RestrictPhoneNumberValidator
                                                    }
                                                ]}>
                                                    <NJVInput placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Form.Item name="emailAddress" label="အီးမေးလ်လိပ်စာ" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: 'Invalid email address',
                                                    }
                                                ]}>
                                                    <NJVInput placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <span style={{ color: 'red' }}>Ticket IDပို့မည်ဖြစ်ပါသဖြင့် လက်ရှိအသုံးပြုနေသော အီးမေးလ်လိပ်စာကို မှန်ကန်စွာထည့်သွင်းပါ။</span>

                                                <Form.Item name="deviceType" label="ပစ္စည်းအမျိုးအစားရွေးချယ်ပါ" style={{ marginTop: 40 }} rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    }
                                                ]}>
                                                    <NJVSelect options={DeviceTypeItem} placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Form.Item name="assetId" label="Asset Codeနံပါတ်" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    }
                                                ]}>
                                                    <NJVInput placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Form.Item name="issueDescription" label="Issueအကြောင်းအရာရေးပါ" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    }
                                                ]}>
                                                    <NJVInput istextareainput={true} maxLength={200} showCount={true} placeholder="" style={{ height: 80 }} />
                                                </Form.Item>
                                                <Form.Item name="urgencyLevel" label="အရေးကြီးမှုအဆင့်သတ်မှတ်ပါ" rules={[
                                                    {
                                                        required: true,
                                                        message: "(*required)"
                                                    }
                                                ]}>
                                                    <NJVSelect options={UrgencyItem} placeholder="" style={{ height: 40 }} />
                                                </Form.Item>
                                                <Flex justify="start">
                                                    <Form.Item name="image1" label={"Photo 1"} rules={[
                                                        {
                                                            required: true,
                                                            message: "(*required)"
                                                        }
                                                    ]}>
                                                        <Upload
                                                            accept={["image/png", "image/jpg", "image/jpeg"]}
                                                            listType="picture-card"
                                                            fileList={fileList1}
                                                            action={null}
                                                            showUploadList={{ showPreviewIcon: false }}
                                                            onChange={this.handleChange1}
                                                        >
                                                            {fileList1.length >= 1 ? null :
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div
                                                                        style={{
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        Upload
                                                                    </div>
                                                                </div>}
                                                        </Upload>
                                                    </Form.Item>
                                                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                        <Form.Item name="image2" label={"Photo 2"}>
                                                            <Upload

                                                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                                                listType="picture-card"
                                                                fileList={fileList2}
                                                                action={null}
                                                                showUploadList={{ showPreviewIcon: false }}
                                                                onChange={this.handleChange2}
                                                            >
                                                                {fileList2.length >= 1 ? null :
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>}
                                                            </Upload>
                                                        </Form.Item>
                                                    </div>

                                                    <Form.Item name="image3" label={"Photo 3"}>
                                                        <Upload
                                                            style={{ marginLeft: 10 }}
                                                            accept={["image/png", "image/jpg", "image/jpeg"]}
                                                            listType="picture-card"
                                                            fileList={fileList3}
                                                            action={null}
                                                            showUploadList={{ showPreviewIcon: false }}
                                                            onChange={this.handleChange3}
                                                        >
                                                            {fileList3.length >= 1 ? null :
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div
                                                                        style={{
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        Upload
                                                                    </div>
                                                                </div>}
                                                        </Upload>
                                                    </Form.Item>
                                                </Flex>

                                                <Form.Item>
                                                    <NJVButton primarycolor={Theme.colors.primary} type="primary" htmlType="submit" loading={requesting}>ပေးပို့မည်</NJVButton>
                                                </Form.Item>
                                            </Form>
                                        </NJVCard>
                                    </Col>
                                </>
                                :
                                <>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Image
                                            style={{ marginTop: 100 }}
                                            width={150}
                                            src={working}
                                            preview={false}
                                        />
                                        <br />
                                        <Typography.Title
                                            level={5}>
                                            သင်၏ Repair Request အား IT ဌာနသို့ ပေးပို့ပြီးပါပြီ။ <br />
                                            Repair Request ID မှာ  {ticketId} ဖြစ်ပြီး ပြုပြင်ခြင်းအခြေအနေများကို ထိုIDဖြင့် ခြေရာခံနိုင်ပါသည်။<br />
                                            သာယာပျော်ရွှင်သောနေ့ရက်ကလေးပိုင်ဆိုင်နိုင်ပါစေ။
                                        </Typography.Title>
                                        <Button style={{ backgroundColor: Theme.colors.primary }} type="primary" shape="round" onClick={() => {
                                            navigator.clipboard.writeText(ticketId).catch(err => { });
                                        }}>Copy Ticket ID</Button>

                                    </Col>
                                    <Col span={24} style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                        <Link to={CustomPath.ticket_repair_request}>
                                            <NJVBackButtonWithIcon />
                                        </Link>
                                    </Col>
                                </>
                        }

                    </Row>
                </Col >
            </Row >
        )
    }
}

export default compose(withRouter)(TicketRepairRequestForm);