import { compose } from "@reduxjs/toolkit";
import { Card, Col, Row, Table } from "antd";
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import { MainTitle, NJVEmptyData } from "../../components/core-component";

class OrderCreateFailLog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: []

        }
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const response = await ApiHandler({ url: Api.redis_order_create_fail_log, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            this.setState({
                data: response
            })
        } catch (error) {

        }
    }
    render() {
        const { data } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 5
            },
            {
                title: 'Receiver',
                dataIndex: 'receiverName',
                key: 'receiverName',
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
            },
            {
                title: 'Order Type',
                key: 'orderType',
                dataIndex: 'orderType'
            },
            {
                title: 'COD',
                key: 'codAmount',
                render: (orderHistory) => (
                    <div>{orderHistory.codAmount ? orderHistory.codAmount : 0}</div>
                ),

            },
            {
                title: 'Parcel Amount',
                dataIndex: 'parcelAmount',
                key: 'parcelAmount'
            }
        ]
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <MainTitle label={i18next.t("order_create_fail_logs")} />
                </Col>
                <Col span={24}>
                    {
                        data && data?.length !== 0 ?
                            <Table
                                rowKey={(record) => record.id}
                                style={{ borderRadius: 10 }}
                                pagination={false}
                                columns={columns}
                                dataSource={data}
                                rowClassName={getRowClassName}
                            />
                            :
                            <NJVEmptyData />

                    }

                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(OrderCreateFailLog)