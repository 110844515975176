import {
    DeleteOutlined,
    DownloadOutlined,
    MenuOutlined,
    PrinterOutlined,
    QuestionOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Checkbox, Col, ConfigProvider, Divider, Dropdown, Flex, Modal, Row, Skeleton, Space, Spin, Typography, Upload, message } from "antd";
import dayjs from 'dayjs';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import animationData from '../../animate-lottie/animation_lnfk8gz0.json';
import FailAnimation from '../../animate-lottie/lottie-404.json';
import SuccessAnimation from '../../animate-lottie/lottie-success.json';
import NoParcelCreate from '../../animate-lottie/package-cancel.json';
import { MainTitle, ModalTitle, NJVButton, NJVCard, NJVDatePicker, NJVInput, NJVSelect, NJVTable } from '../../components/core-component';
import { SvgEmptyParcel, SvgFileUpload } from '../../components/custom-svgIcon';
import Theme, { Colors } from '../../components/theme';
import '../../css/order-create.css';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, DownloadOrderFormatExcel } from "../../network/network-manager";
import withRouter from '../../network/with-router';
import { clearOrder, updateAdvanceOrderList, updateOrderSession } from '../../redux/order-form-slice';
import CustomPath from '../../routes/custom-path';
import ReceiverForm from './parcel-receiver-form';
const { Dragger } = Upload;

const HOME_DELIVERY = 1
const BUS_GATE = 2
const NINJA_POINT = 3
const POST_OFFICE = 4

const COLLECT_FROM_CUSTOMER = 'Collect from customer'
const PAY_BY_SHIPPER = 'Pay by shipper'
const DELIVERY_FEES = 2000

const initState = 1
const confirmState = 2
const successState = 3
const failState = 4

const orderTypeMap = {
    '1': 'Home Delivery',
    '2': 'Bus Gate',
    '3': 'Ninja Point',
    '4': 'Post Office'
}

const orderTypeLocalizationMap = {
    '1': 'home_delivery',
    '2': 'bus_gate',
    '3': 'ninja_point',
    '4': 'post_office'
}

const NJVLabel = ({ label }) => {
    return (
        <>
            <div style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</div>
        </>)
}
class NewAdvanceOrderForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pickUpDate: null,
            page: 1,
            excel_page: 1,
            currentStep: 0,

            tempLocation: null,
            userAddress_collection: [],
            receiverAddress_collection: [],
            pickupInformation: {},
            busGateList: [],
            partnershipStoreList: [],
            deliveryChargesTemp: [],

            userInput_collection: [],
            orderState: initState,
            successfullOrderList: [],
            orderDetail: {},

            isUpdateData: false,
            downloadingExcel: false,
            isOrderTypeUpload: true,
            confirmToClearReceiverData: false,
            isDataFetching: false,

            openOrderDetailModal: false,
            openConfirmModal: false,
            openClearAllDataModal: false,
            openPickupModal: false,

            isBusgateServiceAvailable: false,
            isExcelFileUploading: false,
            isFetchingMyLocation: true,

            isPostOfficeAvailable: false,

            excel_order_list: [],
            failReasons: [],
            total_excel_role: 0,
            valid_excel_role: 0,
            invalid_excel_role: 0,
            fail_message_code: '076',

            isExitUserGroup: true,

            form_activity: { orderType: HOME_DELIVERY, favourite: false },
            isManualOrder: false,
            selectedOrder: 'Manual Order',
        }
    }
    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 13).set('minute', 0).set('second', 0);
        const isBefore1PM = currentDate.isBefore(targetTime);
        if (isBefore1PM) {
            return dayjs().startOf('day');
        } else {
            return dayjs().endOf('day');
        }
    }
    disabledDate = (current) => {
        return current && current < this.getValidDate();
    };
    componentDidMount() {
        try {
            this.fetchCheckUserGroup()
            this.fetchMyLocation()
            const todayDate = dayjs();
            const previousOrderList = this.props.advanceOrderDraftList

            const orderSessionTime = this.props.orderSessionTime
            if (previousOrderList && previousOrderList.length !== 0) {
                if (orderSessionTime) {
                    const totalMinuteDiff = todayDate.diff(orderSessionTime, 'minute');
                    if (totalMinuteDiff > 180) {
                        this.props.dispatch(clearOrder())
                        this.props.dispatch(updateOrderSession(todayDate))
                    } else {
                        this.setState({
                            userInput_collection: previousOrderList
                        })
                    }
                }
            } else {
                this.props.dispatch(updateOrderSession(todayDate))
            }
        } catch (error) {
        }
    }

    fetchCheckUserGroup = async () => {
        this.setState({
            isFetchingUserGroup: true
        })
        await ApiHandler({ url: Api.check_user_group, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON }).then(response => {
            this.setState({
                isExitUserGroup: response?.is_user_group_exist,
                isFetchingUserGroup: false
            })
        }).catch(error => {
            this.setState({
                isFetchingUserGroup: false
            })
        })
    }

    fetchMyLocation = async () => {
        try {
            const requestParams = { isMyLocation: true }

            const response = await ApiHandler({ url: Api.user_address_available, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })

            if (response) {
                let foundDefaultLocation = false
                let userAddress = {}
                let defaultLocationAddress = {}

                response?.map((address) => {
                    if (this.props.pickupAddress) {
                        if (address.township.id === this.props.pickupAddress?.townshipId) {
                            this.setState({
                                openPickupModal: false
                            })
                        }
                    } else {
                        if (address.defaultLocation) {
                            foundDefaultLocation = true;
                            defaultLocationAddress = address
                            userAddress = address;
                        }
                    }
                });
                if (!foundDefaultLocation && this.props.pickupAddress) {
                    userAddress = this.props.pickupAddress;
                } else if (!foundDefaultLocation && response.length !== 0) {
                    userAddress = response[0];
                }

                const pickupAddressInList = response.some(address => address.township.id === this.props.pickupAddress?.townshipId);

                if (pickupAddressInList) {
                    userAddress = this.props.pickupAddress;
                } else {
                    if (foundDefaultLocation) {
                        userAddress = defaultLocationAddress;
                    } else {
                        this.setState({
                            openPickupModal: true
                        })
                    }
                }
                if (userAddress?.township?.id) {
                    this.checkBusGateAvailableService(userAddress.township.id, userAddress)
                }
                this.setState({
                    userAddress_collection: response,
                    pickupInformation: { ...userAddress, 'townshipId': userAddress?.township?.id },
                })
            }
        } catch (error) {
        }
        this.setState({ isFetchingMyLocation: false })
    }

    checkBusGateAvailableService = async (id, userAddress) => {
        const { pickupInformation } = this.state

        let isPostOfficeAvailable = false
        if (id === null || id === undefined) {
            return
        }
        await ApiHandler({ url: Api.check_bus_gate_service_available, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: { 'id': id } })
            .then((response) => {
                if (response) {
                    if ((userAddress?.township?.division?.shortCode === 'YGN' || userAddress?.township?.division?.shortCode === 'MDY')) {
                        isPostOfficeAvailable = true
                    } else if ((pickupInformation?.divisionShortCode === 'YGN' || pickupInformation?.divisionShortCode === 'MDY')) {
                        isPostOfficeAvailable = true
                    }
                }
                this.setState({
                    isBusgateServiceAvailable: response,
                    isPostOfficeAvailable
                })
            })
            .catch((error) => { })
    }

    // pick up 
    handlePickupLocation = (data) => {

        const { form_activity, currentStep } = this.state
        // if (currentStep !== 0) {
        //     this.setState({
        //         confirmToClearReceiverData: true,
        //         tempLocation: { ...data, 'id': data.value }
        //     })
        // } else {
        this.setState({
            pickupInformation: data,
            form_activity: { orderType: HOME_DELIVERY, favourite: form_activity.favourite ? form_activity.favourite : false },
            isUpdateData: false
        }, () => this.checkBusGateAvailableService(data.townshipId))
        // }
    }

    handlePickupDate = (value) => {
        const { pickupInformation } = this.state
        let pickUpInfo = {}
        let currentStep = 0
        if (value) {
            currentStep = 1
            pickUpInfo = { ...pickupInformation, 'pickupDate': value.format("YYYY-MM-DD") }
        } else {
            pickUpInfo = { ...pickupInformation }
        }
        this.setState({
            pickupInformation: pickUpInfo,
            pickUpDate: value,
            currentStep: currentStep
        })
    }

    deleteOrder = (uid) => {
        if (this.state?.isUpdateData) {
            message.destroy()
            message.error("You can't delete the receiver while editing their information.")
            return
        }
        const { userInput_collection, page } = this.state
        let new_user_collection = []
        let newPage = page
        userInput_collection.map(value => {
            if (value.id !== uid) {
                new_user_collection = [...new_user_collection, value]
            }
        })
        if (new_user_collection.length === 0) {
            newPage = 1
        } else if ((new_user_collection.length / 10) + 1 === page) {
            newPage = page - 1
        }
        this.setState({
            userInput_collection: new_user_collection,
            page: newPage
        })
        this.props.dispatch(updateAdvanceOrderList(new_user_collection))
    }

    confirm = () => {
        this.setState({
            openConfirmModal: true,
            currentStep: 2
        })
    }

    viewOrderDetail = (orderDetail) => {
        this.setState({
            orderDetail,
            openOrderDetailModal: true
        })
    }

    createOrderWithFormData = async () => {
        const { userInput_collection, pickupInformation, excel_order_list, isOrderTypeUpload, fail_message_code, pickUpInstruction } = this.state

        this.setState({
            orderState: confirmState
        })
        if (isOrderTypeUpload) {
            try {
                let formData = new FormData()
                userInput_collection.map((receiver, index) => {
                    let addressDetail = receiver.address
                    if (receiver.orderType === BUS_GATE) {
                        let note = ""
                        if (receiver.note !== null && receiver.note !== undefined) {
                            note = receiver.note ? "(" + receiver.note + ")" : ""
                        }
                        receiver = { ...receiver, note: receiver.busGateFees ? 'Pay Bus Gate Fees ' + note : receiver.note }
                        addressDetail = "(Bus Gate) " + receiver.busGateName
                    }

                    formData.append(`receiverDTOS[${index}].isFavourite`, receiver.favourite)
                    formData.append(`receiverDTOS[${index}].favouriteLocationId`, receiver.favouriteLocationId ? receiver.favouriteLocationId : 0)
                    formData.append(`receiverDTOS[${index}].receiverName`, receiver.receiverName)
                    formData.append(`receiverDTOS[${index}].phoneNumber`, receiver.phoneNumber)
                    formData.append(`receiverDTOS[${index}].addressDetail`, addressDetail)
                    formData.append(`receiverDTOS[${index}].orderType`, receiver.orderType ? orderTypeMap[receiver.orderType] : orderTypeMap[HOME_DELIVERY])
                    formData.append(`receiverDTOS[${index}].paymentOption`, receiver.orderType === HOME_DELIVERY ? receiver.paymentOptionName : PAY_BY_SHIPPER)
                    formData.append(`receiverDTOS[${index}].isSameDay`, receiver.sameDay ? receiver.sameDay : false)
                    formData.append(`receiverDTOS[${index}].parcelAmount`, receiver.parcelAmount)
                    formData.append(`receiverDTOS[${index}].codAmount`, receiver.total)
                    formData.append(`receiverDTOS[${index}].deliveryCharges`, receiver.deliveryFees)
                    formData.append(`receiverDTOS[${index}].note`, receiver.note && receiver.insuranceInfo?.insuranceFees ? `${receiver.note || ''} (FNI Insuranceဖြင့်အာမခံဝယ်ယူထားပါသည်။) ` : receiver.note ? receiver.note : '')
                    formData.append(`receiverDTOS[${index}].townShipId`, receiver.townshipId)
                    formData.append(`receiverDTOS[${index}].busGateId`, receiver.busGateId ? receiver.busGateId : 0)
                    formData.append(`receiverDTOS[${index}].partnershipStoreId`, receiver.partnershipStoreId ? receiver.partnershipStoreId : 0)
                    if (receiver?.insuranceInfo?.insuranceItems) {
                        receiver?.insuranceInfo?.insuranceItems.forEach((product, productIndex) => {
                            formData.append(`receiverDTOS[${index}].insuranceItems[${productIndex}].name`, product.name)
                            formData.append(`receiverDTOS[${index}].insuranceItems[${productIndex}].price`, product.price)
                            formData.append(`receiverDTOS[${index}].insuranceItems[${productIndex}].quantity`, product.quantity)
                        })
                    }
                    if (receiver?.insuranceInfo?.insuranceFees) {
                        formData.append(`receiverDTOS[${index}].insuranceFees`, receiver?.insuranceInfo?.insuranceFees)
                    }
                })
                formData.append('pickUpLocation', pickupInformation?.id)
                formData.append('pickUpDate', pickupInformation?.pickupDate)
                formData.append('pickUpInstruction', pickUpInstruction ? pickUpInstruction : '');
                formData.append('isAdmin', false)

                const response = await ApiHandler({ url: Api.order_create, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: formData })
                if (response && response.status === 200) {
                    this.setState({
                        orderState: successState,
                        currentStep: 0,
                        userInput_collection: [],
                        busGateList: [],
                        partnershipStoreList: [],
                        successfullOrderList: response.data,
                        isManualOrder: true
                    })
                    // this.clearAllUserOrder()
                    this.props.dispatch(clearOrder())
                }
            } catch (error) {
                let code = fail_message_code
                if (error && error.response && error.response.data && error.response.data.code) {
                    code = error.response.data.code
                }
                this.setState({
                    orderState: failState,
                    fail_message_code: code
                })
            }
        } else {
            try {
                let receiverList = []
                excel_order_list.map((receiver, index) => {
                    let addressDetail = receiver.addressDetail
                    if (receiver.orderType === 'Bus Gate') {
                        addressDetail = "(Bus Gate) " + receiver.busGateName
                    }

                    let receiverJson = {
                        'isFavourite': false,
                        'receiverName': receiver.receiverName,
                        'phoneNumber': receiver.phoneNumber,
                        'orderType': receiver.orderType,
                        'addressDetail': addressDetail,
                        'isSameDay': false,
                        'parcelAmount': receiver.parcelAmount,
                        'paymentOption': receiver.paymentOption,
                        'deliveryCharges': receiver.deliveryCharges,
                        'note': receiver.note,
                        'townShipId': receiver.townShipId,
                        'busGateId': receiver.busGateId || 0,
                        'codAmount': receiver.codAmount
                    }
                    receiverList.push(receiverJson)
                })
                let jsonData = {
                    'pickUpLocation': pickupInformation.id,
                    'pickUpDate': pickupInformation.pickupDate,
                    'pickUpInstruction': pickUpInstruction ? pickUpInstruction : '',
                    'isAdmin': false,
                    'receiverDTOS': receiverList
                }
                const response = await ApiHandler({ url: Api.excel_order_create, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: jsonData })
                if (response && response.status === 200) {
                    this.setState({
                        orderState: successState,
                        currentStep: 0,
                        userInput_collection: [],
                        busGateList: [],
                        partnershipStoreList: [],
                        successfullOrderList: response.data,
                        excel_order_list: [],
                        valid_excel_role: 0,
                        invalid_excel_role: 0,
                        isManualOrder: false
                    })
                    // this.clearAllUserOrder()
                    this.props.dispatch(clearOrder())
                }
            } catch (error) {
                let code = fail_message_code
                if (error && error.response && error.response.data && error.response.data.code) {
                    code = error.response.data.code
                }
                // this.setState({
                //     orderState: failState,
                //     fail_message_code: code
                // })
                this.setState({
                    orderState: successState,
                })
            }
        }
    }
    onChangePagination = (currentPage) => {
        this.setState({
            page: currentPage.current
        })
    }

    onChangeExcelPagination = (page, size) => {
        this.setState({
            excel_page: page
        })
    }

    GoToHistory = () => {
        this.setState({ openConfirmModal: false, orderState: initState })
        this.props.navigate(CustomPath.order_history)
    }

    handleSegment = () => {
        this.setState({
            isOrderTypeUpload: !this.state.isOrderTypeUpload
        })
    }

    airwaybill = () => {
        this.props.navigate(CustomPath.airwaybill, { state: { data: this.state.successfullOrderList, route: CustomPath.order_create } })
    }

    downloadExampleTemplate = async () => {
        this.setState({
            downloadingExcel: true
        })
        try {
            const response = await DownloadOrderFormatExcel()
            if (response && response.status === 200) {
                this.setState({
                    downloadingExcel: false
                })
            }
        } catch (error) {
            this.setState({
                downloadingExcel: false
            })
        }
    }

    handleFileUpload = async (file) => {
        const { pickupInformation } = this.state
        this.setState({
            isExcelFileUploading: true
        })
        try {
            if (pickupInformation && pickupInformation.townshipId) {
                let formData = new FormData()
                formData.append('excel', file);
                formData.append('id', pickupInformation.townshipId);
                const response = await ApiHandler({ url: Api.upload_order_excel, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, disableShowMessage: true })
                if (response && response.data) {
                    let list = []
                    let valid = 0
                    let invalid = 0
                    response.data.map((order, index) => {
                        order = {
                            ...order,
                            key: index + 1,
                            parcelAmount: order.parcelAmount ? parseInt(order.parcelAmount) : 0,
                            deliveryCharges: order.deliveryCharges ? parseInt(order.deliveryCharges) : 0,

                        }
                        if (order.paymentOption === COLLECT_FROM_CUSTOMER) {
                            order = { ...order, codAmount: order.parcelAmount + order.deliveryCharges }
                        } else if (order.paymentOption === PAY_BY_SHIPPER) {
                            order = { ...order, codAmount: order.parcelAmount }
                        }
                        list.push({ ...order, id: uuidv4() })
                        if (order.confirm) {
                            valid = valid + 1
                        } else {
                            invalid = invalid + 1
                        }
                    })
                    this.setState({
                        excel_order_list: list,
                        valid_excel_role: valid,
                        invalid_excel_role: invalid
                    })
                }
            }
        } catch (error) {
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
        this.setState({
            isExcelFileUploading: false
        })
    }

    removeInvalid = (id) => {
        const { excel_order_list, invalid_excel_role } = this.state
        let new_excel_order_list = []
        let invalid_role = 0;
        excel_order_list && excel_order_list.map(order => {
            if (order.id !== id) {
                new_excel_order_list.push(order)
            } else if (order.id === id) {
                if (invalid_excel_role !== 0) {
                    invalid_role = invalid_excel_role - 1
                }
            }
        })
        this.setState({
            invalid_excel_role: invalid_role,
            excel_order_list: new_excel_order_list
        })

    }

    uploadNewExcel = () => {
        this.setState({
            invalid_excel_role: 0,
            valid_excel_role: 0,
            excel_order_list: []
        })
    }

    updateStateToTable = (key, value) => {

        if (key === 'update_userInput') {
            this.setState({
                userInput_collection: value
            })
        }
        if (key === 'is_update') {
            this.setState({
                isUpdateData: value
            })
        }
    }

    handleInstruction = (key, value) => {
        if (key === 'pickUpInstruction') {
            this.setState({
                pickUpInstruction: value
            })
        }
    }

    render() {
        const {
            openFailReasonModal,
            failReasons,
            isFetchingMyLocation,
            fail_message_code,
            openOrderDetailModal,
            valid_excel_role,
            invalid_excel_role,
            downloadingExcel,
            isDataFetching,
            userAddress_collection,
            currentStep,
            isUpdateData,
            openConfirmModal,
            orderState,
            pickupInformation,
            userInput_collection,
            pickUpDate,
            excel_order_list,
            isExcelFileUploading,
            excel_page, form_activity, orderDetail, isBusgateServiceAvailable, isPostOfficeAvailable, isExitUserGroup, isFetchingUserGroup, openPickupModal, isManualOrder, selectedOrder } = this.state

        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };

        const customizeRenderEmpty = () => (
            <Flex justify='center' align='center' vertical style={{ marginTop: 30 }}
            >
                <Flex justify='center' align='center' style={{ width: 100, height: 100, borderRadius: '50%', position: 'relative', background: 'rgba(253, 245, 245, 1)' }}>
                    <SvgEmptyParcel width={50} height={50} color="rgba(194, 0, 47, 0.3)" style={{ position: 'absolute' }} />
                </Flex>
                <p style={{ color: '#000' }}>No Receiver Information</p>
            </Flex>
        );

        let columns = [
            // {
            //     title: <>{i18next.t("no")}</>,
            //     key: 'index',
            //     render: (value, item, index) => <span>{(page - 1) * 10 + index + 1}</span>,
            //     width: 5
            // },
            {
                title: <>{i18next.t("receiver")}</>,
                dataIndex: 'receiverName',
                key: 'receiverName'
            },
            {
                title: <>{i18next.t("phone_number")}</>,
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                responsive: ['md'],
            },
            {
                title: <>{i18next.t("township")}</>,
                dataIndex: 'townshipName',
                key: 'townshipName',
                responsive: ['md'],
            },
            {
                title: <>{i18next.t("parcel_value")}</>,
                dataIndex: 'parcelAmount',
                key: 'parcelAmount',
                responsive: ['md'],
            },
            {
                title: <>{i18next.t("deli_fees")}</>,
                dataIndex: 'deliveryFees',
                key: 'deliveryFees',
            },
            {
                title: <>{i18next.t("cod")}</>,
                dataIndex: 'total',
                key: 'total'
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, order) => (
                    <>
                        {
                            currentStep === 1 &&
                            <Space>
                                <Dropdown
                                    trigger={['click']}
                                    placement="bottomRight"

                                    menu={{
                                        items: [
                                            {
                                                label: <div onClick={() => this.viewOrderDetail(order)} style={{ display: 'flex', alignItems: 'center', width: 85 }}><div style={{ width: 7, height: 15, backgroundColor: 'green', borderRadius: 10, marginRight: 8 }}></div><span>{i18next.t("view")}</span></div>,
                                                key: '0',
                                            },
                                            {
                                                label: <div onClick={() => this.handleEditOrder(order.id)} style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: 7, height: 15, backgroundColor: Theme.colors.system_blue, borderRadius: 10, marginRight: 8 }}></div><span>{i18next.t("edit")}</span></div>,
                                                key: '1',
                                            },
                                            {
                                                label: <div onClick={() => this.deleteOrder(order.id)} style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: 7, height: 15, backgroundColor: 'red', borderRadius: 10, marginRight: 8 }}></div><span>{i18next.t("delete")}</span></div>,
                                                key: '2',
                                            }
                                        ]
                                    }}
                                >
                                    <div style={{ width: 30, height: 30, background: '#1677ff', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                        <MenuOutlined style={{ color: 'white' }} />
                                    </div>
                                </Dropdown>
                            </Space >
                        }
                    </>
                ),
                width: 10
            },
        ]

        let excel_order_columns = [
            // {
            //     title: <>{i18next.t("no")}</>,
            //     key: '1',
            //     render: (value, item, index) => <span>{(excel_page - 1) * 50 + index + 1}</span>,
            //     width: 5
            // },
            {
                title: <>{i18next.t("receiver")}</>,
                dataIndex: 'receiverName',
                key: '2'
            },
            {
                title: <>{i18next.t("phone_number")}</>,
                dataIndex: 'phoneNumber',
                key: '3'
            },
            {
                title: <>{i18next.t("township_city")}</>,
                dataIndex: 'townShip',
                key: '4',

            },
            {
                title: <>{i18next.t("parcel_value")}</>,
                dataIndex: 'parcelAmount',
                key: '5'
            },
            {
                title: <>{i18next.t("deli_fees")}</>,
                dataIndex: 'deliveryCharges',
                key: '6'
            },
            {
                title: <>{i18next.t("cod")}</>,
                dataIndex: 'codAmount',
                key: '7'
            }, {
                title: <>{i18next.t("status")}</>,
                key: '8',
                render: (_, order) => (
                    <Space>
                        {
                            order.confirm ?
                                <span style={{ color: Colors.darkGreen }}>Valid</span>
                                :
                                <>
                                    <span style={{ color: Colors.red }}>Invalid</span>
                                    <Button
                                        size='small'
                                        type='primary' shape='circle' onClick={() => this.setState({ openFailReasonModal: true, failReasons: order.failReasons })}>
                                        <QuestionOutlined color='#000000' />
                                    </Button>
                                </>
                        }

                    </Space>
                ),
            }, {
                title: 'Action',
                key: '9',
                render: (_, order) => (
                    <Space>
                        {
                            order.confirm ?
                                <></>
                                :
                                <NJVButton size={'middle'} type="primary" shape="circle" onClick={() => this.removeInvalid(order.id)} danger><DeleteOutlined /></NJVButton>
                        }

                    </Space>
                ),
                width: 10
            },
        ]

        return (
            <>
                <Modal
                    title={i18next.t("confirmation")}
                    centered
                    open={openPickupModal}
                    onCancel={() => this.setState({ openPickupModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                    maskClosable={false}
                >
                    <p>Your data can't get anymore becasue of pervious pickup location isn't exist</p>
                    <NJVButton type='primary' style={{ marginRight: 10 }} onClick={() => {
                        this.props.dispatch(clearOrder())
                        this.setState({ openPickupModal: false })
                        window.location.reload()
                    }}>OK</NJVButton>
                </Modal>
                <Modal
                    title={i18next.t("fail_reason")}
                    centered
                    open={openFailReasonModal}
                    onCancel={() => this.setState({ openFailReasonModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        failReasons && failReasons.map((item) => <div>{item}</div>)
                    }
                    <NJVButton type='primary' style={{ marginTop: 10 }} onClick={() => this.setState({ openFailReasonModal: false })}>{i18next.t("ok")}</NJVButton>
                </Modal >
                <Modal
                    title={i18next.t("order_detail")}
                    centered
                    open={openOrderDetailModal}
                    onCancel={() => this.setState({ openOrderDetailModal: false, orderDetail: {} })}
                    footer={null}
                    className='custom-modal'
                >
                    <Row style={{ marginTop: 30 }}>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("receiver_name")} />
                            <div>{orderDetail.receiverName}</div>
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("receiver_phone_number")} />
                            <div>{orderDetail.phoneNumber}</div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        {/* <Col span={12}>
                            <NJVLabel label={i18next.t("division_state")} />
                            <div>{orderDetail.divisionName}</div>
                        </Col> */}
                        <Col span={12}>
                            <NJVLabel label={i18next.t("township_city")} />
                            <div>{orderDetail.townshipName}</div>
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("address")} />
                            <div>{orderDetail.orderType === BUS_GATE ? `${orderDetail.busGateName}(Bus Gate)` : orderDetail.address}</div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("order_type")} />
                            <div>
                                {
                                    orderDetail && orderDetail.orderType && <>{i18next.t(orderTypeLocalizationMap[orderDetail.orderType])}</>
                                }
                            </div>
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("payment_option")} />
                            <div>
                                {
                                    orderDetail && orderDetail.paymentOptionName === COLLECT_FROM_CUSTOMER ?
                                        <>{i18next.t("collect_from_customer")}</>
                                        :
                                        <>{i18next.t("pay_by_shipper")}</>
                                }
                            </div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={8}>
                            <NJVLabel label={i18next.t("parcel_value")} />
                            <div>{orderDetail.parcelAmount}</div>
                        </Col>

                        <Col span={9}>
                            <NJVLabel label={i18next.t("delivery_fees")} />
                            <div>{orderDetail.deliveryFees}</div>
                        </Col>
                        <Col span={7}>
                            <NJVLabel label={i18next.t("cod")} />
                            <div>{orderDetail.total}</div>
                        </Col>

                        {
                            orderDetail.sameDay ?
                                <>
                                    <Col span={24}>
                                        <Divider style={{ margin: '13px 0px' }} />
                                    </Col>
                                    <Col span={24} >
                                        <span style={{ fontWeight: '500' }}>{i18next.t("same_day")}</span>
                                    </Col>
                                </>
                                : orderDetail.busGateFees ?
                                    <>
                                        <Col span={24}>
                                            <Divider style={{ margin: '13px 0px' }} />
                                        </Col>
                                        <Col span={24} >
                                            <span style={{ fontWeight: '500' }}>{i18next.t("pay_bus_gate_fees")}</span>
                                        </Col>
                                    </>
                                    :
                                    <></>
                        }
                        {
                            orderDetail.note || orderDetail.isExistInsurance ?
                                <>
                                    <Col span={24}>
                                        <Divider style={{ margin: '13px 0px' }} />
                                    </Col>
                                    <Col span={24}>
                                        <NJVLabel label={i18next.t("note")} />
                                        <div>{orderDetail.isExistInsurance && '(FNI Insuranceဖြင့်အာမခံဝယ်ယူထားပါသည်။)'}{orderDetail.note}</div>
                                    </Col>
                                </>

                                :
                                <></>
                        }
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={24}>
                            <Checkbox checked={orderDetail.isExistInsurance}>Purchase Insurance</Checkbox>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center', marginTop: 50 }}>
                            <Button
                                onClick={() => this.setState({ openOrderDetailModal: false, orderDetail: {} })}
                                type='primary' style={{ backgroundColor: Theme.colors.primary }}>{i18next.t("close")}</Button>
                        </Col>

                    </Row>
                </Modal>
                <Modal
                    title={null}
                    centered
                    open={openConfirmModal}
                    onOk={() => this.createOrderWithFormData()}
                    onCancel={() => this.setState({
                        openConfirmModal: false,
                        orderState: initState

                    })}
                    maskClosable={false}
                    footer={null}
                    closeIcon={orderState === successState ? true : null}
                    className='custom-modal'
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {
                                orderState === initState ?
                                    <>
                                        <Flex gap={25} vertical>
                                            <ModalTitle label={i18next.t("instruction_title")} />
                                            <NJVInput
                                                bgcolor={Theme.colors.light_gray}
                                                size="large"
                                                showCount
                                                onChange={(e) => this.handleInstruction('pickUpInstruction', e.target.value)}
                                                style={{
                                                    width: '100%'
                                                }}
                                                maxLength={200}
                                                placeholder={i18next.t('instruction_note')}
                                                istextareainput="true"
                                            />
                                            <div>
                                                <NJVButton type='text' style={{ marginRight: 10 }} onClick={() => this.setState({ openConfirmModal: false, currentStep: 1 })}>{i18next.t("cancel")}</NJVButton>
                                                <NJVButton buttonprimary={Theme.colors.primary} type='primary' onClick={() => this.createOrderWithFormData()}>{i18next.t("create_parcel")}</NJVButton>
                                            </div>
                                        </Flex>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                orderState === confirmState ?
                                    <>

                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={animationData}
                                            loop={true}
                                            autoplay={true}
                                        />

                                        <div style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_please_wait")}</div>
                                    </> :
                                    <></>
                            }
                            {
                                orderState === successState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={SuccessAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        {
                                            isManualOrder ? <>
                                                <div style={{ marginBlock: 10, fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_parcel_create_success")}</div>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 20 }}>
                                                    <NJVButton size='large' type='text' icon={<PrinterOutlined />} onClick={() => this.airwaybill()}>{i18next.t("msg_print_awb")}</NJVButton>
                                                    <NJVButton buttonprimary={Theme.colors.primary} size='large' type='primary' onClick={() => this.GoToHistory()}>{i18next.t("msg_go_to_parcel_history")}</NJVButton>
                                                </div>
                                            </>
                                                :
                                                <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>
                                                    သင်၏ပါဆယ်များအား စတင်ဆောင်ရွက်နေပြီဖြစ်ပြီး ခန့်မှန်းကြာမြင့်ချိန် နောက် ၃မိနစ်တွင် ပါဆယ်မှတ်တမ်း(Parcel History)ထဲတွင်၀င်ရောက်စစ်ဆေးနိုင်ပါသည်။
                                                </div>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                            {
                                orderState === failState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={FailAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 20, marginBottom: 20, fontSize: 15, fontWeight: '500' }}>
                                            {
                                                fail_message_code === '076' ?
                                                    <>
                                                        {i18next.t("msg_fail_to_create_parcel")}
                                                    </>
                                                    :
                                                    fail_message_code === '008' ? <>{i18next.t("msg_reselect_date")}</> :
                                                        <>
                                                            {
                                                                fail_message_code === '077' ?
                                                                    <>
                                                                        {i18next.t("msg_fail_to_create_parcel_077")}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {i18next.t("msg_fail_to_create_parcel")}
                                                                    </>
                                                            }
                                                        </>
                                            }
                                        </div>
                                        <NJVButton size='large' shape='round' type='primary' onClick={() => this.setState({ openConfirmModal: false, orderState: initState })}>
                                            Close
                                        </NJVButton>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </Modal>
                <>
                    {
                        isFetchingUserGroup ?
                            <Skeleton />
                            :
                            <>
                                {
                                    isExitUserGroup ?
                                        <Row>
                                            {/* <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                                                <TitleLevel3 label={i18next.t("parcel_create")} />
                                                <NJVButton
                                                    onClick={() => this.setState({ openClearAllDataModal: true })}
                                                    icon={<SvgBrushOutlined width={14} height={14} color={"#fff"} />}
                                                    type='primary'
                                                    style={{ background: Theme.colors.primary }}
                                                >
                                                    {i18next.t("clear_all")}
                                                </NJVButton>
                                            </Col> */}
                                            <Col span={24}>
                                                {
                                                    isDataFetching ?
                                                        <Skeleton active />
                                                        :
                                                        <Row gutter={[16, 16]}>
                                                            <Col lg={16} md={0} sm={0} xs={0}>
                                                                <NJVCard
                                                                    cardpadding="0 px"
                                                                    style={{ height: '84vh' }}
                                                                    bordered={false}
                                                                    title={<div style={{ display: 'flex', alignItems: 'center' }}><MainTitle style={{ marginLeft: 15 }} label={i18next.t("parcel_create")} /></div>}
                                                                >
                                                                    <Row>
                                                                        <Col lg={24} md={24} sm={24} xs={24}>
                                                                            {
                                                                                selectedOrder === 'Manual Order' ?
                                                                                    <Col span={24}>
                                                                                        <ConfigProvider
                                                                                            renderEmpty={customizeRenderEmpty}>
                                                                                            <NJVTable
                                                                                                headerbgcolor="#F7F7F7"
                                                                                                headerborderradius='0px'
                                                                                                columns={columns}
                                                                                                dataSource={userInput_collection}
                                                                                                onChange={this.onChangePagination}
                                                                                                rowClassName={(record, index) => {
                                                                                                    return index % 2 === 0 ? 'blue-row' : 'black-row';
                                                                                                }}
                                                                                            />
                                                                                        </ConfigProvider>
                                                                                        {
                                                                                            userInput_collection && userInput_collection.length !== 0 && currentStep === 1 ?
                                                                                                <Col span={24} style={{ textAlign: 'right', marginRight: 10, marginBottom: 10 }}>
                                                                                                    <NJVButton buttonprimary={Theme.colors.primary} type="primary" onClick={() => this.confirm()}>{i18next.t("create_parcel")}</NJVButton>
                                                                                                </Col>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </Col>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            <Col lg={24} md={24} sm={24} xs={24} style={{ marginBlock: 20 }}>
                                                                                                <div style={{ marginBottom: 15, marginInline: 10 }}>
                                                                                                    <span className='valid-tag'>Valid : {valid_excel_role}</span>
                                                                                                    {
                                                                                                        invalid_excel_role && invalid_excel_role !== 0 ?
                                                                                                            <span className='invalid-tag'>Invalid : {invalid_excel_role}</span>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </div>
                                                                                                <ConfigProvider
                                                                                                    renderEmpty={customizeRenderEmpty}>
                                                                                                    <NJVTable
                                                                                                        headerbgcolor="#F7F7F7"
                                                                                                        headerborderradius='0px'
                                                                                                        columns={excel_order_columns}
                                                                                                        dataSource={excel_order_list}
                                                                                                        pagination={{
                                                                                                            position: 'top' | 'bottom',
                                                                                                            total: excel_order_list.length,
                                                                                                            current: excel_page,
                                                                                                            onChange: this.onChangeExcelPagination,
                                                                                                            defaultPageSize: 50,
                                                                                                            showSizeChanger: false
                                                                                                        }}
                                                                                                        rowClassName={(record, index) => {
                                                                                                            return index % 2 === 0 ? 'blue-row' : 'black-row';
                                                                                                        }}
                                                                                                    />
                                                                                                </ConfigProvider>
                                                                                                <div style={{ marginTop: 20, textAlign: 'right', marginInlineEnd: 10 }}>
                                                                                                    <NJVButton buttonprimary={Theme.colors.primary} disabled={invalid_excel_role === 0 && valid_excel_role > 0 ? false : true} type="primary" onClick={() => this.confirm()}>{i18next.t("create_parcel")}</NJVButton>
                                                                                                </div>
                                                                                            </Col>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </NJVCard>
                                                            </Col>

                                                            <Col lg={0} md={24} sm={24} xs={24}>
                                                                <NJVCard
                                                                    cardpadding="0 px"
                                                                    bordered={false}
                                                                    title={<div style={{ display: 'flex', alignItems: 'center' }}><Typography.Title level={4} style={{ margin: 0, marginLeft: 15 }}>{i18next.t("parcel_create")}</Typography.Title></div>}
                                                                >

                                                                    <Row>
                                                                        <Col lg={24} md={24} sm={24} xs={24}>
                                                                            {
                                                                                selectedOrder === 'Manual Order' ?
                                                                                    <Col span={24}>
                                                                                        <ConfigProvider
                                                                                            renderEmpty={customizeRenderEmpty}>
                                                                                            <NJVTable
                                                                                                headerbgcolor="#F7F7F7"
                                                                                                headerborderradius='0px'
                                                                                                columns={columns}
                                                                                                dataSource={userInput_collection}
                                                                                                onChange={this.onChangePagination}
                                                                                                rowClassName={(record, index) => {
                                                                                                    return index % 2 === 0 ? 'blue-row' : 'black-row';
                                                                                                }}
                                                                                            />
                                                                                        </ConfigProvider>
                                                                                        {
                                                                                            userInput_collection && userInput_collection.length !== 0 && currentStep === 1 ?
                                                                                                <Col span={24} style={{ textAlign: 'right', marginRight: 10, marginBottom: 10 }}>
                                                                                                    <NJVButton buttonprimary={Theme.colors.primary} type="primary" onClick={() => this.confirm()}>{i18next.t("create_parcel")}</NJVButton>
                                                                                                </Col>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </Col>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            <Col lg={24} md={24} sm={0} xs={0} style={{ marginBlock: 20 }}>
                                                                                                <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'space-between', marginInline: 10 }}>
                                                                                                    <div>
                                                                                                        <span className='valid-tag'>Valid : {valid_excel_role}</span>
                                                                                                        {
                                                                                                            invalid_excel_role && invalid_excel_role !== 0 ?
                                                                                                                <span className='invalid-tag'>Invalid : {invalid_excel_role}</span>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <NJVButton type="primary" style={{ backgroundColor: 'red' }} onClick={() => this.uploadNewExcel()}>{i18next.t("upload_new_excel")}</NJVButton>
                                                                                                </div>
                                                                                                <ConfigProvider
                                                                                                    renderEmpty={customizeRenderEmpty}>
                                                                                                    <NJVTable
                                                                                                        headerbgcolor="#F7F7F7"
                                                                                                        headerborderradius='0px'
                                                                                                        columns={excel_order_columns}
                                                                                                        dataSource={excel_order_list}
                                                                                                        pagination={{
                                                                                                            position: 'top' | 'bottom',
                                                                                                            total: excel_order_list.length,
                                                                                                            current: excel_page,
                                                                                                            onChange: this.onChangeExcelPagination,
                                                                                                            defaultPageSize: 50,
                                                                                                            showSizeChanger: false
                                                                                                        }}
                                                                                                        rowClassName={(record, index) => {
                                                                                                            return index % 2 === 0 ? 'blue-row' : 'black-row';
                                                                                                        }}
                                                                                                    />
                                                                                                </ConfigProvider>
                                                                                                <div style={{ marginTop: 20, textAlign: 'right', marginInlineEnd: 10 }}>
                                                                                                    <NJVButton buttonprimary={Theme.colors.primary} disabled={invalid_excel_role === 0 && valid_excel_role > 0 ? false : true} type="primary" size='large' onClick={() => this.confirm()}>{i18next.t("create_parcel")}</NJVButton>
                                                                                                </div>
                                                                                            </Col>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </NJVCard>
                                                            </Col>

                                                            <Col lg={8} md={24} sm={24} xs={24}>
                                                                <NJVCard
                                                                    bordered={false}
                                                                >
                                                                    <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
                                                                        <Col span={24}>
                                                                            <Typography.Title level={5} style={{ marginBlock: 0 }}>{i18next.t("sender_info")}</Typography.Title>
                                                                        </Col>
                                                                        <Col lg={24} md={12} sm={12} xs={24}>
                                                                            <NJVSelect
                                                                                value={pickupInformation.id}
                                                                                placeholder={i18next.t("pickup_address")}
                                                                                onChange={(key, value) => this.handlePickupLocation(value)}
                                                                                size='large'
                                                                                style={{ width: '100%' }}
                                                                                loading={isFetchingMyLocation}
                                                                                options={
                                                                                    userAddress_collection && userAddress_collection.map((userAddress) => {
                                                                                        return {
                                                                                            id: userAddress.id,
                                                                                            value: userAddress.id,
                                                                                            label: userAddress.name,
                                                                                            townshipId: userAddress?.township?.id,
                                                                                            divisionShortCode: userAddress?.township?.division?.shortCode
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />

                                                                        </Col>
                                                                        <Col lg={24} md={12} sm={12} xs={24}>
                                                                            <NJVDatePicker
                                                                                name={`pickupDate`}
                                                                                allowClear={false}
                                                                                disabledDate={this.disabledDate}
                                                                                value={pickUpDate}
                                                                                disabled={isFetchingMyLocation}
                                                                                placeholder={i18next.t("pickup_date")}
                                                                                onChange={(value) => this.handlePickupDate(value)}
                                                                                size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                                                        </Col>
                                                                    </Row>
                                                                </NJVCard>
                                                                {
                                                                    currentStep === 1 &&
                                                                    <Row>
                                                                        {
                                                                            this.props.isMobileSize ?
                                                                                <Col lg={0} md={0} sm={24} xs={24}>
                                                                                    <NJVCard
                                                                                        bordered={false}
                                                                                        className='custom-scrollbar'
                                                                                        style={{ marginTop: 10 }}
                                                                                    >
                                                                                        <Flex gap={10} style={{ marginBottom: 20 }}>
                                                                                            <NJVButton size="large"
                                                                                                buttonprimary={Theme.colors.primary}
                                                                                                style={{
                                                                                                    backgroundColor: selectedOrder === 'Manual Order' ? '#C2002F' : '#fff',
                                                                                                    color: selectedOrder === 'Manual Order' ? '#fff' : '#C2002F',
                                                                                                    width: '100%',
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        selectedOrder: 'Manual Order'
                                                                                                    })
                                                                                                }}
                                                                                            >Manual Order</NJVButton>
                                                                                        </Flex>
                                                                                        {
                                                                                            selectedOrder === 'Manual Order' ?
                                                                                                <ReceiverForm
                                                                                                    pickupInformation={pickupInformation}
                                                                                                    userInput_collection={userInput_collection}
                                                                                                    form_activity={form_activity}
                                                                                                    isPostOfficeAvailable={isPostOfficeAvailable}
                                                                                                    isBusgateServiceAvailable={isBusgateServiceAvailable}
                                                                                                    isUpdateData={isUpdateData}

                                                                                                    updateStateToTable={this.updateStateToTable}
                                                                                                    handleUserInputCollection={this.handleUserInputCollection}
                                                                                                    checkBusGateAvailableService={this.checkBusGateAvailableService}

                                                                                                    handleStateUpdate={updateStateFromTable => this.updateState = updateStateFromTable}
                                                                                                    handleEditOrder={editOrder => this.handleEditOrder = editOrder}
                                                                                                />
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </NJVCard>
                                                                                </Col>
                                                                                :
                                                                                <Col span={24}>
                                                                                    <NJVCard
                                                                                        bordered={false}
                                                                                        style={{ height: '58vh', overflowX: 'hidden', marginTop: 10 }}
                                                                                        className='custom-scrollbar'
                                                                                    >
                                                                                        <Flex gap={10} style={{ marginBottom: 20 }}>
                                                                                            <NJVButton size="large"
                                                                                                buttonprimary={Theme.colors.primary}
                                                                                                style={{
                                                                                                    backgroundColor: selectedOrder === 'Manual Order' ? '#C2002F' : '#fff',
                                                                                                    color: selectedOrder === 'Manual Order' ? '#fff' : '#C2002F',
                                                                                                    width: '100%',
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        selectedOrder: 'Manual Order'
                                                                                                    })
                                                                                                }}
                                                                                            >Manual Order</NJVButton>
                                                                                            <NJVButton
                                                                                                buttonprimary={Theme.colors.primary}
                                                                                                size="large"
                                                                                                style={{
                                                                                                    backgroundColor: selectedOrder === 'Bulk Order' ? '#C2002F' : '#fff',
                                                                                                    color: selectedOrder === 'Bulk Order' ? '#fff' : '#C2002F',
                                                                                                    width: '100%',
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        selectedOrder: 'Bulk Order'
                                                                                                    })
                                                                                                }}
                                                                                            >Bulk Order</NJVButton>
                                                                                        </Flex>
                                                                                        {
                                                                                            selectedOrder === 'Manual Order' ?
                                                                                                <ReceiverForm
                                                                                                    pickupInformation={pickupInformation}
                                                                                                    userInput_collection={userInput_collection}
                                                                                                    form_activity={form_activity}
                                                                                                    isPostOfficeAvailable={isPostOfficeAvailable}
                                                                                                    isBusgateServiceAvailable={isBusgateServiceAvailable}
                                                                                                    isUpdateData={isUpdateData}

                                                                                                    updateStateToTable={this.updateStateToTable}
                                                                                                    handleUserInputCollection={this.handleUserInputCollection}
                                                                                                    checkBusGateAvailableService={this.checkBusGateAvailableService}

                                                                                                    handleStateUpdate={updateStateFromTable => this.updateState = updateStateFromTable}
                                                                                                    handleEditOrder={editOrder => this.handleEditOrder = editOrder}
                                                                                                />
                                                                                                :
                                                                                                <Col span={24} style={{ marginBlock: 20 }}>
                                                                                                    {
                                                                                                        excel_order_list.length > 0 ?
                                                                                                            <NJVButton type="primary" buttonprimary={Theme.colors.primary} block onClick={() => this.uploadNewExcel()} style={{ marginTop: 10 }}>{i18next.t("upload_new_excel")}</NJVButton>
                                                                                                            :
                                                                                                            <>
                                                                                                                <NJVButton buttonprimary="#B19478" type="primary" block size='large' loading={downloadingExcel} onClick={() => this.downloadExampleTemplate()}>
                                                                                                                    {i18next.t("download_template_sample")}<DownloadOutlined />
                                                                                                                </NJVButton>
                                                                                                                <ConfigProvider
                                                                                                                    theme={{
                                                                                                                        token: {
                                                                                                                            colorPrimary: '#C2002F66',
                                                                                                                            colorBorder: '#C2002F66',
                                                                                                                            colorFillAlter: '#FFEFEC'
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Dragger
                                                                                                                        {...draggerProps}
                                                                                                                        accept={[".xlsx", ".xls"]}
                                                                                                                        maxCount={1}
                                                                                                                        style={{ marginTop: 15 }}>
                                                                                                                        <p className="ant-upload-drag-icon">
                                                                                                                            <SvgFileUpload width={25} height={25} />
                                                                                                                        </p>
                                                                                                                        <p className="ant-upload-hint">
                                                                                                                            {
                                                                                                                                isExcelFileUploading ?
                                                                                                                                    <div>
                                                                                                                                        <Spin /> <span>uploading...</span>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <span style={{ fontStyle: 'italic' }}>{i18next.t("upload_excel_file_only")}</span>
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </Dragger>
                                                                                                                </ConfigProvider>
                                                                                                            </>
                                                                                                    }
                                                                                                </Col>
                                                                                        }
                                                                                    </NJVCard>
                                                                                </Col>
                                                                        }
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                }
                                            </Col>
                                        </Row>
                                        :
                                        <Row style={{ marginTop: 100, justifyContent: 'center', width: '100%' }}>
                                            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Lottie
                                                    style={{ height: '200px' }}
                                                    animationData={NoParcelCreate}
                                                    loop={false}
                                                    autoplay={true}
                                                />
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                <div style={{ fontSize: 17 }}>
                                                    သင့်အကောင့်သည် ပါဆယ်ပြုလုပ်ရန်အဆင်သင့်မဖြစ်သေးပါ။
                                                    <br />
                                                    Ninja Vanသို့ဆက်သွယ်ပါ။
                                                </div>
                                            </Col>
                                        </Row>
                                }
                            </>
                    }
                </>
            </>
        )
    }
}

export default compose(withRouter)(NewAdvanceOrderForm)