import React, { useState } from 'react';

const UploadVideo = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const getPresignedUrl = async (fileName) => {
        const response = await fetch(`http://localhost:9091/public/get-presigned-url?key=${fileName}`);
        return await response.text();
    };

    const uploadFileToS3 = async (file) => {
        const fileName = encodeURIComponent(file.name);
        const presignedUrl = await getPresignedUrl(fileName);

        // Perform the upload using the presigned URL
        const uploadResponse = await fetch(presignedUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,  // Set the correct MIME type
            },
            body: file
        });

        if (uploadResponse.ok) {
            setUploadStatus('Upload successful!');
        } else {
            setUploadStatus('Upload failed.');
        }
    };

    const handleUpload = () => {
        if (selectedFile) {
            uploadFileToS3(selectedFile);
        } else {
            alert('Please select a file to upload.');
        }
    };

    return (
        <div>
            <h2>Upload Video to S3</h2>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
            <p>{uploadStatus}</p>
        </div>
    );
};

export default UploadVideo;
