import {
    Alert,
    Checkbox,
    Col,
    ConfigProvider,
    Flex,
    Form,
    Image,
    message,
    Modal,
    Row,
    Select,
    Typography,
    Upload
} from "antd";
import i18next from "i18next";
import React, { useEffect, useRef, useState } from 'react';
import { NJVBoldFont, NJVButton, NJVForm, NJVInput, NJVSelect } from "../../components/core-component";
import {
    SvgAccountNumber,
    SvgBusinessName,
    SvgDivision,
    SvgEcommerce,
    SvgEmial,
    SvgFileUpload,
    SvgPayment,
    SvgUser,
    SvgUserPassword,
    SvgUserPhone
} from "../../components/custom-svgIcon";
import { FormLabel } from "../../components/general-component";
import Theme from "../../components/theme";
import { nrcCode_map, nrcNo_collection, payment_type_collection } from "../../core/constant";
import { NRCValidator, NumberOnlyValidator, RestrictPhoneNumberValidator } from "../../core/validator";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandlerNoAuth } from "../../network/network-manager";
import TermsAndCondition from "../terms-and-condition/terms-and-condition";

const { Title } = Typography;
const { Option } = Select

function Register(prop) {
    const [currentStep, setCurrentStep] = useState(0);
    const userInformationRef = useRef(null);
    const bankInformationRef = useRef(null)
    const [shipperInfo, setShipperInfo] = useState({});
    const [townshipCode_collection, setTownshipCode_collection] = useState([]);
    const [township_collection, setTownship_collection] = useState([]);
    const [division_collection, setDivision_collection] = useState([]);
    const [township_collection_map, setTownship_collection_map] = useState([]);
    const [product_category_collection, setProductCategoryCollection] = useState([]);
    const [nrcDivision_number, setNrcDivision_number] = useState();
    const [nrcTownship_code, setNrcTownship_code] = useState();
    const [nrcCitizen, setNrcCitizen] = useState();
    const [is_loading, setIs_loading] = useState(false);
    const [agreeTermsAndCondition, setAgreeTermsAndCondition] = useState(false);
    const [showTermsAndConditionModal, setShowTermsAndConditionModal] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')

    useEffect(() => {
        fetchDivision()
        fetchProductCategory()
    }, [])

    const fetchDivision = async () => {
        try {
            const response = await ApiHandlerNoAuth({
                url: Api.division_get_all,
                method: HTTP_METHOD.GET,
                mediaType: MEDIA_TYPE.JSON
            })
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.forEach(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                setDivision_collection(divisionList)
                setTownship_collection_map(townshipMap)
            }
        } catch (error) {
        }
    }
    const fetchProductCategory = async () => {
        try {
            const requestParams = {
                page: 0,
                size: 20
            }
            const response = await ApiHandlerNoAuth({
                url: Api.product_category,
                method: HTTP_METHOD.GET,
                mediaType: MEDIA_TYPE.JSON,
                requestParams
            })
            if (response) {
                setProductCategoryCollection(response.content)
            }
        } catch (error) {
        }
    }
    const handleDivisionSelect = (value) => {
        setTownship_collection(township_collection_map[value])
        userInformationRef?.current?.setFieldsValue({ township: null });
    }

    const handleOnCodeChange = (value) => {
        setTownshipCode_collection(nrcCode_map[value]);
        setNrcTownship_code(null)
        setNrcDivision_number(value)
    }

    const handleOnTownShipCodeChange = (value) => {
        setNrcTownship_code(value)
    }

    const completeUserInfo = (formData) => {
        setShipperInfo({ ...shipperInfo, ...formData })
        setCurrentStep(1)
    }

    const completeBusinessInfo = (formData) => {
        setShipperInfo({ ...shipperInfo, ...formData })
        setCurrentStep(2)
    }

    const handleUserInput = (key, value) => {
        setShipperInfo({ ...shipperInfo, [key]: value })
    }

    const onFilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
    };

    const handleFileUpload = (value) => {
        setImageFile(value.fileList)
        setShipperInfo({ ...shipperInfo, 'bankPhoto': value.file })
        if (bankInformationRef.current) {
            bankInformationRef.current.setFieldsValue({ bankPhoto: value.fileList.length !== 0 ? value.file : null });
        }
    };

    const signUp = async (formData) => {
        setIs_loading(true)
        if (!agreeTermsAndCondition) {
            message.error("Please accept the terms and conditions first")
            setIs_loading(false)
            return
        }

        try {
            if (nrcCitizen === null || nrcDivision_number === null || nrcTownship_code === null) {
                message.error("Please fill nrc information")
            } else if ((formData.primaryBank === 'AYA_PAY' || formData.primaryBank === 'KBZ_PAY' || formData.primaryBank === 'CB_PAY') && (formData.primaryAccounNumber.length < 6 && formData.primaryAccounNumber.length > 11)) {
                message.error("Invalid primary account number")
            } else if ((formData.primaryBank === 'AYA' || formData.primaryBank === 'KBZ' || formData.primaryBank === 'YOMA') && formData.primaryAccounNumber.length < 15) {
                message.error("Invalid primary account number")
            } else {
                const formDatas = new FormData()
                formDatas.append('firstName', shipperInfo.firstName);
                formDatas.append('lastName', shipperInfo.lastName);
                formDatas.append('phoneNumber', shipperInfo.phoneNumber);
                formDatas.append('accounts[0].loginId', shipperInfo.phoneNumber);
                formDatas.append('accounts[0].accountType', 'PHONE');
                formDatas.append('accounts[0].password', shipperInfo.password);
                formDatas.append('emailAddress', shipperInfo.email);
                formDatas.append('divisionId', shipperInfo.division)
                formDatas.append('township.id', shipperInfo.township);
                formDatas.append('address1', shipperInfo.address1);
                formDatas.append('business.businessName', shipperInfo.businessName)
                formDatas.append('business.businessOwnerNRC', nrcDivision_number + "/" + nrcTownship_code + "(" + nrcCitizen + ")" + shipperInfo.nrcNo)
                formDatas.append('business.facebookPageLink', shipperInfo.businessFacebookPageLink)
                formDatas.append('business.paymentNumber', formData.primaryAccounNumber)
                formDatas.append('business.ecommerceRegNumber', shipperInfo.ecommerceRegNumber)
                formDatas.append('business.accountOwnerName', formData.accountOwnerName)
                formDatas.append('business.paymentType', formData.primaryBank)
                formDatas.append('business.productCategory.id', shipperInfo.businessCategory)
                formDatas.append('bankBookImg', formData.bankPhoto)

                const response = await ApiHandlerNoAuth({
                    url: Api.signup,
                    method: HTTP_METHOD.POST,
                    mediaType: MEDIA_TYPE.FORM_DATA,
                    requestData: formDatas
                })
                if (response && response.status === 200) {
                    prop.openSuccessPage(true)
                    setTimeout(() => {
                        prop.setInitial();
                    },3000)
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                if (error.response.data) {
                    message.error(error.response.data.message)
                }
            }
        }
        setIs_loading(false)
    }

    return (
        <>
            <Modal
                title={null}
                style={{
                    top: 20,
                    bottom: 50
                }}
                centered
                open={showTermsAndConditionModal}
                onOk={() => setShowTermsAndConditionModal(false)}
                onCancel={() => setShowTermsAndConditionModal(false)}
                footer={null}
                width={600}
                className='custom-modal'
            >
                <TermsAndCondition />
            </Modal>
            <Col span={24}>
                <Title level={3}
                    style={{ marginTop: 20 }}>{currentStep === 0 ? i18next.t("personal_information") : currentStep === 1 ? i18next.t("business_information") : i18next.t("bank_information")}</Title>
            </Col>
            {
                currentStep === 0 ?
                    <NJVForm
                        layout="vertical"
                        onFinish={completeUserInfo}
                        initialValues={shipperInfo}
                        ref={userInformationRef}
                    >
                        <Row>
                            <Col span={24}>
                                <Row gutter={16} className="hide-owner-info">
                                    <Col span={24}>
                                        <Form.Item name="firstName" label={<NJVBoldFont>{i18next.t("first_name")}</NJVBoldFont>} size={'large'}
                                            rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVInput size="large" prefix={<SvgUser width={20} height={20} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="lastName" label={<NJVBoldFont>{i18next.t("last_name")}</NJVBoldFont>} size={'large'}
                                            rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVInput size="large" prefix={<SvgUser width={20} height={20} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="phoneNumber" label={<NJVBoldFont>{i18next.t("phone_number")}</NJVBoldFont>}
                                            size={'large'} rules={[{
                                                required: true,
                                                validator: RestrictPhoneNumberValidator
                                            }]}>
                                            <NJVInput size="large" prefix={<SvgUserPhone width={20} height={20} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="password" label={<NJVBoldFont>{i18next.t("password")}</NJVBoldFont>} size={'large'}
                                            rules={[
                                                { required: true, message: '*(requried)' },
                                                { min: 8, message: 'Please enter at least 8' }

                                            ]}>
                                            <NJVInput
                                                size="large"
                                                type={"password"}
                                                ispasswordinput="true"
                                                prefix={<SvgUserPassword width={20} height={20} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="email" label={<NJVBoldFont>{i18next.t("email")}</NJVBoldFont>} size={'large'}
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: 'Please use valid email',
                                                }
                                            ]}>
                                            <NJVInput size="large" prefix={<SvgEmial width={20} height={20} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="division" label={<NJVBoldFont>{i18next.t("division_state")}</NJVBoldFont>}
                                            rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVSelect
                                                className="my-location-selector"
                                                placeholder={<SvgDivision width={22} height={22} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }} />}
                                                onChange={handleDivisionSelect}
                                                size="large"
                                                options={
                                                    division_collection && division_collection.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="township" label={<NJVBoldFont>{i18next.t("township_city")}</NJVBoldFont>}
                                            rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVSelect
                                                className="my-location-selector"
                                                placeholder={<SvgDivision width={22} height={22} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }} />}
                                                size="large"
                                                options={
                                                    township_collection && township_collection.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <Form.Item name="address1" label={<NJVBoldFont>{i18next.t("address")}</NJVBoldFont>}
                                            rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVInput
                                                style={{ height: 60 }}
                                                maxLength={180} istextareainput="true" />
                                        </Form.Item>
                                        <span style={{ fontSize: 12, color: '#848484' }}>Enter up to 180 characters</span>
                                    </Col>
                                    <StepsComponent currentStep={currentStep} setCurrentStep={setCurrentStep} />
                                </Row>
                            </Col>
                        </Row>
                    </NJVForm>
                    :
                    <></>
            }
            {
                currentStep === 1 ?
                    <NJVForm
                        layout="vertical"
                        onFinish={completeBusinessInfo}
                        initialValues={shipperInfo}
                        ref={bankInformationRef}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item name="businessName" label={<NJVBoldFont>{i18next.t("business_name")}</NJVBoldFont>} size={'large'}
                                    rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVInput size="large"
                                        prefix={<SvgBusinessName width={20} height={20} />}
                                        onChange={(event) => handleUserInput('businessName', event.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="ecommerceRegNumber" label={<NJVBoldFont>{i18next.t("reg_number")}</NJVBoldFont>} size={'large'}>
                                    <NJVInput size="large"
                                        prefix={<SvgEcommerce width={20} height={20} />}
                                        onChange={(event) => handleUserInput('ecommerceRegNumber', event.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="businessCategory" label={<NJVBoldFont>{i18next.t("business_category")}</NJVBoldFont>}
                                    rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVSelect
                                        className="my-location-selector"
                                        placeholder={<SvgBusinessName width={20} height={20} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} />}
                                        showSearch
                                        filterOption={onFilterOption}
                                        style={{ width: "100%" }}
                                        onChange={(value) => handleUserInput('businessCategory', value)}
                                        size="large"
                                        options={
                                            product_category_collection && product_category_collection.map(category => {
                                                return {
                                                    value: category.id,
                                                    label: category.name,
                                                }
                                            })
                                        }
                                    >

                                    </NJVSelect>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <FormLabel label={<NJVBoldFont>{i18next.t("business_owner_nrc")}</NJVBoldFont>} />
                                    </Col>
                                    <Col span={4}>
                                        <NJVSelect
                                            className="my-location-selector"
                                            style={{ width: '100%' }}
                                            placeholder={"1"}
                                            onChange={handleOnCodeChange}
                                            size="large"
                                            value={nrcDivision_number}
                                            options={nrcNo_collection}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <NJVSelect
                                            className="my-location-selector"
                                            style={{ width: '100%' }}
                                            onChange={handleOnTownShipCodeChange}
                                            placeholder="OUKATA"
                                            showSearch
                                            filterOption={onFilterOption}
                                            value={nrcTownship_code}
                                            size="large"
                                            options={
                                                townshipCode_collection && townshipCode_collection.map((code, index) => {
                                                    return {
                                                        key: index,
                                                        value: code.value,
                                                        label: code.value,
                                                    }
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <NJVSelect
                                            value={nrcCitizen}
                                            className="my-location-selector"
                                            style={{ width: '100%' }}
                                            placeholder={"N"}
                                            onChange={(value) => setNrcCitizen(value)}
                                            size="large"
                                        >
                                            <Option value={"N"}>N</Option>
                                            <Option value={"F"}>F</Option>
                                            <Option value={"P"}>P</Option>
                                            <Option value={"T"}>T</Option>
                                            <Option value={"E"}>E</Option>
                                        </NJVSelect>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name="nrcNo" size={'large'}
                                            rules={[
                                                { validator: NRCValidator }
                                            ]}>
                                            <NJVInput size="large"
                                                onChange={(event) => handleUserInput('nrcNo', event.target.value)} />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="businessFacebookPageLink"
                                    label={<NJVBoldFont>{i18next.t("business_fb_page_link")}</NJVBoldFont>} size={'large'}
                                    rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVInput size="large" placeholder={"https://www.facebook.com/yourbusinessname"}
                                        onChange={(event) => handleUserInput('businessFacebookPageLink', event.target.value)} />
                                </Form.Item>
                            </Col>
                            <StepsComponent currentStep={currentStep} setCurrentStep={setCurrentStep}
                                isLoading={is_loading} />
                        </Row>
                    </NJVForm>
                    :
                    <></>
            }
            {
                currentStep === 2 ?
                    <NJVForm
                        layout="vertical"
                        onFinish={signUp}
                        initialValues={shipperInfo}
                        ref={bankInformationRef}
                    >
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Form.Item name="accountOwnerName"
                                    label={<NJVBoldFont>{i18next.t("account_owner_name")}</NJVBoldFont>}
                                    rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVInput
                                        size="large"
                                        prefix={<SvgUser width={18} height={18} />}
                                        onChange={(event) => handleUserInput('accountOwnerName', event.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Form.Item name="primaryBank" label={<NJVBoldFont>{i18next.t("primary_payment")}</NJVBoldFont>}
                                    rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVSelect
                                        placeholder={<SvgPayment width={17} height={17} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} />}
                                        className="my-location-selector"
                                        // placeholder={i18next.t("primary_payment")}
                                        onChange={(value) => handleUserInput('primaryBank', value)}
                                        size="large"
                                        options={
                                            payment_type_collection && payment_type_collection.map((payment) => {
                                                return {
                                                    value: payment.key,
                                                    label: payment.value,
                                                    type: 'primaryPayment'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="primaryAccounNumber" label={<NJVBoldFont>{i18next.t("primary_account_number")}</NJVBoldFont>}
                                    size={'large'}
                                    rules={[{ required: true, validator: NumberOnlyValidator }]}>
                                    <NJVInput size="large" placeholder={"xxx-xxx-xxx"}
                                        prefix={<SvgAccountNumber width={18} height={18} />}
                                        onChange={(event) => handleUserInput('primaryAccounNumber', event.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="bankPhoto" label={<NJVBoldFont>{i18next.t("bank_acc_photo")}</NJVBoldFont>}
                                    rules={[{ required: true, message: 'Photo is required!' }]}>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: Theme.colors.light_primary,
                                                colorBorder: '#C2002F66',
                                                colorPrimaryHover: '#C2002F66',
                                                colorFillAlter: Theme.colors.light_primary
                                            }
                                        }}>
                                        <Upload
                                            listType="picture-card"
                                            fileList={imageFile}
                                            onPreview={handlePreview}
                                            onChange={handleFileUpload}
                                            maxCount={1}
                                            accept={["image/png, image/jpeg"]}
                                            beforeUpload={() => false}
                                            style={{ backgroundColor: Theme.colors.light_primary }}
                                        >
                                            {imageFile?.length >= 1 ? null : <>
                                                <div>
                                                    <SvgFileUpload width={25} height={25} />
                                                    <div>Upload file</div>
                                                </div>
                                            </>}
                                        </Upload>
                                        {previewImage && (
                                            <Image
                                                wrapperStyle={{ display: 'none' }}
                                                preview={{
                                                    visible: previewOpen,
                                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                }}
                                                src={previewImage}
                                            />
                                        )}
                                    </ConfigProvider>
                                    <Flex vertical style={{ fontSize: 12, marginTop: 2, color: '#848484' }}>
                                        <span>Supported Format: .JPG, .JPEG, .PNG</span>
                                        <span>Maximum Size: 25MB</span>
                                    </Flex>
                                </Form.Item>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Alert: {
                                                withDescriptionIconSize: 20
                                            },
                                        },
                                    }}
                                >
                                    <Alert
                                        description={<><span style={{ fontWeight: 'bold', fontSize: 13 }}>သတိပြုရန် </span>- <span
                                            style={{ fontSize: 12 }}>Upload တင်ရမည့် ဓါတ်ပုံမှာ သတ်မှတ်ထားသော ဘဏ်အချက်အလက်ပုံ မဟုတ်ခဲ့လျှင် Reject ကျနိုင်ပါသည်။</span></>}
                                        type="warning" showIcon
                                        style={{
                                            border: 'none',
                                            padding: 5,
                                            marginBottom: 10,
                                            backgroundColor: Theme.colors.light_gray
                                        }} />
                                </ConfigProvider>
                            </Col>
                            <Col span={24}>
                                <ConfigProvider theme={{
                                    token: {
                                        colorPrimary: Theme.colors.primary
                                    },
                                }}>
                                    <Checkbox
                                        checked={agreeTermsAndCondition}
                                        onChange={(event) => setAgreeTermsAndCondition(event.target.checked)} />
                                </ConfigProvider>

                                <span
                                    style={{
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                        color: Theme.colors.primary,
                                        fontWeight: '600'
                                    }}
                                    onClick={() => setShowTermsAndConditionModal(true)}
                                >
                                    I have read and agreed to the Terms and Conditions*
                                </span>
                            </Col>
                            <StepsComponent currentStep={currentStep} setCurrentStep={setCurrentStep}
                                isLoading={is_loading} />
                        </Row>
                    </NJVForm> : <></>
            }
        </>
    );
}

const StepsComponent = ({ currentStep, setCurrentStep, isLoading }) => {
    return (
        <Row style={{ width: '100%', alignItems: 'center', marginBlock: 10, marginInline: 8 }}>
            <Col lg={12} md={12} sm={0} xs={0}>
                <Flex gap={7}>
                    <span style={{
                        width: 80,
                        height: 10,
                        borderRadius: 2,
                        backgroundColor: currentStep === 0 ? Theme.colors.primary : Theme.colors.light_gray_2
                    }} />
                    <span style={{
                        width: 80,
                        height: 10,
                        borderRadius: 2,
                        backgroundColor: currentStep === 1 ? Theme.colors.primary : Theme.colors.light_gray_2
                    }} />
                    <span style={{
                        width: 80,
                        height: 10,
                        borderRadius: 2,
                        backgroundColor: currentStep === 2 ? Theme.colors.primary : Theme.colors.light_gray_2
                    }} />
                </Flex>
            </Col>
            <Col lg={0} md={0} sm={12} xs={12}>
                <Flex gap={7}>
                    <span style={{
                        width: 40,
                        height: 7,
                        borderRadius: 2,
                        backgroundColor: currentStep === 0 ? Theme.colors.primary : Theme.colors.light_gray_2
                    }} />
                    <span style={{
                        width: 40,
                        height: 7,
                        borderRadius: 2,
                        backgroundColor: currentStep === 1 ? Theme.colors.primary : Theme.colors.light_gray_2
                    }} />
                    <span style={{
                        width: 40,
                        height: 7,
                        borderRadius: 2,
                        backgroundColor: currentStep === 2 ? Theme.colors.primary : Theme.colors.light_gray_2
                    }} />
                </Flex>
            </Col>
            <Col lg={12} md={12} sm={0} xs={0}>
                <Flex gap={14} justify={"right"} align={"center"}>
                    {
                        currentStep > 0 && (
                            <span onClick={() => setCurrentStep(currentStep - 1)}
                                style={{ fontWeight: 'bold', cursor: 'pointer' }}>Back</span>
                        )
                    }
                    <NJVButton type="primary" htmlType="submit" loading={currentStep === 2 ? isLoading : false} buttonprimary={Theme.colors.primary}>{currentStep === 2 ? i18next.t("register") : i18next.t("continue")}</NJVButton>
                </Flex>
            </Col>
            <Col lg={0} md={0} sm={12} xs={12}>
                <Flex gap={12} justify={"right"} align={"center"}>
                    {
                        currentStep > 0 && (
                            <span onClick={() => setCurrentStep(currentStep - 1)}
                                style={{ fontWeight: 'bold', cursor: 'pointer' }}>Back</span>
                        )
                    }
                    <NJVButton type="primary" htmlType="submit"
                        loading={currentStep === 2 ? isLoading : false} buttonprimary={Theme.colors.primary}>{currentStep === 2 ? i18next.t("register") : i18next.t("continue")}</NJVButton>
                </Flex>
            </Col>
        </Row>
    )
}

export default Register;