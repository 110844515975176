import { Badge } from "antd";
import { SvgAirwayBill, SvgAppTutorial, SvgAWBFilled, SvgConfirmOrder, SvgConfirmOrderFilled, SvgCoverage, SvgCoverageFilled, SvgExclamationMarkFilled, SvgFailLog, SvgFaq, Svgfaq, SvgFAQFilled, SvgFeedback, SvgFeedbackFilled, SvgHistoryFilled, SvgInvoiceFilled, SvgLearningFilled, SvgLocationFilled, SvgLoyalty, SvgLoyaltyFilled, SvgMyLocation, SvgNearby, SvgNotificationBellFilled, SvgOrderBilling, SvgOrderDollyFilled, SvgParcelCreate, SvgParcelHistory, SvgPickupFilled, SvgPrivacy, SvgPrivacyPolicy, SvgPrivacyPolicyFilled, SvgReturnNotification, SvgROM, SvgRTSFilled, SvgStoreFilled, SvgTandCFilled, SvgTerms } from "../components/custom-svgIcon";
import Theme from "../components/theme";

const unselectColor = '#989898'

export const GenerateSvgIconBaseOnName = (name, selectedIndex, currentIndex, showBadge) => {
    let svgIcon;
    let isSelect = selectedIndex === currentIndex ? true : false
    if (name === 'parcel_create') {
        svgIcon = <SvgParcelCreate width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'rom') {
        svgIcon = <SvgROM width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'rts') {
        svgIcon = <SvgReturnNotification width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'parcel_history') {
        svgIcon = <SvgParcelHistory width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    }else if (name === 'store') {
        svgIcon = <SvgStoreFilled width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'awb') {
        svgIcon = <SvgAirwayBill width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'fav_location') {
        svgIcon = <SvgMyLocation width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    }else if (name === 'nearby_ninjas') {
        svgIcon = <SvgNearby width={23} height={23} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'ninja_rewards') {
        svgIcon = <SvgLoyalty width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } 
    // else if (name === 'notification') {
    //     svgIcon = <Badge dot={showBadge} color={Theme.colors.primary}><SvgNotificationBellFilled width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} /></Badge>
    // } 
    else if (name === 'invoice') {
        svgIcon = <SvgOrderBilling width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'guide') {
        svgIcon = <SvgAppTutorial width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'price_coverage') {
        svgIcon = <SvgCoverage width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'faq') {
        svgIcon = <SvgFaq width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'feedback') {
        svgIcon = <SvgFeedback width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'terms_and_condition') {
        svgIcon = <SvgTerms width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'privacy_and_policy') {
        svgIcon = <SvgPrivacy width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'pickup_call') {
        svgIcon = <SvgPickupFilled width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'confirm_order') {
        svgIcon = <SvgConfirmOrder width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    } else if (name === 'order_create_fail_logs') {
        svgIcon = <SvgFailLog width={20} height={20} color={isSelect ? Theme.colors.primary : unselectColor} />
    }
    return (svgIcon)
}

export const GenerateOrderStatusCode = (key, value) => {
    let code = value
    if (key === 'ARRIVED_AT_ORIGIN_HUB' || key === 'ARRIVED_AT_SORTING_HUB' || key === 'ARRIVED_AT_TRANSIT_HUB' ||
        key === 'ARRIVED_AT_DESTINATION_HUB' || key === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' || key === 'AT_PUDO') {
        code = 'Processing'
    }
    return code
}

export const GetListFromEnumObject = (enumlist) => {
    let temp = []
    enumlist.map((res, index) => {
        temp.push({
            key: index + 1,
            value: res.key,
            label: res.value,
        });
    })
    return temp
}