import { compose } from '@reduxjs/toolkit';
import { Col, Flex, Image, Pagination, Row, Skeleton, Tag, Typography } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NoData from '../../asset/no-data-found.png';
import { BackTitleCompo } from '../../components/general-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';
import { NJVButton, NJVCard } from '../../components/core-component';
import Theme from '../../components/theme';
import ThumbnailImg from '../../asset/ninja_thumbnail.jpg';

class ShopTypePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shopType: this.props.location?.state?.shopType || null,
            shops: this.props.location?.state?.shops || null,
            isFetchingData: false,
            shopData: [],
            page: 0,
            pageSize: Constant.pageSize
        }
    }

    componentDidMount() {
        if (this.state.shops) {
            this.fetchPopularShops()
        }
        this.fetchShopByType();
    }

    fetchShopByType = async (pageNumber) => {
        const { shopType, page, pageSize } = this.state

        this.setState({
            isFetchingData: true,
        })
        const requestParams = {
            page: pageNumber ? pageNumber - 1 : page,
            size: pageSize,
            shopType: shopType?.value,
        }
        await ApiHandler({ url: Api.loyalty_shop_category, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    shopData: response,
                    page: pageNumber ? pageNumber : page,
                    totalElement: response.totalElements,
                })
            }).catch(err => { })
            .finally(() => {
                this.setState({
                    isFetchingData: false
                })
            })
    }

    // shopTypeLoadMore = async () => {
    //     const { shopData, page, pageSize, shopType } = this.state;
    //     this.setState({
    //         typeLoadMore: true
    //     })
    //     try {
    //         const requestParams = {
    //             page: page + 1,
    //             size: pageSize,
    //             shopType: shopType?.value,
    //         };

    //         const response = await ApiHandler({
    //             url: Api.loyalty_shop_category,
    //             method: HTTP_METHOD.GET,
    //             mediaType: MEDIA_TYPE.JSON,
    //             requestParams
    //         });

    //         const updatedData = [...shopData.content, ...response.content];

    //         this.setState(prev => ({
    //             shopData: {
    //                 ...prev.shopData,
    //                 content: updatedData,
    //                 last: response.last
    //             },
    //             totalElements: response.totalElements,
    //             page: page + 1
    //         }))
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     } finally {
    //         this.setState({
    //             typeLoadMore: false
    //         })
    //     }
    // }

    fetchPopularShops = async (pageNumber) => {
        const { page, pageSize } = this.state
        this.setState({
            isFetchingPopularShops: true
        })
        const requestParams = {
            page: pageNumber ? pageNumber - 1 : page,
            size: pageSize
        }
        await ApiHandler({ url: Api.popular_shops, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    popularShops: response,
                    page: pageNumber ? pageNumber : page,
                    totalElement: response.totalElements,
                })
            })
            .catch((error) => {
            }).finally(() => {
                this.setState({
                    isFetchingPopularShops: false
                })
            })
    }

    // popularShopsLoadMore = async () => {
    //     const { popularShops, page, pageSize } = this.state;
    //     this.setState({
    //         popularLoadMore: true
    //     })
    //     try {
    //         const requestParams = {
    //             page: page + 1,
    //             size: pageSize
    //         };

    //         const response = await ApiHandler({
    //             url: Api.popular_shops,
    //             method: HTTP_METHOD.GET,
    //             mediaType: MEDIA_TYPE.JSON,
    //             requestParams
    //         });

    //         const updatedData = [...popularShops.content, ...response.content];

    //         this.setState(prev => ({
    //             popularShops: {
    //                 ...prev.popularShops,
    //                 content: updatedData,
    //                 last: response.last
    //             },
    //             totalElements: response.totalElements,
    //             page: page + 1
    //         }))
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     } finally {
    //         this.setState({
    //             popularLoadMore: false
    //         })
    //     }
    // }

    paginationPopularShops = (page) => {
        this.fetchPopularShops(page)
        this.setState({
            page: page - 1
        })
    }

    paginationShopType = (page) => {
        this.fetchShopByType(page)
        this.setState({
            page: page - 1
        })
    }

    render() {
        const { shopData, shops, isFetchingData, shopType, popularShops, page, pageSize, totalElement, isFetchingPopularShops } = this.state

        // const imageContainer = {
        //     border: '0.5px solid #ccc',
        //     width: 90,
        //     height: 90,
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     borderRadius: 12,
        //     boxShadow: '0 1px 6px -1px rgba(0, 0, 0, 0.15)'
        // }

        return (
            <Row>
                <Col lg={4} md={2} sm={1} xs={1} />
                <Col lg={16} md={20} sm={22} xs={22}>
                    {
                        shops ? (
                            <>
                                <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                    <BackTitleCompo label="Most Popular Brands" onClickRoute={CustomPath.loyalty} />
                                </Row>
                                {
                                    isFetchingPopularShops ? <Skeleton /> :
                                        <Row gutter={[16, 16]}>
                                            {
                                                popularShops?.content?.map((data, index) => {
                                                    return (
                                                        <Col key={index} span={24}>
                                                            <Link to={`${CustomPath.loyalty_shop_detail}/${data.id}`} state={{ prevRoute: CustomPath.shops, stateData: shops, stateName: 'shops' }}>
                                                                <NJVCard>
                                                                    <Flex gap={30} align='center'>
                                                                        {
                                                                            data?.shopProfileUrl &&
                                                                            <Image
                                                                                src={data.shopProfileUrl}
                                                                                preview={false}
                                                                                style={{ cursor: 'pointer', objectFit: 'cover', borderRadius: '50%', width: 60, height: 60 }}
                                                                                alt="Shop Profile"
                                                                                fallback={ThumbnailImg}
                                                                            />
                                                                        }
                                                                        <div>
                                                                            <Typography.Title level={5} style={{ marginTop: 0 }}>{data?.fullName}</Typography.Title>
                                                                            <Tag color='#2db7f5'>{data?.shopType}</Tag>
                                                                        </div>
                                                                    </Flex>
                                                                </NJVCard>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            {/* <Col span={24} style={{ textAlign: 'center' }}>
                                                {
                                                    popularShops?.last ?
                                                        <></>
                                                        :
                                                        <NJVButton type="primary"
                                                            style={{ backgroundColor: Theme.colors.primary }}
                                                            onClick={() => this.popularShopsLoadMore()}
                                                            loading={popularLoadMore}
                                                        >
                                                            Load More
                                                        </NJVButton>
                                                }
                                            </Col> */}
                                            <Col span={24} style={{ marginTop: 20, display: 'flex', justifyContent: 'right' }}>
                                                <Pagination
                                                    showSizeChanger={false}
                                                    current={page}
                                                    defaultPageSize={pageSize}
                                                    total={totalElement}
                                                    onChange={this.paginationPopularShops}
                                                />
                                            </Col>
                                        </Row>
                                }
                            </>
                        ) : (
                            <>
                                <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                    <BackTitleCompo label={shopType?.label} onClickRoute={CustomPath.loyalty} />
                                </Row>
                                {
                                    isFetchingData ?
                                        <Skeleton />
                                        :
                                        <>
                                            {
                                                shopData?.content?.length > 0 ?
                                                    <Row gutter={[24, 24]}>
                                                        {
                                                            shopData?.content?.map((data, index) => {
                                                                return (
                                                                    <Col key={index} span={24}>
                                                                        <Link to={`${CustomPath.loyalty_shop_detail}/${data.id}`} state={{ prevRoute: CustomPath.shops, stateData: shopType, stateName: 'shopType' }}>
                                                                            <NJVCard>
                                                                                <Flex gap={30} align='center'>
                                                                                    {
                                                                                        data?.shopProfileUrl &&
                                                                                        <Image
                                                                                            src={data.shopProfileUrl}
                                                                                            preview={false}
                                                                                            style={{ cursor: 'pointer', objectFit: 'cover', borderRadius: '50%', width: 60, height: 60 }}
                                                                                            alt="Shop Profile"
                                                                                            fallback={ThumbnailImg}
                                                                                        />
                                                                                    }
                                                                                    <Typography.Title level={5} style={{ marginBlock: 0 }}>{data?.fullName}</Typography.Title>
                                                                                </Flex>
                                                                            </NJVCard>
                                                                        </Link>
                                                                    </Col>
                                                                )
                                                            })}
                                                        {/* <Col span={24} style={{ textAlign: 'center' }}>
                                                            {
                                                                shopData?.last ?
                                                                    <></>
                                                                    :
                                                                    <NJVButton type="primary"
                                                                        style={{ backgroundColor: Theme.colors.primary }}
                                                                        onClick={() => this.shopTypeLoadMore()}
                                                                        loading={typeLoadMore}
                                                                    >
                                                                        Load More
                                                                    </NJVButton>
                                                            }
                                                        </Col> */}
                                                        <Col span={24} style={{ marginTop: 20, display: 'flex', justifyContent: 'right' }}>
                                                            <Pagination
                                                                showSizeChanger={false}
                                                                current={page}
                                                                defaultPageSize={pageSize}
                                                                total={totalElement}
                                                                onChange={this.paginationShopType}
                                                            />
                                                        </Col>
                                                    </Row> : <Flex justify='center' align='center' style={{ marginTop: 80 }}>
                                                        <Image src={NoData} alt='no data' width={120} />
                                                    </Flex>
                                            }
                                        </>
                                }
                            </>
                        )
                    }
                </Col>
            </Row >
        )
    }
}

export default compose(withRouter)(ShopTypePage)

