import { Col, Row, Skeleton } from "antd";
import i18next from "i18next";
import React from "react";
import { TitleLevel3 } from "../components/general-component";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import { MainTitle } from "../components/core-component";

class TutorialPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetchingData: false,
            videoInfos: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isFetchingData: true
        })
        await ApiHandler({ url: Api.public_get_tutorial, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                this.setState({
                    isFetchingData: false
                })
                response.map(item => {
                    if (item.type === 'VIDEO') {
                        this.setState((prev) => ({
                            videoInfos: [...prev.videoInfos, item]
                        }))
                    }
                })
            }).catch(() => {
                this.setState({
                    isFetchingData: false
                })
            })
    }

    render() {
        const { videoInfos, isFetchingData } = this.state;

        return (
            <Row gutter={[16, 16]}>
                <Col span={24} >
                    <MainTitle label={i18next.t("guide")} />
                </Col>
                {
                    isFetchingData ? <Skeleton /> :
                        <>
                            {
                                videoInfos?.map(video => (
                                    <Col lg={8} md={12} sm={24} xs={24}>
                                        <div className="tutorial-video-container">
                                            <iframe src={video?.embeddedUrl} height={100} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                        </div>
                                        <h5 style={{ fontSize: '16px', marginBlock: 15 }}>{video?.title}</h5>
                                    </Col>
                                ))
                            }
                        </>
                }
            </Row>
        )
    }
}

export default TutorialPage