const Api = {
    host: process.env.REACT_APP_SERVER_URL,

    invoice_host: 'https://api.ninjavan-mm.com/ninjavan-backend-svr',
    invoice_by_shipper: 'v3/financial_center/list_shipper_invoices',
    invoice_download: 'v3/download/download_converted_invoice',

    signup: 'public/shipper/register',
    login: 'api/auth/login',
    refresh_token: 'api/auth/refresh_token',
    order_summary: 'dashboard/shipper',

    division_filter: 'division/filter',
    division: 'division',
    division_filter_active: 'division/enable',
    division_get_all: 'division/getAll',

    township_filter: 'township/filter',
    township: 'township',
    township_filter_active: 'township/enable',

    bus_gate_by_receiver_and_sender: 'bus-gate/findByAvailableTownship',
    check_bus_gate_service_available: 'bus-gate/checkBusGateByTownship',

    user_address_filter: 'user_address/filter/specific',
    user_address_available: 'user_address/get_available_address',
    user_address: 'user_address',

    shipper_profile: 'shipper/profile',
    shipper_dashboard: 'shipper/dashboard',
    shipper_invoice: 'shipper/invoice',
    shipper_analytic_dashboard: 'shipper/analyticDashboardForShipper',
    shipper_report: 'shipper/order-report-summary',
    shipper_settlement_history: 'shipper/owed-history',
    shipper_settlement_info: 'shipper/owed-info',
    shipper_settlement_screenshot: 'shipper/settlement',

    // faq: 'faq/enable',
    faq: 'faq/enableUpdate',

    notification_history_filter: 'notification_history/specific_notification_history',
    notification_history: 'notification_history',
    mark_all_read: 'notification_history/update_isAllRead',
    isExist_notification: 'notification_history/is_exist_unread_notification',

    order: 'order',
    order_create: 'order/create_order',
    excel_order_create: 'order/excel_create_order',
    order_check_same_day: 'order/check_same_day_available',
    pickup_call_check_same_day: 'order/check_same_day_for_single_township',
    order_history: 'order/shipper/history',
    order_get_by_tracking_number: 'order/get_order_by_tracking_number',
    confirm_rom_order: 'order/confirm-return-order',
    create_return_order: 'order/register-return-order',
    order_issue: 'order/issue',
    order_rts: 'order/return_to_sender',
    order_rts_shipper: 'order/return_to_sender/shipper',
    order_rts_update_status: 'order/return_to_sender/update-status',
    order_partnership_stores: 'order/partnership-stores',
    order_event: 'order/events-by-tracking-number',
    batch_cancel: 'order/parcel-batch-cancel',
    order_export_excel: 'shipper/export-order-history',

    delivery_charges_type: 'order/delivery_charges_status',
    download_order_sample_excel: 'order/download_order_sample_excel',
    upload_order_excel: 'order/showOrderFromExcel',

    product_category: 'api/product_category/filter',
    get_price: 'zone/get_price_script',

    get_otp: 'public/get_otp_with_phone_number',
    check_otp: 'public/checkOTP',
    forgetPassword: 'public/confirm_password',

    // confirm_voucher: 'public/quick_voucher/confirm/customer',
    public_order_get_by_tracking_number: 'public/order/get_order_by_tracking_number',
    public_order_event: 'public/order/events-by-tracking-number',
    public_bus_gate_by_receiver_and_sender: 'public/bus-gate/findByAvailableTownship',
    public_get_tutorial: 'public/mobile-image-guides',
    public_get_products: 'public/shipper/products',
    public_image_slider: 'public/coverage/enable/IMAGE_SLIDER',
    public_get_product_detail: 'public/shipper/product-detail',
    public_shipper_info: 'public/shipper/info',

    product: 'shipper/product',
    product_filter: 'shipper/product/filter',

    enum_filter: 'enum/filter',

    pickup_call: 'order/add-order-data-to-sheet',

    delete_account_verify: 'public/verify-account',
    delete_account_request: 'public/request-delete-account',

    get_voucher_by_unique_id: 'public/quick_voucher/get-voucher',
    update_voucher: 'public/quick_voucher/confirm/customer',

    confirm_voucher: 'voucher/confirm',
    all_vouchers: 'voucher',
    public_create_store_voucher: 'public/voucher',
    voucher_by_unique_id: 'voucher/unique-id',
    delete_voucher: 'voucher/cancel',

    redis_order_create_fail_log: 'redis/order-create-fail-activity',

    create_insurance_request: 'shipper/insurance-tmp',
    get_incurance_history: 'insurance/filter',
    insurance_claim: 'api/v1/parcel-insurance/claim',
    insurance_items: 'api/v1/parcel-insurance/find-by-tracking-number',

    coverage_image: 'coverage/enable/PRICE_COVERAGE',
    check_user_group: 'shipper/check-user-group-exist',

    loyalty_info: 'api/v1/loyalty/info',
    banner_slide: 'api/v1/banner/banner-items-by-banner-type',
    popular_shops: 'api/v1/loyalty-shop/most-popular-shops',
    loyalty_shop: 'api/v1/loyalty-shop',
    loyalty_shop_filter: 'api/v1/loyalty-shop/filter',
    loyalty_shop_category: 'api/v1/loyalty-shop/loyalty/filter',
    loyalty_promotion: 'api/v1/loyalty/promotion/get-by-loyalty-shop-id',
    point_history: '/api/v1/loyalty/usage-history',
    feedback: 'api/v1/loyalty/feedback',
    feedback_info: 'api/v1/loyalty/feedback/info',

    ticket_repair_request: 'api/v1/ticket-repair/public/request',
    ticket_repair_search: 'api/v1/ticket-repair/public/search',

    nearby: 'nearby/ninjaAddresses',
    feedbacks: 'api/v1/feedbacks'
};

export default Api;
